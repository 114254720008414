import { useEffect, useState, useContext } from 'react';
import FilterBlock from '../filterselect/block';

// import { GlobalStateContext, GlobalDispatchContext } from '../context/global';
import { useGlobal } from '../context/global';

import api from '../service/api.ts';
import { parseJSON } from '../util';
import { useTranslation } from 'react-i18next';
import { SpinnerAbsolute, SpinnerRelative } from '../spinner';

const texture = "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAbwAAAG8B8aLcQwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAK0SURBVDiNbZJNaBxlHMZ/7ztfO9nMfiW7GmhjCqJCaaF6KN68ePCgB0ELftFCC6KHYsGD4kXBS6HqRVDw4EFQilAVD4IVih8nBaXIpoRA2qTZZrPNzmxmdmdn5515PbQ7Nprn/Px/7/N/3r/QWrOfNt5++Rm7XvsMIUQ8Ts4svfPpN/v5xH8Ba++eOWpJPi/H8bGBysErU6t5bGz2/hyN45PHP/r66r6A9rnnG1XTuChLzhNuc85QStHp3CZNFUIItNYI0Lnmp9TQJx7/4GK/AKy//+qHrmu/nkUjayeISDRUK2U8cibxmL6C+nwNrTVxsEtNMomV/mTpwpdnJYDv776UJKnlNqpUm3UkEAwitqIxjmPzgCMxo4iyY9I60GI5GNnfrXZeAzABlMqybrdP4IfMCc2hkkGgctz5Bna5RByNsMMhG9c7/HKzT380xjalLACjSWrM2BbJJKUDzBqCuinJh0NiKYhUxs+rt9jww6K8esnJC8Bv17ca93tlHmpWcUyDKNMM85yqa/DH32usbPXJ75btORaPHmjiOda/CTRwww/ZHAx5cL7CobkKBhAEEddu7QBgS8mxg02a5dKeb5cAC155LACV51zbDriy2uHmYFiYji40ePLhg8WwBjq7o7RIcGShES41vJl216cXxcSp4q/N26zt7PLUI4sYUhSwXhTT7vokWWY9PQVMdzu+2GI7ilnu+oRJymA8KYbDJGW567MdxXdWMiVFAoE+qxHngcXWrEtz1mXdD1npDUhUxkpvwLofMj36kmmo+yrux3tO+cqpUyWvNnoDzVuAx91OLq9sovL8zmtS6JY386OlKidOXroU7AFMdfXNZ1sqtd7TcBowvm/fQACt2VLbmzGfe/GLy+17/f8DTPX7uRcOyzy78Ova1mN11z39ylc/fLuf7x91HEDHTzA+8AAAAABJRU5ErkJggg==')";

const dataColor = {
  "biały": 'white',
  "czarny": "black",
  "czerwony": "red",
  "zielony": "green",
  "niebieski": "blue",
  "zółty": "yellow",
  "pomarańczowy": "orange",
  "fioletowy": "purple",
  "różowy": "pink",
  "srebrny": "silver",
  "złoty": "gold",
  "brązowy": "brown",
  "szary": "gray",
  "beżowy": "beige",
  "morski": "seagreen",
  "limonkowy": "lime",
  "koralowy":"coral",
  "łososiowy": "salmon",
  "indygo": "indigo",
  "lawendowy": "lavender",
  "kośc słoniowa": "ivory",
  "błękitny": "azure",
  "kasztanowaty": "maroon",
  "oliwkowy": "olive",
  "karmazynowy": "magenta",
  "wodny": "aqua",
  "karmazynowy": "crimson",
  "lniany": "linen",
};

const getFilter = async (nodeKey, catalog) => {
  const res = await (catalog ? api.getFilterCatalog(nodeKey) : api.getFilter(nodeKey));
  console.log("Katalog: ", catalog);
  return res?.data;
};

const FilterList = ({
  // handleClear = () => { console.log('FilterButton', "clear") },
  handleData = (data) => { console.log('FilterList', "data") },
  // handleUpdate = (obj) => handleUpdate(obj),
  nodeKey,
  catalog = false,
}) => {
  // const globalState = useContext(GlobalStateContext);
  // const globalDispatch = useContext(GlobalDispatchContext);

  const [globalState, globalDispatch] = useGlobal();
  const { t } = useTranslation("shop");

  const [filterExpand, setFilterExpand] = useState(false);
  const [dataFilter, setDataFilter] = useState([]);
  const [selectFilter, setSelectFilter] = useState({});
  const [clearFilter, setClearFilter] = useState(JSON.stringify('-1'));
  const [loading, setLoading] = useState(false);
  let isMounted = false;

  useEffect(() => {
    isMounted = true;
    if (!Array.isArray(nodeKey) || nodeKey[0] == '') return;
    setLoading(true);

    getFilter(nodeKey[0], catalog)
      .then((data) => {
      if (!data || data?.status !== 0) return;

      let dataJson = parseJSON(data.dane);
      if (!Array.isArray(dataJson)) return;

      dataJson = dataJson
        .filter((el, index, self) => index === self.findIndex(it => it.kolumna === el.kolumna))
        .map(filter => {
        // console.log("filterData", filter);

        if (filter.nazwa.toLowerCase().includes("kolor")) {
          filter.inputType = "color";
          filter.wartosci = filter.wartosci.map(value => {
            value.color = dataColor[value.wartosc.toLowerCase()];
            return value;
          });
        } else if (filter.typ == "D" || filter.typ == "T") {
          filter.inputType = "date";
        } else if (filter.typ == "N") {
          filter.inputType = "number";
        } else if (filter.typ == "I") {
          filter.inputType = "numberstring";
        } else if (filter.typ == "C") { // char
          filter.inputType = "string";
          if (filter?.wartosci == undefined)
            filter.inputType = "input";
        } else if (filter.typ == "L") { // bool
          filter.inputType = "radio";
          filter.wartosci = [{id: -1, wartosc: ''}];
        } else
          filter.inputType = "select"; // filter.typ == "O" = combo

        filter.selected = []; // TODO: set default values
        if (filter?.wartosci == undefined) {
          filter['wartosci'] = [{id: 0, wartosc: ''}];
        }

        return filter;
      });

      // console.log('DataFilter', dataJson);

      if (isMounted) {
        setDataFilter(dataJson);
        handleData(dataJson);
      }
    }).finally(() => {
      isMounted && setLoading(false);
    });

    return () => {
      isMounted = false;
    };
  }, [nodeKey]);

  const onFilterExpand = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFilterExpand(!filterExpand);
  };

  const onFilterClear = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (Object.keys(selectFilter).length == 0) return;

    globalDispatch.setFilter(Object.assign({}));

    // TODO: check below functions if this is neccessary
    // setClearFilter(JSON.stringify("-1"));
    // typeof handleClear === "function" && handleClear();
  };

  const handleUpdate = (obj) => {
    // console.log("FilterFeature", {...}, "OBJ", {...obj}, );
    console.log('FilterGLOBAL', { ...globalState.filter, ...obj }, selectFilter);

    const deleteProperty = (key, { [key]: undefined, ..._obj }) => _obj;
    const filterObj = deleteProperty('_type', obj);

    setSelectFilter({ ...selectFilter, ...filterObj });
    globalDispatch.setFilter({ ...filterObj });
  };

  useEffect(() => { // FOR remove Object
    const removeObj = Object.keys(selectFilter).filter(obj => globalState.filter[obj] === undefined);
    // console.log('FilterButton', globalState.filter);

    if (removeObj !== undefined) {
      console.log('FilterButton removeObj', removeObj);
      setClearFilter(""+JSON.stringify(removeObj));
    }

    setSelectFilter({ ...globalState.filter });
  }, [globalState.filter]);

  if (dataFilter && dataFilter.length <= 0) {
    return "";
    // return (
    //   <div className='relative h-20 my-1.5'>
    //     {loading && <SpinnerRelative noShadow />}
    //   </div>
    // );
  }

  return (
    <div className='relative'>
      {loading && <SpinnerAbsolute />}
      <fieldset className="relative border bg-white border-gray-200 mb-4">
        <legend className='ml-1 mr-1'>
          <span className="flex items-center flex-grow px-1">
            <svg className="w-3 h-3" enableBackground="new 0 0 515.058 515.058" viewBox="0 0 515.058 515.058">
              <path d="m515.058 11.057h-515.058l49.936 60.763h415.186z" />
              <path d="m216.205 274.139v229.863l82.649-46.614v-183.249l141.614-172.319h-365.878z" />
            </svg>
            <span className="ml-1 font-medium text-sm">{t('Filtry')}:</span>
          </span>
        </legend>
        <div className={`relative`}>
          <span className="shadowround" />
          <nav className="relative px-3 py-2 text-sm">
            {dataFilter && dataFilter.length > 0 && (
              <>
                <div className="flex flex-row flex-wrap gap-x-4">
                  {dataFilter
                    .filter(filter => filter?.wartosci && filter.wartosci.length > 0)
                    .map(filter => (
                      <div className="relative flex flex-col items-center" key={`filter_select_${encodeURIComponent(filter.nazwa)}`}>
                        <FilterBlock
                          id={filter.kolumna}
                          name={filter.nazwa}
                          data={filter.wartosci}
                          type={filter.inputType}
                          filter={filter}
                          handleUpdate={handleUpdate}
                          clearFilter={clearFilter}
                          expand={filterExpand}
                        />
                      </div>
                  ))}
                </div>
              </>
            )}
          </nav>
        </div>
        <div className="absolute right-0 flex flex-row m-px -top-5"> 
          <button
            type="button"
            onClick={onFilterClear}
            className={`p-1 mr-1 text-white bg-white border border-b-0 border-l-0 border-r-0 border-gray-300 rounded-full`}
            data-tooltip={t('wyczyść filtry')}
          >
            <svg
              className={`w-4 h-4 ${Object.keys(selectFilter).length > 0 ? "text-black" : "text-gray-300"} bg-white rounded-full`}
              viewBox="-1.5 -2.5 24 24"
              fill="currentColor"
            >
              <path d='M12.728 12.728L8.485 8.485l-5.657 5.657 2.122 2.121a3 3 0 0 0 4.242 0l3.536-3.535zM11.284 17H14a1 1 0 0 1 0 2H3a1 1 0 0 
                1-.133-1.991l-1.453-1.453a2 2 0 0 1 0-2.828L12.728 1.414a2 2 0 0 1 2.828 0L19.8 5.657a2 2 0 0 1 0 2.828L11.284 17z'
              />
            </svg>
          </button>
          <button
            type="button"
            onClick={onFilterExpand}
            className="p-1 text-white bg-white border border-b-0 border-l-0 border-r-0 border-gray-300 rounded-full"
            data-tooltip={filterExpand == true ? t('zwiń filtry') : t('rozwij filtry')}
          >
            {filterExpand == false && (
              <svg className={`w-4 h-4 bg-green-500 rounded-full`} viewBox="0 0 24 24" fill="none">
                <path d="M14.5 9.5L9 15" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10 9H14.6717C14.853 9 15 9.14703 15 9.32837V14" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            )}
            {filterExpand == true && (
              <svg className={`w-4 h-4 bg-yellow-500 rounded-full`} viewBox="0 0 24 24" fill="none">
                <path d="M9.5 14.5L15 9" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14 15L9.32833 15C9.147 15 9 14.853 9 14.6716L9 10" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            )}
          </button>
        </div>
      </fieldset>
    </div>
  );
};

export default FilterList;
