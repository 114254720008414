import { useEffect, useState, useRef} from 'react';
import Calendar from 'react-calendar';
import { IconCalendar, IconNext, IconPrev, IconDoublePrev, IconDoubleNext } from './icon';

const InputCalendar = ({ defaultValue = ['', ''], focus = false, onChange = (e) => {} }) => {
  const [show, setShow] = useState(false);
  const [date, setDate] = useState({from: defaultValue[0], to: defaultValue[1]})
  // const [dateDef, setDateDef] = useState
  const ref = useRef();

  const onShow = (e) => {
    e.preventDefault();

    setShow(!show);
    setTimeout(() => {
      if (ref.current == null) return false;

      const pos = (document.body.clientWidth - (ref.current.getBoundingClientRect().left + ref.current.clientWidth));
      if (pos < 0) {
        // refCalendar.current.style.left = (pos - 20) + "px";
        ref.current.style.right = 0;
        ref.current.style.left = 'inherit';
        ref.current.querySelector("span.react-arrow").classList.add("right-4");
        ref.current.querySelector("span.react-arrow").classList.remove("left-10");
      }

      return true;
    }, 0);
  };

  const onChangeCalendar = (date) => {
    setDate({ from: date[0], to: date[1] });
    setShow(false);
  };

  const getDefaultValue = () => {
    return defaultValue.map(date => {
      if (date == '') return new Date();
      const dateSplit = date.split('-');
      return new Date(+dateSplit[0], +dateSplit[1]-1, +dateSplit[2]);
    })
  }

  useEffect(() => {
    if (date.from == '' && date.to == '') return;

    onChange(date);
  }, [date]);

  // useEffect(() => {
  //   if (date.from == '' && date.to == '') return;
  //   console.log("DEF VALUE", defaultValue);
  //   // setDate({from: defaultValue[0], to: defaultValue[1]});
  // }, defaultValue);

  return (
    <div className="relative w-full">
      <div>
        {/* <input
          placeholder={date.from != '' || date.to != '' ? '' : 'Data...'}
          type="text"
          defaultValue={''}
          readOnly
          
          className={``}
          // onChange={onChange}
        /> */}
        <button
          type="button"
          className={`w-full flex flex-grow cursor-pointer z-10 rounded-sm px-1 text-sm text-gray-700 placeholder-gray-400 border 
          border-gray-200 appearance-none focus:placeholder-gray-600 focus:text-gray-700 bg-white
            focus:outline-none ${focus || show ? 'ring-1 ring-primary border-primary' : 'border-gray-200'} whitespace-nowrap`}
            onClick={onShow}
          >
          <div className='flex items-start justify-center text-xs font-normal flex-col break-keep h-8 w-18 overflow-hidden'>
          {defaultValue[0] == '' && defaultValue[1] == '' && (
            <div>
              <i className='text-gray-400 text-sm px-1 w-full'>Data...</i>
            </div>
          )}
          {defaultValue[0] != '' && (
            <div>
              <span className='text-gray-400'>od&nbsp;</span>
              <span>{(new Date(date.from).toDateInput(-2))}</span>
            </div>
          )}
          {defaultValue[1] != '' && (
            <div>
              <span className='text-gray-400'>do&nbsp;</span>
              <span>{(new Date(date.to).toDateInput(-2))}</span>
            </div>
          )}
          </div>
        </button>
        {/* <IconCalendar className='absolute right-2' /> */}
      </div>
      {show && (
        <div
          ref={ref}
          className="absolute z-10 mt-3 bg-white rounded shadow-cart"
          // onBlur={() => setShow(false)}
        >
          <span className="react-arrow left-10 -top-1" />
          <a
            href={void(0)}
            onClick={() => setShow(false)}
            className="absolute z-20 flex items-center text-white border-4 border-transparent border-opacity-0 cursor-pointer -top-3 -right-3"
          >
            <svg className="w-5 h-5 bg-red-500 rounded-full shadow" viewBox="-220 -220 800 800" fill="currentColor">
              <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 
                113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 
                113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 
                12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"
              />
            </svg>
          </a>
          <div className="relative z-10 p-2 bg-white rounded">
            <Calendar
              locale="pl-PL"
              selectRange
              defaultValue={getDefaultValue()}
              onChange={onChangeCalendar}
              prevLabel={<IconPrev size={4} />}
              nextLabel={<IconNext size={4} />}
              prev2Label={<IconDoublePrev size={4} />}
              next2Label={<IconDoubleNext size={4} />}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InputCalendar;