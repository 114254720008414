import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useCartAction from '../hook/cartaction';
import { IconPattern } from '../icon';

const PatternBuy = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("home");
  const [state] = useCartAction();

  const [productCount, setCartProductCount] = useState(0);
  const getCartProductCount = (_state) => _state.countW;
  let isMounted = false;

  useEffect(() => {
    isMounted = true;
    const productCnt = getCartProductCount(state.cart.summary);
    isMounted && setCartProductCount(productCnt);

    return () => {
      isMounted = false;
    }
  }, [state.summary]);

  const onEnter = () => {
    navigate('/pattern');
  };

  return (
    <button
      type="button"
      className={`${window.location.pathname === '/pattern' ? 'active' : ''} text-gray-800 hover:text-primary py-4 px-2 xs:px-4 flex`}
      onClick={onEnter}
      data-testid="gorna_belka-wzorce_zakupu"
    >
      <span className="flex-col hidden mr-1 text-xs leading-4 text-right xs:flex">
        <span><Trans t={t}>wzorce<br/>zakupu</Trans></span>
      </span>
      <IconPattern size="8" />
      <span className="relative top-0 right-2">
        <span className={`${productCount <= 0 ? 'hidden' : ''} flex justify-center text-center`}>
          <span
            className="px-1 font-sans text-xs font-bold text-white rounded-full bg-primary group-hover:bg-gray-800"
            style={{ minWidth: '17px', height: '17px', paddingTop: '1px' }}
          >
            {productCount}
          </span>
        </span>
      </span>
    </button>
  );
};

export default PatternBuy;
