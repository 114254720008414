import { useEffect, useRef } from 'react';

const SResizer = ({ mode = 0 }) => {
  const divRef = useRef(null);

  const state = {
    elPrev: null,
    elNext: null,
    left: -1,
    widthPrev: 0,
    widthNext: 0,
  };

  const handleResizeColumn = (e) => {
    state.elPrev.style.width = `${String(state.widthPrev + (e.pageX - state.left))}px`;

    if (state.elNext !== null && mode !== 1)
      state.elNext.style.width = `${String(state.widthNext - (e.pageX - state.left))}px`;
  };

  const handleMouseUpColumn = () => {
    document.removeEventListener('mousemove', handleResizeColumn);
    document.removeEventListener('mouseup', handleMouseUpColumn);
  };

  const handleMouseDownColumn = (e) => {
    state.elPrev = divRef.current.closest('th');
    state.elNext = divRef.current.closest('th').nextElementSibling;
    state.left = e.pageX;
    state.widthPrev = state.elPrev.clientWidth;
    state.widthNext = state.elNext?.clientWidth;
    document.addEventListener('mouseup', handleMouseUpColumn);
    document.addEventListener('mousemove', handleResizeColumn);
  };

  useEffect(() => () => {
    document.removeEventListener('mousemove', handleResizeColumn);
    document.removeEventListener('mouseup', handleMouseUpColumn);
  }, []);

  return (
    <>
      <button
        label="resizer"
        type="button"
        className="sresizer"
        ref={divRef}
        onMouseDown={handleMouseDownColumn}
      />
    </>
  );
};

export default SResizer;
