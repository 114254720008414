import { useEffect, useState } from 'react';
import { useGlobal } from '../context/global';
import styled from 'styled-components';

const Div = styled.div`
  background: #eff3f7;
`;

const FilterActive = ({ 
  filterData = [],
  handleClear = () => { 
    console.log('FilterClear', "clear");
  } 
}) => {
  const [isDataFiltered, setIsDataFiltered] = useState(false);
  const [globalState, globalDispatch] = useGlobal();

  useEffect(() => {
    console.log('FilterActive', globalState.filter);
    const data = Object
      .values(globalState.filter)
      .filter(value => value !== undefined);

    setIsDataFiltered(data.length > 0);
  }, [globalState.filter]);

  useEffect(() => {
    console.log('FilterData', filterData);
  }, [filterData]);

  const filterClear = (key) => {
    // const deleteProperty = (key, { [key]: undefined, ...obj }) => obj;
    // const filter = deleteProperty(key, globalState.filter);

    console.log('FilterActive clear', key);
    globalDispatch.setFilter({ [key]: undefined }); // is ... inside
  };

  const objToString = (obj) => { // for DateTime
    return Object.entries(obj).reduce((str, [p, val]) => {
      if (val == "") return `${str}`;
      if ((""+val).indexOf("T") > -1) {
        return `${str}${str.length > 0 ? ', ' : ' '}${p} ${(""+val).slice(0, (""+val).indexOf("T"))}`;
      }
      return `${str}${str.length > 0 ? ' - ' : ' '} ${val}`;
    }, '');
  };

  const objFindValues = (value, selected) => {
    console.log("objValues", value, selected);

    if (Array.isArray(selected)) {
      return selected.reduce((prev, curr) => (
        [...prev, value.find(obj => obj.id == curr)?.wartosc]
      ), []).join(", ");
    }

    if (typeof selected === 'object') {
      return objToString(selected);
    }

    return String(selected);
  };

  return (
    <>
      {isDataFiltered && (
        <Div className="flex flex-row items-start w-full p-4 py-2">
          <span className="mr-2 mt-0.5 pt-px text-sm font-semibold bold justify-start">Aktywne filtry:</span>
          <div className="flex flex-row flex-wrap gap-2">
            {[...Object.entries(globalState.filter)]
              .filter(obj => obj[1] != undefined)
              .map((obj, index) => {
                const objFound = filterData.find(val => val.kolumna == obj[0]);
                if (objFound == undefined) return '';

                const objValues = objFindValues(objFound.wartosci, obj[1]);

                return (
                  <div className="flex items-stretch px-2 py-0.5 pr-1 bg-white shadow rouned-sm" key={`filter_active_${index}`}>
                    <span className="pt-px text-sm">
                      <i className="text-xs">{objFound.nazwa}:</i>
                      &nbsp;
                      <b className="font-semibold">{objValues}</b>
                      {/* <b className="font-semibold">{obj[1]}</b> */}
                    </span>
                    <button
                      type="button"
                      className="p-1 hover:text-red-500"
                      onClick={() => filterClear(obj[0])}
                    >
                      <svg className="w-4 h-4" viewBox="-220 -220 800 800" fill="currentColor">
                        <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
                      </svg>
                    </button>
                  </div>
               );
            })}
          </div>
        </Div>
      )}
    </>
  )
}

export default FilterActive;
