
import styled from 'styled-components';

const Tool = styled.div`
  &.tooltip-show > div:first-child + div, 
  > div:first-child:hover + div {
    display:block;
    opacity: 1;
  }
`;

const Info = styled.div`
  display:none;
  opacity:0;
  position:absolute;
  top:30px;
  margin-left:8px;
  transform:translate(-50%, 0);
  background-color:white;
  padding:12px;
  box-shadow:0 0 6px rgba(0,0,0,0.4);
  z-index:20;
  color: black;
  border-radius:6px;
  transition: opacity 0.2s;

  :before {
    display:block;
    position:absolute;
    content:"\\00a0";
    top:-5px;
    left:50%;
    margin-left:-5px;
    background:white;
    transform:rotate(45deg);
    width:10px;
    height:10px;
    border:1px solid #dedede;
    border-right:none;
    border-bottom:none;
  }
`;

const Tooltip = ({children, content, className}) => {
  return (
    <>
      <Tool className={className}>
        <div>
          {children}
        </div>
        <Info>
          {content}
        </Info>
      </Tool>
    </>
  )
};

export default Tooltip;