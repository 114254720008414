import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropSelect from './index';
import useCartAction from "../hook/cartaction";
import { SpinnerAbsolute } from '../spinner';


const PropDisplay = ({ item, property = {definition: [], selection: []}}) => {
  if (property.definition.length == 0) return '';

  const { t } = useTranslation("cart");
  const [stateCart, cartAction] = useCartAction();


  // console.log("%cDEF", "color:red", property.definition)

  // const [propSave, setPropSave] = useState({});
  const [propSave, setPropSave] = useState(
    !property.selection ? {} : property.selection.reduce((acc, curr) => ({...acc, [curr.field]: curr.value}), {})
  );
  // const [changedEdit, setChangedEdit] = useState(false);
  const onChanged = (prop) => {
    // changedEdit == false && setChangedEdit(true);
    console.log("PROP", prop);

    setPropSave({ ...propSave, ...prop });
    cartAction.setProperty(item, { ...propSave, ...prop }, 0);
  };

  // const onSave = () => {
  //   setChangedEdit(false);
  //   cartAction.setProperty(item, { ...propSave }, 0);
  // }

  return (
    <>
      <div className={`flex flex-row relative w-full mb-1 focus:border-primary`}>
        {stateCart.loading && <SpinnerAbsolute />}
        <div className="flex flex-col w-full">
          <fieldset className={`rounded bg-white border border-gray-300 flex flex-row items-center border-1 
            ${stateCart.loading ? 'rainbow-box' : ''} ${stateCart.error ? 'border-red-500' : ''}`}
          >
            <legend className='px-1 mx-2 text-sm text-gray-500'>
              {t('Właściwości zamówienia')}
            </legend>
            <div className='p-2'>
              <PropSelect
                item={item}
                property={property.definition}
                selected={property.selection}
                onChanged={onChanged}
                style={'flex-col'}
              />
            </div>
          </fieldset>
        </div>
        {/* <div className="absolute right-0 flex flex-row m-px -top-0">
          <button
            type="button"
            className={`${changedEdit ? '' : 'hidden'} text-white p-1 bg-white rounded-full border border-b-0 border-l-0 border-r-0 border-gray-300`}
            title="zapisz zmiany cech"
            onClick={onSave}
          >
            <svg className="w-4 h-4 bg-green-500 rounded-full" viewBox="-180 -220 750 750" fill="currentColor">
              <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0"></path>
            </svg>
          </button>
        </div> */}
      </div>
    </>
  )
};



export default PropDisplay;
