
type ProductKey = {
  cart: number,
  symbol: string,
  jm: string,
};

type ProductPoz = number;

interface ProductObject {
  id: number,
  input: number,
  confirm: number,
  symbol: string,
  price: number,
  jm: string,
  convert: number,
}

// interface QuantityObject {
//   input: number,
//   confirm: number,
// }

interface ProductArray {
  [x: number]: ProductObject
}

interface ProductList {
  [x: string]: ProductArray
}

interface ProductEvent {
  [x: string]: any
}

// interface ProductQuantity {
//   [x: string]: QuantityObject
// }

interface ProductPos {
  [x: string]: number
}

const filterMapProduct = (obj) => {
  if (obj == null || Object.prototype.toString.call(obj) !== "[object Object]")
    return ({});

  Object.keys(obj).forEach(key => {
    if (parseInt(""+key, 10) == NaN)
      delete obj[key];
  });

  return obj;
};

class ProductMap {
  #list: ProductList = {};
  // #pos: ProductPos = {}
  event: ProductEvent = {};
  // quantity: ProductQuantity = {};

  constructor(list: []) {
    console.log('%cProductMap', "color:red", list, typeof list);

    // if (!Array.isArray(list)) return;

    for (let value of list) {
      if (this.#list[value[0]] == undefined)
        this.#list[value[0]] = {};
      
      this.#list[value[0]] = filterMapProduct(value[1]);
      // this.#pos[value[0]] = +(Object.keys(value[1]).pop() || 0);
      // const productKey: ProductKey = JSON.parse(key);
      // delete productKey.poz;
      // this.#pos[JSON.stringify({ ...productKey, poz:0 })] = productKey.poz;
    }
  }

  on(eventType: keyof DocumentEventMap, listener: (this: Document, ev: Event) => void) {
    document.addEventListener(eventType, listener);
  }

  off(eventType: keyof DocumentEventMap, listener: (this: Document, ev: Event) => void) {
    document.removeEventListener(eventType, listener);
  }

  trigger(eventType: string, data: object) {
    const event = new CustomEvent(eventType, { detail: data });
    document.dispatchEvent(event);
  }

  set(key: ProductKey, poz: ProductPoz, value: ProductObject) {
    const keyStr = JSON.stringify(key);

    if (key === undefined) return;

    if (this.#list[keyStr] == undefined)
      this.#list[keyStr] = {};

    if (+poz != 0) { // TODO: it will test its
      // delete this.#list[keyStr]["0"];
      if (this.#list[keyStr]["0"] != undefined) {
        this.#list[keyStr]["0"].input = 1;
        this.#list[keyStr]["0"].confirm = 0;
      }
    }

    if (poz == -1 || poz == undefined) {
      const pozKey = +(Object.keys(this.#list[keyStr]).pop() || 0);
      this.#list[keyStr][pozKey] = value;
      return;
    }
    
    this.#list[keyStr][poz] = value;
    // this.event = { ...this.event, [key.symbol]: (+new Date) }; // TODO: test in cart
    // console.log('ProductMap set', keyStr, poz, this.#list[keyStr][poz], this.event);
  }
  
  fire(key: ProductKey, poz: ProductPoz) {
    if (key === undefined) return;
    // this.event = { ...this.event, [key.symbol+"_"+poz]: (+new Date) }
    this.event = { ...this.event, [key.symbol]: ""+(+new Date()) }
  }

  has(key: ProductKey, poz: ProductPoz) {
    //console.log('productmap has', key, String(JSON.stringify(key)), this.#list["{\"cart\":16,\"symbol\":\"238\",\"jm\":\"szt.\"}"])
    const keyStr = JSON.stringify(key);

    if (this.#list[keyStr] === undefined)
      return false;

    if (poz == -1 || poz == undefined) {
      // console.log("KEYSSSS", keyStr, this.#list[keyStr], Object.keys(this.#list[keyStr]))
      return Object.keys(this.#list[keyStr]).pop() != undefined;
    }

    return this.#list[keyStr][poz] !== undefined;
  }

  get(key: ProductKey, poz: ProductPoz) {
    const keyStr = JSON.stringify(key);

    if (this.#list[keyStr] == undefined)
      return undefined;

    if (poz == -1 || poz == undefined) {
      const pozKey = +(Object.keys(this.#list[keyStr]).pop() || 0);
      return this.#list[keyStr][pozKey];
    }
    
    return this.#list[keyStr][poz];
  }

  // getFromCart(cartId: number) {
  //   return Object.getOwnPropertyNames(this.#list).reduce((acc, val) => {
  //     if (JSON.parse(val).cart == cartId && this.#list[val].confirm > 0)
  //       acc.push(this.#list[val];

  //     return acc;
  //   }, []);
  // }

  del(key: ProductKey, poz: ProductPoz) {
    const keyStr = JSON.stringify(key);

    if (this.#list[keyStr] != undefined) {
      delete this.#list[keyStr][poz];

      if (Object.keys(this.#list[keyStr]).length == 0) {
        delete this.#list[keyStr];
        delete this.event[key.symbol];
      }
    }

    // delete this.event[key.symbol+"_"+poz];
    // delete this.event[key.symbol]; // for without poz
  }

  clear() {
    this.#list = {};
    this.event = {};
  }

  toString() {
    return this.#list;
  }

  entries() {
    const productList = [];

    Object.getOwnPropertyNames(this.#list).forEach((key: string) => {
      Object.entries(this.#list[key]).forEach(([poz, product]) => {
        if (+poz > 0)
          productList.push([key, product]);
      });
    });

    return productList;
  }

  values() {
    return Object.values(this.#list);
  }

  countProduct(cartId: number = 0): number {
    return Object.getOwnPropertyNames(this.#list).reduce((acc, val) => {
      const key = JSON.parse(val);
      if (key.cart != cartId) return acc;

      Object.entries(this.#list[val]).forEach(([poz, product]) => {
        if (+poz == 0 || product.confirm < 0) return;
        acc += 1
      });

      return acc;
    }, 0);
  }

  confirmProduct(cartId: number = 0, symbol: string = ""): number {
    return Object.getOwnPropertyNames(this.#list).reduce((acc, val) => {
      const key = JSON.parse(val);
      if (key.cart != cartId || key.symbol != symbol) return acc;

      Object.entries(this.#list[val]).forEach(([poz, product]) => {
        if (+poz == 0 || product.confirm < 0) return;
        acc += (parseFloat(product.confirm) * parseFloat(product.convert));
      });

      return acc;
    }, 0);
  }

  delCart(cartId: number = 0): void {
    Object.getOwnPropertyNames(this.#list).forEach((key: string) => {
      const productKey: ProductKey = JSON.parse(key);
      if (!productKey || productKey.cart !== cartId) return;

      Object.entries(this.#list[key]).forEach(([poz, product]) => {
        if (+poz == 0) return;
        delete this.#list[key][poz];
        // delete this.event[product.symbol+"_"+poz];
      });

      delete this.event[productKey.symbol];
      delete this.#list[key];
    });
  }

  priceCart(cartId: number = 0) {
    return Object.getOwnPropertyNames(this.#list).reduce((acc, val) => {
      if (JSON.parse(val).cart !== cartId) return acc;

      let productSum = 0;
      Object.entries(this.#list[val]).forEach(([poz, product]) => {
        if (+poz == 0 || product.confirm < 0) return;
        const productPrice = product.price_discount || product.price;
        productSum += (productPrice * product.confirm * (product?.convert || 1));
      });

      // console.log('PRICECART', JSON.parse(val).cart, product.confirm, parseFloat(product.price * product.confirm))
      return (productSum) + acc;
    }, 0);
  }

  getLastPosition(productKey: ProductKey) {
    let pozLast = 0;
    const key = JSON.stringify(productKey);

    if (this.#list[key] == undefined) return 0;

    Object.entries(this.#list[key]).forEach(([poz, _]) => {
      if (+poz > 0) pozLast = +poz;
    });
    
    return pozLast;
  }

  findByCartId(cartId: number) {
    const productList: ProductObject[] = [];

    Object.getOwnPropertyNames(this.#list).forEach((key: string) => {
      const productKey: ProductKey = JSON.parse(key);
      if (!productKey || productKey.cart !== cartId) return;

      Object.entries(this.#list[key]).forEach(([poz, product]) => {
        if (+poz == 0 || product.id <= 0) return;

        productList.push(this.#list[key][poz]);
      });
    });

    return productList;
  }

  findByProductSymbol(productSymbol: string, productJm:string, cartId: number, productPoz: number = -1) {
    const productList: ProductObject[] = [];

    Object.getOwnPropertyNames(this.#list).forEach((key: string) => {
      const productKey: ProductKey = JSON.parse(key);

      if (!productKey || productKey.cart !== cartId) return;
      Object.entries(this.#list[key]).forEach(([poz, product]) => {
        if (productPoz > -1 && productPoz != +poz) return;

        if (product != undefined && product.symbol == productSymbol && (productJm == '' || product.jm == productJm))
          productList.push(this.#list[key][poz]);
      });
    });

    return productList;
  }

  findPosByProperty(productKey: ProductKey, property: object) {
    const key = JSON.stringify(productKey);

    if (this.#list[key] == undefined) return 0;

    let productPos = 0;

    const checkNullableValue = (val) => {
      if (val === null) return true;

      const valStr = val.toString();
      return valStr == '' || valStr === 'false' || valStr === '0'
    };

    for (const [poz, product] of Object.entries(this.#list[key])) {
      if (+poz == 0) continue;
      if (typeof product.property != 'object') continue;
      let productPass = true;

      Object.entries(property).forEach(([fieldA, valueA]) => {
        let equal = false;

        if (product.property[fieldA] == undefined && checkNullableValue(valueA)) {
          equal = true;
        }

        if (product.property[fieldA] != undefined && product.property[fieldA].toString() == (valueA as any).toString()) {
          equal = true;
        }

        if (equal == false)
          productPass = false;

        // console.log("%cIIIIIIIIIIIIIIIIIII", "color:blue", fieldA, product.property[fieldA].toString() == (valueA as any).toString(), product.property[fieldA].toString(), (valueA as any).toString(), equal, productPass, product.property, property);
      });
      
      Object.entries(product.property).forEach(([fieldA, valueA]) => {
        let equal = false;

        if (property[fieldA] == undefined && checkNullableValue(valueA)) {
          equal = true;
        }

        if (property[fieldA] != undefined && property[fieldA].toString() == (valueA as any).toString()) {
          equal = true;
        }

        if (equal == false)
          productPass = false;

        // console.log("%cEEEEEEEEEEEEEEEEEEEEEEEEEE", "color:blue", fieldA, product.property[fieldA].toString() == (valueA as any).toString(), product.property[fieldA].toString(), (valueA as any).toString(), equal, productPass, product.property, property);
      });

      if (productPass == true) {
        productPos = +poz;
        break;
      }
    };

    return productPos;
  };

  [Symbol.iterator]() { 
    const keys = Object.getOwnPropertyNames(this.#list)
    const length = keys.length;
    
    return {
      next: function () {
        return this._count < length ? {
          value: [keys[this._count], this._list[keys[this._count++]]],
          done: false
        } : {
          done: true
        }
      },
      _count: 0,
      _list: this.#list
    };
  }
};

export default ProductMap;
