import { useState, useEffect } from 'react';

import NormalDialog from '../../components/modal/normal';
import Portal from '../../components/context/portal';
import { parseJSON, compareDates } from '../../components/util/index';
import api from '../../components/service/api';
import { IconSpinner } from '../../components/icon';
import { useTranslation } from 'react-i18next';

import "./status.css";

const getOrderStatus = async (id) => {
  const resp = await api.statusOrder(id);
  return resp?.data;
};

const OrderStatus = ({ id = '', show = false, handleClose = () => {}, handleComplete = (data) => {} }) => {
  if (show == false || id == 0) return '';

  const { t } = useTranslation('cart');

  const [blockOK, setBlockOk] = useState(true);
  const [status, setStatus] = useState({
    count_comp: 0,
    count_pos: 0,
    order_err: "",
    order_nr: "",
    stat_order: 0,
    validateErrors: ""
  });

  let refTimeout = null;

  useEffect(() => {
    checkStatus();
    return () => {
      if (refTimeout != null)
        clearTimeout(refTimeout);
      // unblock
    }
  }, []);

  const checkStatus = () => {
    refTimeout = setTimeout(async () => {
      
      do {
        const resp = await getOrderStatus(id);
        console.log('OrderStatus', resp?.dane);

        if (resp?.status != 0) {
          statusComplete({ status: resp?.status || 1, info: resp?.info || "", order: "" });
          break;
        }

        const statusJson = parseJSON(resp?.dane);
        if (!statusJson || !Array.isArray(statusJson)) {
          statusComplete({ status: resp.status, info: resp.info, order: "" });
          break;
        }

        const statusData = statusJson[0];
        setStatus(statusData);

        if (statusData.order_nr != "" ||
          statusData.order_err != "" ||
          statusData.stat_order == 2 ||
          statusData.stat_order == -1)
        {
          statusComplete({ status: resp.status, info: resp.info || "OK", order: JSON.stringify(statusData) });
          break;
        }

        await new Promise((resolve) => {
          const timeout = (_resolve) => setTimeout(() => _resolve(true), 2000);
          timeout(resolve);
        });
      } while (blockOK == true);

      setBlockOk(false);
    }, 0);
  };

  const statusComplete = (data) => {
    typeof handleComplete == "function" && handleComplete(data);
    setTimeout(() => handleClose(), 0);
  };

  const closeModal = () => {
    setBlockOk(false);

    if (refTimeout != null)
      clearTimeout(refTimeout);

    setTimeout(() => handleClose(), 0);
  };

  return (
    <>
      <Portal elementId="show-modal">
        <NormalDialog
          title={`Status zamówienia`}
          open={true}
          onClose={closeModal}
          showButton={false}
        >
          <div className="w-full mb-4">
            <div className='flex gap-2 items-center'>
              <span>{t('Identyfikator koszyka')}:</span>
              <b>{id}</b>
              {/* <span>{t('Numer dokumentu')}:</span>
              {status.order_nr == "" && status.order_err == "" && <b><IconSpinner /></b>}
              {status.order_nr != "" && status.order_err != "" && <b>{status.order_nr}</b>}
              {status.order_nr == "" && status.order_err != "" && <b>-----------</b>} */}
            </div>
          </div>
          <hr className="my-2 mb-5 border border-dashed border-t-1" />
          <div className="relative stripe loading w-full bg-primary rounded-md dark:bg-gray-700 border border-primary h-5">
            <div
              className="bg-primary text-xs font-medium text-blue-100 text-center leading-none rounded-md h-4.5 py-1 px-1 -mt-px"
              style={{ width: `${Math.round((status.count_comp/(status.count_pos||1))*100)}%` }}
            >
              &nbsp;
            </div>
            <div className='absolute w-full top-0 left-0 h-5 text-center'>
              <b className='relative text-sm leading-5 -top-1'>
                {status.count_pos > 0 && (<>{status.count_comp}&nbsp;/&nbsp;{status.count_pos}</>)}
                {status.count_pos == 0 && (<>&nbsp;</>)}
              </b>
            </div>
          </div>
          {status.order_err != "" && (
            <div className="relative px-4 py-3 mb-2 mt-2 text-center text-red-700 transition bg-red-100 rounded" role="alert">
              <span className="block sm:inline">{status.order_err || "Z koszyka o identyfikatorze ## powstało już zamówienie ZS/B2B/00/000000"}</span>
            </div>
          )}
          {status.validateErrors != "" && (
            <div>
              &nbsp;
            </div>
          )}
          {/* <div className="flex flex-col gap-1 mt-2">
            <div className="flex items-center gap-x-2 break-all">
              {JSON.stringify(status)}
            </div>
          </div> */}
        </NormalDialog>
      </Portal>
    </>
  );
};

export default OrderStatus;
