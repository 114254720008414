import { useSpring, animated } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import styled from 'styled-components';

import ExitIcon from './exiticon';
import IconButton from './iconbutton';

// interface Props {
//   children: React.ReactNode;
//   open: boolean;
//   onClose: Function;
// }

const DivModal = styled.div`
  z-index:1850; /* prevent jqwidgets overlay*/
`;

const ButtonArrow = styled.button`
  background-color: rgba(255,255,255,1);
  box-shadow: 0 0 8px rgba(0,0,0,.25);
  color:#8d9299;

  &:hover {
    background-color: var(--primary);
  }
`;

export default function GalleryDialog({
  children = [<></>],
  dialogKey = 'gallery_full',
  open = false,
  onClose = () => {},
  onChange = (data) => {},
  imageCurr = 0,
  imageCount = 0,
  bgColor = undefined,
  iconColor = undefined,
}) {
  const [{ x }, api] = useSpring(() => ({ x: 0 }))
  const propBgColor = bgColor || "bg-white";
  const propIconColor = iconColor || "text-gray";

  if (!open) {
    return <></>;
  }

  const onChangeImage = (event, current) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (typeof onChange != "function")
      return;

    if (current == imageCurr)
      return;

    if (current < 0)
      current = imageCount-1;

    if (current >= imageCount)
      current = 0;

    onChange({ current });
  };

  const bind = useDrag(({ down, movement: [mx], last }) => {
    if (last) {
      onChangeImage(false, mx < 0 ? imageCurr + 1: imageCurr - 1);
    }
    api.start({ x: down ? mx : 0, immediate: down })
  }, { axis: 'lock', preventDefault: true, filterTaps: true })

  return (
    <DivModal
      className="fixed inset-0 z-50 flex overflow-auto transition bg-gray-800 bg-opacity-50 modal-bg"
      data-key={dialogKey}
      onClick={onClose}
    >

      <div className={`relative w-full max-w-3xl max-h-screen m-auto flex-col flex rounded-lg shadow transition ${propBgColor}`}>
        <ButtonArrow
          type="button"
          className={`absolute flex items-center justify-center w-20 h-20 top-1/2 -mt-10 rounded-full bg-gray-600 -left-24 ${imageCurr < 0 || imageCount <= 1 ? 'hidden': ''}`}
          onClick={(event) => onChangeImage(event, imageCurr - 1)}
        >
          {/* ICON arrow left*/}
          <svg className='w-8 h-8' viewBox="0 0 1024 1024" fill="currentColor">
            <path d="M685.248 104.704a64 64 0 0 1 0 90.496L368.448 512l316.8 316.8a64 64 0 0 1-90.496 90.496L232.704 557.248a64 64 0 0 1 0-90.496l362.048-362.048a64 64 0 0 1 90.496 0z" />
          </svg>
        </ButtonArrow>
        <div className='p-2 overflow-hidden'>
          <animated.div {...bind()} style={{ x, touchAction: "none"}}>
            {children}
          </animated.div>
          {imageCount > 1 && (
            <div className="flex justify-center w-full mx-auto mt-2">
              {imageCount > 0 && Array.from({length: imageCount}, (_, i) => i + 1).map((_, index) => (
                <button
                  type="button"
                  key={`gallery_btn_${index}`}
                  label={`${index + 1}`}
                  onClick={(event) => onChangeImage(event, index)}
                  className="p-2.5"
                >
                  <span className={`w-4 h-4 block rounded-full ${Math.abs(imageCurr) === index ? 'bg-primary' : 'bg-primary opacity-30'}`} />
                </button>
              ))}
            </div>
          )}
        </div>
        <ButtonArrow
          type="button"
          className={`absolute flex items-center justify-center w-20 h-20 -mt-10 rounded-full bg-gray-600 top-1/2 -right-24 ${imageCurr > imageCount-1 || imageCount <= 1 ? 'hidden': ''}`}
          onClick={(event) => onChangeImage(event, imageCurr + 1)}
        >
          {/* ICON arrow right */}
          <svg className="w-8 h-8" viewBox="0 0 1024 1024" fill="currentColor">
            <path d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0z" />
          </svg>
        </ButtonArrow>
        <span className="absolute p-4 -top-1 -right-1">
          <IconButton onClick={() => onClose()}>
            <ExitIcon color={propIconColor} />
          </IconButton>
        </span>
      </div>
    </DivModal>
  );
}
