import { useEffect, useMemo, useState, useRef } from 'react';
import { useErrorHandler } from '../ErrorBoundary';

import api from '../service/api.ts';
import { parseJSON } from '../util';
import styled from 'styled-components';

const Button = styled.button`
  :hover > span {
    display:block;
  }
`

async function getVersion() {
  const response = await api.getVersion();
  const version = response?.data?.dane;
  // console.log('VERSION', version);
  return version;
}

async function getOsobaLog() {
  const response = await api.getOsobaLog();
  const logList = response?.data?.dane;
  console.log('USERLOG', response?.data);
  return logList;
}

const AlertMessage = ({ catalog=false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [version, setVersion] = useState('v0.0.0');
  const [logList, setLogList] = useState([]);
  const refList = useRef();
  const triggerError = useErrorHandler();
  const versionAwait = useMemo(getVersion, []);

  const getVersionAsync = async () => {
    const result = await versionAwait;
    setVersion(result);
  };

  useEffect(() => {
    getVersionAsync();
    if (catalog) return;

    getOsobaLog()
      .then((json) => {
        if (json === '' || json === undefined) return;

        const list = parseJSON(json);
        if (!Array.isArray) return;

        setLogList(list);
        console.log("USER LOG", list);
      });
  }, []);

  const handlerClick = () => {
    if (logList.length <= 0) return;

    if (isOpen === false) setTimeout(() => refList.current.focus(), 100);
    setIsOpen(!isOpen);
  };

  const onHideList = () => isOpen === true && setIsOpen(false);

  const handleError = (e) => {
    e.preventDefault();
    triggerError(new Error('asdasdasdadsdas'));
  };

  const getAlertLevel = function (level) {
    switch (level) {
      case 'warning':
        return (
          <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
            <path d="M19.64 16.36L11.53 2.3A1.85 1.85 0 0 0 10 1.21 1.85 1.85 0 0 0 8.48 2.3L.36 16.36C-.48 17.81.21 19 1.88 19h16.24c1.67 0 2.36-1.19 1.52-2.64zM11 16H9v-2h2zm0-4H9V6h2z" />
          </svg>
        );
      default: return (
        <svg className="w-4 h-4 text-red-500" viewBox="0 0 20 20" fill="currentColor">
          <path d="M19.64 16.36L11.53 2.3A1.85 1.85 0 0 0 10 1.21 1.85 1.85 0 0 0 8.48 2.3L.36 16.36C-.48 17.81.21 19 1.88 19h16.24c1.67 0 2.36-1.19 1.52-2.64zM11 16H9v-2h2zm0-4H9V6h2z" />
        </svg>
      );
    }
  };

  const getDateTime = function (dateTime) {
    return dateTime.split('T')[1].substring(0, 8);
  };

  return (
    <div>
      <div className="relative z-20">
        <Button
          className={`inline-flex outline-none group  ${logList.length > 0 ? '' : 'cursor-auto'}`}
          type="button"
          onMouseDown={handlerClick}
          title={version}
        >
          <svg
            className={`w-4 h-4 ${logList.length > 0 ? 'text-red-500 animate-alert' : 'text-gray-500'} hover:text-primary`}
            viewBox="0 0 1024 1024"
            fill="currentColor"
          >
            <path d="M512 244c176.18 0 319 142.82 319 319v233a32 32 0 0 1-32 32H225a32 32 0 0 1-32-32V563c0-176.18 142.82-319 319-319zM484 68h56a8 8 0 0 1 8 8v96a8 8 0 0 1-8 8h-56a8 8 0 0 1-8-8V76a8 8 0 0 1 8-8zM177.25 191.66a8 8 0 0 1 11.32 0l67.88 67.88a8 8 0 0 1 0 11.31l-39.6 39.6a8 8 0 0 1-11.31 0l-67.88-67.88a8 8 0 0 1 0-11.31l39.6-39.6zm669.6 0l39.6 39.6a8 8 0 0 1 0 11.3l-67.88 67.9a8 8 0 0 1-11.32 0l-39.6-39.6a8 8 0 0 1 0-11.32l67.89-67.88a8 8 0 0 1 11.31 0zM192 892h640a32 32 0 0 1 32 32v24a8 8 0 0 1-8 8H168a8 8 0 0 1-8-8v-24a32 32 0 0 1 32-32zm148-317v253h64V575h-64z"/>
          </svg>
          <span className="relative hidden px-1 transition-opacity duration-150 xs:block">
            {version}
          </span>
        </Button>
        <div
          tabIndex={-1}
          ref={refList}
          onBlur={onHideList}
          className={`absolute transition-opacity mt-1 left-0 rounded bg-white shadow-lg outline-none ${isOpen ? 'opacity-100 visible' : 'invisible opacity-0'}`}
        >
          {logList.length > 0 && (
            <ul
              tabIndex={-1}
              role="listbox"
              aria-labelledby="state-alert-message"
              aria-activedescendant="state-alert-0"
              className="min-w-full py-1 overflow-auto text-sm rounded-md max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              {logList.map((el, index) => (
                <li
                  key={`alert_option_${index}`}
                  role="option"
                  aria-selected="false"
                  className="relative py-1 pl-3 pr-6 cursor-default select-none"
                >
                  <div className="inline-flex items-center text-sm whitespace-nowrap">
                    <span className="pr-2">{getAlertLevel(el.level)}</span>
                    <span className="">{getDateTime(el.time)}</span>
                    <span className="px-2">-</span>
                    <span>{el.msg}</span>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlertMessage;
