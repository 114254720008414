import { useState, useRef } from 'react';
import styled from 'styled-components';

const InputStyle = styled.input`
  &.input-alert:focus {
    border-color: rgba(220, 38, 38);
    --tw-ring-color: rgba(220, 38, 38);
  }
`;

const InputText = ({ className = '', defaultValue = '', onChange = () => {}, tabIndex = 0 }) => {
  const [alert, setAlert] = useState(false);
  const refInput = useRef();

  const getText = () => {
    if (!refInput.current) {
      return 0;
    }

    let text = String(refInput.current.value).replaceAll("  ", " ").replace(/[^\w]/g, "").trim();

    if (text == "") text = defaultValue;

    return text;
  };

  const onChangeText = (e) => {
    const text = getText();

    setAlert(false);
    if (text == "") {
      setAlert(true);
    }

    typeof onChange == "function" && onChange(text);
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <div className="relative flex flex-row">
        <InputStyle
          ref={refInput}
          className={`w-40 text-sm text-left text-gray-600 bg-white border border-gray-300 rounded outline-none pr-11 
          focus:outline-none focus:shadow-none ring-opacity-0 ring-0 focus:ring-1 
            ${className} ${alert ? "input-alert border-red-600" : "" }`}
          onChange={onChangeText}
          type="text"
          defaultValue={defaultValue.replace("  ", " ").trim()}
          tabIndex={tabIndex}
          // autoFocus={true}
          // ref={input => input && setTimeout(() => input.focus(), 10)}
        />
      </div>
    </div>
  );
};

export default InputText;
