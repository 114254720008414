import { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import InputCalendar from '../InputCalendar';
// import { Transition } from '@headlessui/react';
import { debounce } from '../util/index';
// import 'react-calendar/dist/Calendar.css';

const SFilter = ({ symbol, type, filterDefault = {}, handleChange = (objFilter) => {} }) => {
  const [isFilter, setIsFilter] = useState(['', '']);
  const refForm = useRef();

  const typeInput = ((_type) => {
    switch (_type) {
      case 'numeric': return 'numeric';
      case 'date': return 'date';
      default: return 'text';
    }
  })(type);

  useEffect(() => {
    if (filterDefault[symbol] === undefined) {
      // console.log("RESET", filterDefault);
      refForm.current != undefined && refForm.current.reset();
      setIsFilter(['', '']);
    }
  }, [filterDefault]);

  const onChangeDebounce = useCallback( // for non multi
    debounce((_value) => {
      // console.log('GRIDFILTER', symbol, _value);
      setIsFilter([_value[0], _value[1]]);
      typeof handleChange === 'function' && handleChange([symbol, _value]);
    }, 500)
    ,[isFilter],
  );

  // const onChangeDate = (e) => {
  //   const inputC = e.currentTarget.value;
  const onChangeDate = (date) => {
    let dateFrom = new Date(date.from).toDateInput();
    let dateTo = new Date(date.to).toDateInput();
    if (dateFrom == 'NaN.NaN.NaN') dateFrom = '';
    if (dateTo == 'NaN.NaN.NaN') dateTo = '';
    onChangeDebounce([dateFrom, dateTo, 'date']);
  };

  const onChangeText = (e) => {
    const inputC = e.currentTarget.value;
    onChangeDebounce([inputC, '', 'text']);
  };

  const onChangeNumeric = (e, inputNr) => {
    const inputL = inputNr === 0 ? e.currentTarget?.value : e.currentTarget.previousElementSibling?.value;
    const inputR = inputNr === 1 ? e.currentTarget?.value : e.currentTarget.nextElementSibling?.value;
    onChangeDebounce([`${inputL}`, `${inputR}`, 'numeric']);
  };


  return (
    <form ref={refForm} className="flex flex-row p-1">
      {typeInput == 'numeric' && (
        <div className="relative top-0 w-full">
          <input
            placeholder="Min."
            type="number"
            style={{ marginRight: '50%', width: '49%' }}
            name="filter_numeric_min"
            defaultValue={''}
            className={`text-right rounded-sm absolute block  px-2 py-1.5 text-sm text-gray-700 placeholder-gray-400 
              bg-white border border-gray-200 appearance-none focus:bg-white focus:placeholder-gray-600 
              focus:text-gray-700 focus:outline-none ${isFilter[0] !== '' ? 'ring-1 ring-primary border-primary' : 'border-gray-200'}`}
            onChange={(e) => onChangeNumeric(e, 0)}
          />
          <input
            placeholder="Max."
            type="number"
            style={{ marginLeft: '50%', width: '49%' }}
            name="filter_numeric_max"
            defaultValue={''}
            className={`text-right rounded-sm absolute block  px-2 py-1.5 text-sm text-gray-700 placeholder-gray-400 
            bg-white border border-gray-200 appearance-none focus:bg-white focus:placeholder-gray-600 
            focus:text-gray-700 focus:outline-none ${isFilter[1] !== '' ? 'ring-1 ring-primary border-primary' : 'border-gray-200'}`}
            onChange={(e) => onChangeNumeric(e, 1)}
          />
          <span className="block w-0 pt-px pb-px leading-8">&nbsp;</span>
        </div>
      )}
      {typeInput == 'date' && (
        <InputCalendar 
          onChange={onChangeDate}
          focus={isFilter[0] !== '' && isFilter[1] !== ''}
          defaultValue={[isFilter[0], isFilter[1]]}
        />
      )}
      {typeInput == 'text' && (
        <input
          placeholder="Tekst..."
          type="text"
          defaultValue=""
          className={`rounded-sm block w-full px-2 py-1.5 text-sm text-gray-700 placeholder-gray-400 bg-white border 
          border-gray-200 appearance-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 
            focus:outline-none ${isFilter[0] !== '' ? 'ring-1 ring-primary border-primary' : 'border-gray-200'}`}
          onChange={onChangeText}
        />
      )}
    </form>
  );
};

SFilter.propTypes = {
  symbol: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  filterDefault: PropTypes.object,
  handleChange: PropTypes.func,
};

SFilter.defaultProps = {
  filterDefault: ({}),
  handleChange: () => {},
};

export default SFilter;
