import { PropTypes } from 'prop-types';
//import { numberFormat } from '../util/index'

import useSNavigate from './snavigate.ts';
import SCheckbox from './scheckbox';
import SSelect from './sselect';
import { debounce } from '../util/index';

const defaultClassName = 'px-5 py-3 whitespace-no-wrap text-base';

const articleGoToPage = debounce((direction) => {
  if (document.location.pathname != "/article") return;

  const hash = document.location.hash.split("|");
  if (hash.length < 2) hash.push(0);

  if (parseInt(hash[1], 10) + direction < 0) return;
  document.activeElement?.blur();

  document.location.hash = hash[0] + "|"+ (parseInt(hash[1], 10) + direction);
}, 250);

const SColumn = ({
  name,
  symbol,
  data,
  tabindex,
  rowindex,
  ref = null,
  template = '',
  groupGrid = undefined,
  groupTemplate = '',
  width='',
  type='char',
  typeColumn = 'string',
  defaultValue = '',
  className = defaultClassName
}) => {
  const { handleNavigate } = useSNavigate({
    handleChange(command) {
      // console.log('Navigate change', command);

      switch (command) {
        case 'pageNext':
          articleGoToPage(+1);
          break;
        case 'pagePrev':
          articleGoToPage(-1);
          break;
        default: break;
      }
    },
  });

  let value = data[symbol] ?? defaultValue;

  // if (data[symbol] == undefined) return ''; // for check exist obj

  if (typeColumn === 'number') value = parseInt(value, 10) || parseInt(defaultValue, 10);
  // if (typeColumn === 'currency') value = numberFormat(`${value || defaultValue}`);
  if (typeColumn === 'select') value = <SSelect value={[...value]} />;
  if (typeColumn === 'checkbox') {
    value = <SCheckbox />;
    className = 'px-3 py-3 ml-3 whitespace-no-wrap';
  }

  return (
    <td
      role="gridcell"
      onKeyDown={(e) => handleNavigate(e)}
      className={className}
      width={width}
      tabIndex={tabindex}
      data-name={name}
    >
      {groupGrid !== undefined && typeof groupTemplate === 'function' ? groupTemplate(data, rowindex, groupGrid, ref) : ''}
      {groupGrid === undefined && typeof template === 'function' ? template(data, rowindex, ref) : value}
    </td>
  );
};

SColumn.propTypes = {
  name: PropTypes.string,
};

SColumn.defaultProps = {
  name: '',
};

export default SColumn;
