/* eslint-disable */
// @ts-ignore: Unreachable code error
window.process = {};

import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import './style/tailwind.css';
import './style/index.css';
import 'react-toastify/dist/ReactToastify.css';
// test
import { GlobalContextProvider } from './components/context/global';
import { RouterContextProvider } from './components/router/index';

import { ToastContainer, toast } from "react-toastify";

import ModalContainer from './components/modal/container';
import ErrorBoundary from './components/ErrorBoundary';
// import { ErrorFallback } from './components/ErrorBoundary';

// import * as Sentry from '@sentry/browser';
// import AlertDialog from './components/modal/AlertDialog';

// // const dsn = process.env.NODE_ENV === 'development' ? 'http://public@localhost:8080/2' : `${window.location.host}//public@${window.location.host}/2`

// // Sentry.init({dsn});

// import * as serviceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
unregister();

// import {Helmet} from 'react-helmet';
// import config from '../plugins/site.json';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <GlobalContextProvider>
        {/* <Helmet>
            <meta charSet="utf-8" />
            <title></title>
            <link rel="canonical" href="" />
          </Helmet> 
        */}
        <RouterContextProvider /> 
        <ToastContainer limit={1} />
        <ModalContainer />
      </GlobalContextProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// serviceWorker.register({
//   onSuccess: () => {
//     // toast('🚀 Content is cached for offline use.')
//     console.warn('SW onSuccess');
//   },
//   onUpdate: registration => {
//     toast.info('🔄 Nowa zawartość portalu. Proszę odświeżyć stronę.');
//     console.warn('SW onUpdate', registration);
//   },
//   onLine: () => toast('🦄 Wróciło połączenie z internetem...'),
//   offLine: () => toast.warn('Strona w trybie offline'),
// });
