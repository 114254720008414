import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// import { usePopper } from 'react-popper';
import { GlobalStateContext } from '../context/global';

import useCartAction from '../hook/cartaction';
import { IconCart } from '../icon';
import { numberFormatCurrency } from '../util/index';

import PopCart from './popcart';
import { useTranslation } from 'react-i18next';

const BigCart = () => {
  const globalState = useContext(GlobalStateContext);
  const { t } = useTranslation("home");
  const [state, actions] = useCartAction();
  const navigate = useNavigate();
  const [refCart, setRefCart] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const [priceSum, setCartPriceSum] = useState(0);
  const [productCount, setCartProductCount] = useState(0);
  const [cartCurrency, setCartCurrency] = useState("PLN");
  // const [, setCartCount] = useState(0);

  const getCartProductCount = (_state) => _state.count;
  const getCartPriceSum = (_state) => _state.price;
  const getCartCurrency = (_state) => _state.currency;

  let popupTimeout = 0;
  let isMounted = false;

  useEffect(() => {
    actions.calcCart();
  }, []);

  useEffect(() => {
    isMounted = true;
    const sum = getCartPriceSum(state.cart.summary);
    const productCnt = getCartProductCount(state.cart.summary);
    const currency = getCartCurrency(state.cart.summary);
    console.log('CART BIG REFRESH', sum, productCnt);

    isMounted && setCartPriceSum(sum);
    isMounted && setCartProductCount(productCnt);
    isMounted && setCartCurrency(currency);

    return () => {
      clearTimeout(popupTimeout);
      isMounted = false;
    };
  }, [state.summary]);

  const enterCart = () => {
    // console.log('CART CNT', cartCount);
    navigate('/cart');
  };

  const overCart = (e) => {
    e.stopPropagation();
    // e.preventDefault();
    clearTimeout(popupTimeout);
    if (showPopup === true || getCartProductCount(state.cart.summary) <= 0) return;

    popupTimeout = setTimeout(() => {
      setShowPopup(true);
    }, 0);
  };

  const outCart = (e) => {
    e && e.stopPropagation();
    // e.preventDefault();
    if (showPopup === false) return;

    popupTimeout = setTimeout(() => {
      setShowPopup(false);
    }, 100);
  };

  const onGoTo = (cartId) => {
    actions.selectCart(cartId, 'Z');
    outCart(false);

    if (location.pathname !== '/cart') {
      navigate('/cart');
    }
  };

  return (
    <>
      <button
        ref={setRefCart}
        onClick={enterCart}
        onMouseEnter={overCart}
        onMouseLeave={outCart}
        type="button"
        data-title={t('koszyk')}
        data-placement="top"
        className={`${window.location.pathname === '/cart' ? 'active' : ''} group flex flex-row content-center items-center justify-center 
          py-4 px-2 xs:px-4 xs:pr-0 pr-0 border-0 border-transparent text-gray-800 rounded-full hover:text-primary focus:outline-none`}
        aria-label="Notifications"
        data-testid="gorna_belka-koszyk"
      >
        <span className="hidden mt-1 mr-2 font-sans font-medium leading-4 xm:block">
          {numberFormatCurrency(priceSum || 0, cartCurrency)}
        </span>
        <IconCart size="8" />
        <span className="relative -top-2 right-2">
        <span className={`${productCount <= 0 ? 'hidden' : ''} flex justify-center text-center`}>
          <span
            className="px-1 font-sans text-xs font-bold text-white rounded-full bg-primary group-hover:bg-gray-800"
            style={{ minWidth: '17px', height: '17px', paddingTop: '1px' }}
          >
            {productCount}
          </span>
        </span>
      </span>
      </button>
      {showPopup && (
        <div onMouseEnter={overCart} onMouseLeave={outCart}>
          <PopCart refElement={refCart} onGoTo={onGoTo} />
        </div>
      )}
    </>
  );
};

export default BigCart;
