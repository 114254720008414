import { useContext, useEffect, useState } from 'react';
// import { PropTypes } from 'prop-types';
import styled from 'styled-components';

import { GlobalStateContext } from '../context/global';

import CartContext from '../context/cart.ts';
import CartDialog from '../modal/CartDialog';

import './style.css';
import { IconCart } from '../icon';
import { useTranslation } from 'react-i18next';


const Button = styled.button`
  &:hover + a.icon-cross:not(.hidden) {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
`;

const CartButton = ({ value = 1, max = 0, maxActual = 0, disable = false, defaultClassName='', type='normal', templateCount = (<></>), noDialog = false }) => {
  const globalState = useContext(GlobalStateContext);
  const [quantity, setQuantity, loading, error, done] = useContext(CartContext);

  const { t } = useTranslation('shop');

  const [showDialog, setShowDialog] = useState(false);
  const maxLimit = 1000000;

  if (typeof max === 'boolean') {
    max = max === true ? -1 : 0;
  } else if (max < 0) {
    max = max == -1 ? -1 : 0;
  }

  if (value < 0) value = 0;

  const addToCartQuantity = (e) => {
    // if (e.target.tagName === 'BUTTON') return;
    if (max === 0 || loading || disable || +quantity.confirm >= maxLimit) return;

    if (max !== -1 && (+quantity.input) >= max) // maxActual = max quantity in input for jm
      quantity.confirm = parseFloat(maxActual);
    else
      quantity.confirm = +quantity.input + parseFloat(quantity.confirm);

    if (quantity.confirm > maxLimit)
      quantity.confirm = parseFloat(maxLimit);

    quantity.input = value;
    setQuantity({ ...quantity });
  };

  const removeFromCart = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (loading) return;

    quantity.confirm = 0;
    setQuantity({ ...quantity });
  };

  useEffect(() => {
    if (noDialog) return;
    if (done == false || loading == true || error != 0) return;
    if (!(quantity.confirm > 0)) return;
    if (globalState.user.cart_dialog != true) return;
    if (done.symbol != quantity.symbol) return;

    setShowDialog(true);
  }, [done]);

  // useEffect(() => {
  //   console.log('CARTBUTTON QUANTITY', quantity);
  // }, [quantity]);

  return (
    <>
      {type === 'big' && (
        <div className="relative">
          <Button
            onClick={addToCartQuantity}
            type="button"
            data-name="cart-button"
            className={`
              relative group 
              ${max === 0 || loading || disable || +quantity.confirm >= maxLimit ? 'text-gray-300 cursor-not-allowed' 
              : error === quantity.hash ? 'text-red-500' 
              : quantity.confirm === 0 ? 'text-gray-800 hover:text-primary hover:border-primary' 
              : 'text-primary hover:text-primary border-primary' } 

              focus:outline-none w-auto py-0 px-3 font-semibold flex items-center justify-center transition ease-in-out 
              rounded-lg bg-white border border-gray-300 h-9
              `}
            disabled={max === 0 || disable || loading || +quantity.confirm > maxLimit}
          >
            <IconCart size="6" loading={loading} className={`${max === 0 || disable || +quantity.confirm >= maxLimit ? 'group-hover:text-gray-300' : ''}`} />
            <div className='flex flex-col'>
              <span
                className={`ml-1 text-sm leading-3 ${max === 0 || disable || +quantity.confirm >= maxLimit ? 'text-gray-300' : 'text-gray-700 group-hover:text-primary'} ${(quantity.confirm) > 0 ? 'text-primary' : ''}`}
                data-testid="karta_do_koszyka"
              >
                {t('do\u00A0koszyka')}
              </span>
              {templateCount}
            </div>
          </Button>
          <a
            href={void(0)}
            className={`${(quantity.confirm) === 0 ? 'hidden' : 'opacity-0'} icon-cross w-4 h-4 absolute -left-2 -top-2 flex justify-center text-center 
              hover:cursor-pointer hover:opacity-100`}
            onClick={removeFromCart}
          >
            <span className="relative text-white hover:text-white hover:bg-w-black">
              <svg className="w-4 h-4 bg-red-500 rounded-full" viewBox="-220 -220 800 800" fill="currentColor">
                <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
              </svg>
            </span>
          </a>
        </div>
      )}
      {type !== 'big' && (
        <div className="relative">
          <Button
            onClick={addToCartQuantity}
            type="button"
            data-name="cart-button"
            className={`relative group ${max === 0 || loading || disable || +quantity.confirm >= maxLimit ? 'text-gray-300 hover:text-gray-300 cursor-not-allowed' : error === quantity.hash ? 'text-red-500' : quantity.confirm === 0 ? 'text-gray-800 hover:text-primary' : 'text-primary hover:text-primary' } 
              focus:outline-none w-auto py-0 px-2 font-semibold flex items-center justify-center transition ease-in-out 
              ${defaultClassName} 
            `}
            disabled={max === 0 || disable || loading || +quantity.confirm > maxLimit}
            // data-disabled={`${max}, ${disable}, ${loading}, ${+quantity.confirm}, ${maxLimit}`}
          >
            <IconCart size="6" loading={loading} className={`${max === 0 || disable || +quantity.confirm >= maxLimit ? 'group-hover:text-gray-300' : ''}`} />
          </Button>
          <a
            href={void(0)}
            className={`${(quantity.confirm) === 0 ? 'hidden' : 'opacity-0'} icon-cross w-4 h-4 absolute -left-2 -top-2 flex justify-center text-center 
              hover:cursor-pointer hover:opacity-100`}
            onClick={removeFromCart}
          >
            <span className="relative text-white hover:text-white hover:bg-w-black">
              <svg className="w-4 h-4 bg-red-500 rounded-full" viewBox="-220 -220 800 800" fill="currentColor">
                <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
              </svg>
            </span>
          </a>
        </div>
      )}
      {showDialog == true && (
        <CartDialog
          title={(<><b className='text-green-500'>✓</b> {t('Produkt został dodany do koszyka')}</>)}
          onClose={() => setShowDialog(false)}
          dialogKey={`cart_dialog__${quantity.symbol}`}
          article={quantity}
        />
      )}
    </>
  );
};

CartButton.propTypes = {
};

export default CartButton;
