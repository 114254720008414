import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import useFileCache from '../hook/useFileCache';
import AlertDialog from '../modal/AlertDialog';
import api from '../service/api.ts';
import { isPopupBlocked, parseJSON } from '../util/index';

const getDocuments = async (symbol) => {
  const resp = await api.getArticleFiles(symbol, 'P');
  if (!resp?.data || resp.data?.status != 0) return [];

  const files = parseJSON(resp.data.dane);
  if (!Array.isArray(files)) return [];

  return files.map(el => 
    el.replace(/^.*[\\\/]/, '')
  );
};

const DocumentList = ({ symbol, handleCount = (count) => {} }) => {
  const [isDone, setIsDone] = useState(true);
  const [files, setFiles] = useState([]);
  const [, loadArticleDocument] = useFileCache();
  let isMounted = false;

  useEffect(() => {
    isMounted = true;
    getDocuments(symbol).then(list => {
      if (!isMounted) return;

      setFiles(list);
      typeof handleCount == "function" && handleCount(list.length);
    });

    return () => {
      isMounted = false;
    }
  }, []);

  const downloadFile = (e, num, fileName) => {
    console.log('downloadFile', num);
    e.preventDefault();
    setIsDone(false);

    loadArticleDocument(symbol, num).then(dataFile => {
      if (dataFile.url == null) {
        AlertDialog({
          title: 'Informacja',
          message: 'Nie udało się pobrać pliku',
          color: 'yellow',
        });
        
        return;
      }


      if (dataFile.url != null) {
        const isDownloadFile = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'application/pdf'].some(x => x == dataFile.mimeType);

        if (!isDownloadFile) {
          const a = document.createElement("A");
          a.style = 'display: none';
          a.setAttribute("href", dataFile.url);
          a.setAttribute("download", fileName);
          document.getElementsByTagName("body")[0].append(a);
          a.click();
          window.URL.revokeObjectURL(dataFile.url);
          a.remove();

          return;
        }

        const opener = window.open(dataFile.url);

        isPopupBlocked(opener, (status) => {
          if (status != true) return;

          toast.error('🚀 Zablokowane okienko!', {
            position: 'top-right',
            autoClose: 2000,
          });
        });
      }
    }).finally(() => {
      setIsDone(true);
    });
  };

  return (
    <ul>
      {files.length > 0 && files.map((file, index) =>
        <li key={`document_list_${index}`}>
          <a
            className={`${!isDone && 'cursor-not-allowed'} text-gray-500 flex flex-row mb-2 hover:cursor-pointer`}
            onClick={(e) => downloadFile(e, index, file)}
            href={void(0)}
          >
            <svg className={`${!isDone && 'hidden'} w-5 h-5`} viewBox="0 0 512 512" style={{ enableBackground: "new 0 0 512 512" }}>
              <path style={{ fill: "#E2E5E7" }} d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z" />
              <path style={{ fill: "#B0B7BD" }} d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z" />
              <polygon style={{ fill: "#CAD1D8" }} points="480,224 384,128 480,128 "/>
              <path style={{ fill: "#F15642" }} d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16V416z" />
              <g>
                <path style={{ fill: "#FFFFFF" }} d="M101.744,303.152c0-4.224,3.328-8.832,8.688-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48
                  c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.688-3.184-8.688-8.816V303.152z
                  M118.624,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H118.624z" />
                <path style={{ fill: "#FFFFFF" }} d="M196.656,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.592,4.608-7.936,8.832-7.936h29.296
                  c58.464,0,57.184,88.528,1.152,88.528H196.656z M204.72,311.088V368.4h21.232c34.544,0,36.08-57.312,0-57.312H204.72z" />
                <path style={{ fill: "#FFFFFF" }} d="M303.872,312.112v20.336h32.624c4.608,0,9.216,4.608,9.216,9.072c0,4.224-4.608,7.68-9.216,7.68
                  h-32.624v26.864c0,4.48-3.184,7.92-7.664,7.92c-5.632,0-9.072-3.44-9.072-7.92v-72.672c0-4.592,3.456-7.936,9.072-7.936h44.912
                  c5.632,0,8.96,3.344,8.96,7.936c0,4.096-3.328,8.704-8.96,8.704h-37.248V312.112z" />
              </g>
              <path style={{ fill: "#CAD1D8" }} d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z" />
            </svg>
            <svg className={`${isDone && 'hidden'} animate-spin h-5 w-5 text-primary`} fill="none" viewBox="0 0 24 24">
              <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
            <span className="pl-2 text-sm hover:text-primary">{file}</span>
          </a>
        </li>
      )}
    </ul>
  )
};

export default DocumentList;