import { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { throttle } from './util/index';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
`;

const Button = styled.button`
  position: fixed; 
  width: 3rem;
  height: auto;
  bottom: 60px;
  right:25px;
  align-items: center;
  justify-content: end;
  z-index: 100000;
  cursor: pointer;
  animation: ${fadeIn} 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  &:focus {
    outline: 0;
  }
`;

const ScrollArrow = () => {
  const [showScroll, setShowScroll] = useState(false);
  const throttleLimit = 1000;
  let checkIfScrollToIsFinished = null;
  let timeoutScrollTop = null;

  const checkScrollTop = () => {
    // console.log("scrollTop", showScroll, window.pageYOffset);
    clearTimeout(timeoutScrollTop);

    timeoutScrollTop = setTimeout(() => {
      if (!showScroll && window.pageYOffset > 400)
        setShowScroll(true);
      else
      if (showScroll && window.pageYOffset <= 401)
        setShowScroll(false);
    }, throttleLimit);
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    let scrollCounter = 0;
    checkIfScrollToIsFinished = setInterval(() => {
      if (window.pageYOffset <= 0 || (scrollCounter++) >= 2000) {
        setShowScroll(false);
        clearInterval(checkIfScrollToIsFinished);
      }
    }, 25);
  };

  useEffect(() => {
    // const runFunc = throttle(checkScrollTop, throttleLimit);
    const runFunc = checkScrollTop;
    window.addEventListener('scroll', runFunc, { passive: true });

    return () => {
      if (checkIfScrollToIsFinished !== null) clearInterval(checkIfScrollToIsFinished);
      window.removeEventListener('scroll', runFunc);
    };
  }, [showScroll]);

  return (
    <Button
      type="button"
      label="scrollArrow"
      className={`scrollTop ${showScroll ? 'flex' : 'hidden'}`}
      onClick={scrollTop}
    >
      <svg className="w-12 h-12 text-primary" xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke="currentColor" viewBox="0 0 512 512">
        <path d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.853,256-256S397.167,0,256,0z M256,472.341
          c-119.275,0-216.341-97.046-216.341-216.341S136.725,39.659,256,39.659c119.295,0,216.341,97.046,216.341,216.341
          S375.275,472.341,256,472.341z"
        />
        <path d="M369.227,283.365l-99.148-99.148c-7.734-7.694-20.226-7.694-27.96,0l-99.148,99.148c-6.365,7.416-6.365,18.382,0,25.798
          c7.119,8.309,19.651,9.28,27.96,2.161L256,226.256l85.267,85.069c7.734,7.694,20.226,7.694,27.96,0
          C376.921,303.591,376.921,291.098,369.227,283.365z"
        />
      </svg>
    </Button>
  );
};

export default ScrollArrow;
