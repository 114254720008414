import { useState, useRef, useEffect, Suspense } from 'react';
import styled from 'styled-components';
import { render, unmountComponentAtNode } from 'react-dom';
import { useTranslation, Trans } from 'react-i18next';

import ValidDelivery from './ValidDelivery.tsx';
import ConfirmDialog from '../../components/modal/index';

const DivCloud = styled.div`
  // max-height: calc(100vh - 94px);
  & ul {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--primary) #f8f8f8;
  }

  & ul::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }  
  
  & ul li button.status-active {
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(0, 220, 60, 0.1) 10px, rgba(0, 220, 60, 0.1) 20px);
  }
`;

const SelectDelivery = ({
  name,
  defaultValue = "",
  readOnly = false,
  list = ([{ value: "", key: "", label:"" }]),
  delivery = ({ address: "", city: "", homeno: "", localno: "", name: "", postalcode: "", country: "" }),
  onChangeSelect = (value) => {},
  onChangeDelivery = (value) => {},
  fieldOption = { visible: false, handleField: (key) => {} },
  className = '',
}) => {
  const { t } = useTranslation('cart');

  const [selected, setSelected] = useState(defaultValue); // key
  const [isOpen, setIsOpen] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const refList = useRef();
  const regionNames = new Intl.DisplayNames(['pl'], { type: 'region' });

  const onShowList = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // if (delivery.name != "") return;

    setIsOpen(!isOpen);
    showInput === true && setShowInput(false);

    setTimeout(() => {
      refList.current.focus();
    }, 100);
  };

  const onHideList = () => {
    setIsOpen(false);
    showInput === true && setShowInput(false);
  };

  const onSelect = (e, value) => {
    e.preventDefault();
    
    if (delivery.name != "") {
      openModalForChangeToStandard(value);
      return true;
    }

    if (readOnly) return false;

    setSelected(value);
    setTimeout(() => onChangeSelect(value), 10);

    return false;
  };

  const openModalForDelivery = () => {
    const container = document.getElementById('show-modal');

    const closeModal = () => {
      unmountComponentAtNode(container);
    };

    const confirmModal = (data) => {
      setTimeout(() => onChangeDelivery(data), 10);
      // cartHook.getWholeCart().catch(err => {
      //   console.log('CART', err);
      // });
      closeModal();
    };

    const suspenseDiv = (
      <Suspense fallback="loading...">
        <ValidDelivery
          title={'Inny adresu dostawy'}
          list={delivery}
          onClose={closeModal}
          onConfirm={confirmModal}
        />
      </Suspense>
    );

    render(suspenseDiv, container);
  };

  const openModalForChangeToStandard = (value) => {
    const container = document.getElementById('show-modal');

    const deliveryChange = () => {
      const data = ({ name:"" });

      setTimeout(() => {
        onChangeDelivery(data);
        setSelected(value);
        onChangeSelect(value);
      }, 10);

      closeModal();
    };

    const closeModal = () => {
      unmountComponentAtNode(container);
    };

    const suspenseDiv = (
      <Suspense fallback="loading...">
        <ConfirmDialog
          title={"Zmiana miejsca dostawy"}
          open={true}
          onClose={closeModal}
          onConfirm={() => deliveryChange()}
        >
            Zmiana miejsca dostawy spowoduje usunięcie <b>Inny adres</b>.<br />
            Kontynuuować?
        </ConfirmDialog>,
      </Suspense>
    );

    render(suspenseDiv, container);
  };

  const openDialogForDeliveryDelete = () => {
    const container = document.getElementById('show-modal');

    const closeModal = () => {
      unmountComponentAtNode(container);
    };

    const deliveryDelete = () => {
      const data = ({ name:"" });
      setTimeout(() => onChangeDelivery(data), 10);
      closeModal();
    };

    render(
      <ConfirmDialog
        title={"Inny adres dostawy"}
        open={true}
        onClose={closeModal}
        onConfirm={() => deliveryDelete()}
      >
        Czy na pewno usunąć <b>Inny adres</b> dostawy?
      </ConfirmDialog>,
      container,
    );
  };

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <div className="relative max-w-sm">
        <input type="hidden" name={name} value={selected} />
        <div
          className={`relative flex flex-col bg-white border rounded shadow-sm cursor-default 
            ${isOpen ? 'border-primary ring-primary' : 'border-gray-300 ring-transparent'} 
            ring-1 ${className} 
          `}
        >
          {fieldOption.visible == true && delivery.name == "" && (
            <div
              className="relative w-full border-b border-gray-300 border-dashed cursor-default select-none text-primary hover:text-white hover:bg-primary"
            >
              <button
                type="button"
                onMouseDown={openModalForDelivery}
                className="w-full py-2 pl-3 pr-6 outline-none"
              >
                <div className="flex items-center content-center">
                  <span className="block -mt-px text-sm font-normal truncate">
                    <Trans t={t} i18nKey={'_AddAddress'}>Dodaj <b>inny adres</b></Trans>
                  </span>
                </div>
                {list.length > 0 && (
                  <span className="absolute inset-y-0 flex items-center pr-2 right-px">
                    <svg className="w-4 h-4" viewBox="0 0 512 512" fill="currentColor">
                      <path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm112 277.332031h-90.667969v90.667969c0 11.777344-9.554687 
                        21.332031-21.332031 21.332031s-21.332031-9.554687-21.332031-21.332031v-90.667969h-90.667969c-11.777344 0-21.332031-9.554687-21.332031-21.332031s9.554687-21.332031 21.332031-21.332031h90.667969v-90.667969c0-11.777344 
                        9.554687-21.332031 21.332031-21.332031s21.332031 9.554687 21.332031 21.332031v90.667969h90.667969c11.777344 0 21.332031 9.554687 21.332031 21.332031s-9.554687 21.332031-21.332031 21.332031zm0 0" 
                      />
                    </svg>
                  </span>
                )}
              </button>
            </div>
          )}
          {delivery.name != "" && (
            <div
              className="relative w-full text-red-500 border-b border-gray-300 border-dashed cursor-default select-none hover:text-white hover:bg-red-500"
            >
              <button
                type="button"
                onMouseDown={openDialogForDeliveryDelete}
                className="w-full py-2 pl-3 pr-6 outline-none"
              >
                <div className="flex items-center content-center">
                  <span className="block -mt-px text-sm font-normal truncate">
                    <Trans t={t} i18nKey={'_DelAddress'}>Usuń <b>inny adres</b></Trans>
                  </span>
                </div>
                <span className="absolute inset-y-0 flex items-center my-1.5 mr-2 right-px text-red-500 bg-white border border-red-600 rounded-full right-px group-hover:bg-red-600 border-px">
                  <svg className="w-5 h-5 border-2 border-transparent rounded-full" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M8 1.5V2.5H3C2.44772 2.5 2 2.94772 2 3.5V4.5C2 5.05228 2.44772 5.5 3 5.5H21C21.5523 5.5 22 5.05228 22 4.5V3.5C22 2.94772 21.5523 2.5 21 2.5H16V1.5C16 0.947715 15.5523 0.5 15 0.5H9C8.44772 0.5 8 0.947715 8 1.5Z" />
                    <path d="M3.9231 7.5H20.0767L19.1344 20.2216C19.0183 21.7882 17.7135 23 16.1426 23H7.85724C6.28636 23 4.98148 21.7882 4.86544 20.2216L3.9231 7.5Z" />
                  </svg>
                </span>
              </button>
            </div>
          )}
          <button
            type="button"
            // aria-haspopup="listbox"
            // aria-labelledby="listbox-label"
            className={`relative w-full py-2 pr-9 text-left sm:text-sm`}
            onMouseDown={onShowList}
          >
            <span
              className="relative z-0 items-start block w-full pl-3"
            >
              {delivery.name != "" && (
                <>
                  <b className='break-all'>{delivery.name}</b><br />
                  <span className='break-all'>{delivery.address}</span> {delivery.homeno} {delivery.localno != "" ? " - "+ delivery.localno : ""}<br />
                  {delivery.postalcode} {delivery.city}
                  {!!delivery?.country && (<><br />{regionNames.of(delivery.country)}</>)}
                </>
              )}
              {delivery.name == "" && (list.length > 0 && list.find(el => el.value == selected)?.label || '------')}
            </span>
            <p className={`absolute inset-y-0 right-0 flex items-center pl-2 pr-3 z-10`}>
              {/* <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 3a1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" />
              </svg> */}
              <svg className="w-3 h-3 text-gray-500" viewBox="-5 -6 38 38" fill="currentColor">
                <path d="M29.994,10.183L15.363,24.812L0.733,10.184c-0.977-0.978-0.977-2.561,0-3.536c0.977-0.977,2.559-0.976,3.536,0 l11.095,11.093L26.461,6.647c0.977-0.976,2.559-0.976,3.535,0C30.971,7.624,30.971,9.206,29.994,10.183z"/>
              </svg>
            </p>
          </button>
        </div>
        <DivCloud
          tabIndex={-1}
          ref={refList}
          onBlur={onHideList}
          className={`absolute w-full z-20 transition-opacity mt-1 right-0 rounded bg-white shadow-lg outline-none ${isOpen ? 'opacity-100 visible' : 'invisible opacity-0'}`}
        >
          {list.length > 0 && (
            <ul
              role="listbox"
              // aria-labelledby="state-cart-label"
              // aria-activedescendant="state-cart-0"
              className="w-full py-1 overflow-auto text-sm rounded-md max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              {list.filter(el => !readOnly || selected == el.value).map(el => (
                <li
                  key={`prop_selectdelivery_choose_${el.value}`}
                  role="option"
                  className={`relative cursor-default select-none hover:bg-gray-200 ${delivery.name == "" && selected == el.value ? 'bg-primary text-white hover:text-primary' : 'text-gray-700 '}`}
                >
                  <button
                    type="button"
                    onMouseDown={(e) => onSelect(e, el.value)}
                    className="w-full px-3 py-2 outline-none"
                  >
                    <div className="flex items-start">
                      <span
                        className="block text-sm font-normal text-left truncate"
                      >
                        {el.label}
                      </span>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          )}
        </DivCloud>
      </div>
    </>
  );
};

export default SelectDelivery;
