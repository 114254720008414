import { useState, useRef, useEffect } from 'react';

import ForwardDialog from '../../components/modal/forward';
import Portal from '../../components/context/portal';
import api from '../../components/service/api';
import AlertDialog from '../../components/modal/AlertDialog';
import { numberFormatCurrency, numberFormatQuantity } from '../../components/util';
// import { numberFormatCurrency, parseJSON } from '../../components/util/index';

import styled from 'styled-components';

const P = styled.p`
  span {
    font-size:1.0em;
    font-weight:500;
  }
`;

const Ul = styled.ul`
  max-height:300px;
  overflow-y:auto;
`;

type ProductList = {
  label: string,
  nazwa_art: string,
  ilosc: number,
  symbol_jmp: number,
  cena: number,
  actualPrice: string,
  actualStock: string,
  cart_id : number,
  currency: string,
};

const unlockOrder = async (key) => {
  const resp = await api.unlockOrder(key);
  return resp?.data;
};

const deleteOrder = async (key) => {
  const resp = await api.deleteOrder(key);
  return resp?.data;
};

const ValidProduct = (
    { title = 'Aktualizacja produktów w zamówieniu', order = '' , list = [], onClose = function(e: Event){ }, onConfirm = function(e: Event){ } } 
    : 
    { title:string, order: string, list : Array<ProductList>, onClose : Function, onConfirm : Function } 
) => {

  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);

  const confirmModal = (e) => {
    if (loadingConfirm == true) return;

    setLoadingConfirm(true);

    unlockOrder(order).then(async data => {
      if (data?.status != 0) {
        return;
      }

      setTimeout(() => {
        AlertDialog({
          title: "Informacja",
          message: `Zamówienie nr ${order} zostało potwierdzone`,
          color: "green"
        });
      }, 0);
    }).finally(() => {
      setLoadingConfirm(false);
      onConfirm(e);
    });
  };

  const cancelModal = (e) => {
    if (loadingCancel == true) return;
    setLoadingCancel(true);

    deleteOrder(order).then(data => {
      if (data?.status != 0) {
        return;
      }

      setTimeout(() => {
        AlertDialog({
          title: "Informacja",
          message: `Zamówienie nr ${order} zostało anulowane`,
          color: "pink"
        });
      }, 0);
    }).finally(() => {
      setLoadingCancel(false);
      onClose(e);
    });
  };

  return (
    <>
      {/* <Portal elementId="show-modal"> */}
        <ForwardDialog
          title={title}
          open
          onClose={cancelModal}
          onGoTo={confirmModal}
          textGoTo={(
            <div className="flex flex-row">
              <svg className={`${loadingConfirm ? '' : 'hidden'} animate-spin mr-3 h-5 w-5 text-white`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
              <span>Potwierdź</span>
            </div>
          )}
          textCancel={(
            <div className="flex flex-row">
              <svg className={`${loadingCancel ? '' : 'hidden'} animate-spin mr-3 h-5 w-5 text-white`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
              <span>Anuluj</span>
            </div>
          )}
        >
          <>
            <P>
              Numer zamówienia: <span>{order}</span>
            </P>
            {list.length > 0 && (
              <div className="w-full pt-4">
                <p className='text-sm text-gray-500'>
                  Lista artykułów:
                </p>
                <Ul>
                  {list.map((val, index) => 
                    <li key={`valid_product_list_${index}`} className={`${index % 2 == 0 ? 'bg-gray-100' : ''} p-2`}>
                      <b className='font-normal'>{val?.nazwa_art}</b> ({val?.label})
                      <p className='text-sm'>
                      {((val.actualPrice || 0) != val.cena) && (val.actualPrice || 0) > 0 && (
                        <>
                          <span className='px-1 text-gray-700 line-through' translate="no">
                            {numberFormatCurrency(val.cena, val?.currency)}
                          </span>
                          <span className='px-1 text-red-500' translate="no">
                            {numberFormatCurrency(val.actualPrice || 0, val?.currency)}
                          </span>
                        </>
                      )}
                      {((val.actualPrice || 0) != val.ilosc) && (
                        <>
                          {((val.actualPrice || 0) != val.cena) && (val.actualPrice || 0) > 0 && (
                            <span className="px-2"> | </span>
                          )}
                          <span className='px-1 text-gray-700 line-through' translate="no">
                            {numberFormatQuantity(val.ilosc, 2)}&nbsp;{val.symbol_jmp}
                          </span>
                          <span className='px-1 text-red-500' translate='no'>
                            {numberFormatQuantity(val.actualStock || 0, 2)}&nbsp;{val.symbol_jmp}
                          </span>
                        </>
                      )}
                      </p>
                    </li>
                  )}
                </Ul>
              </div>
            )}
          </>
        </ForwardDialog>
      {/* </Portal> */}
    </>
  );
};

export default ValidProduct;
