import { useState, useRef, useCallback, useEffect } from 'react';
import { Transition } from '@headlessui/react';

import styled from 'styled-components';
import { debounce, capitalizeFirstLetter, parseJSON } from '../util/index';
import useDidMountEffect from '../hook/useDidMountEffect';
import { Trans, useTranslation } from 'react-i18next';
// import { debounce, parseJSON, numberFormatCurrency } from '../util';

const Color = styled.b`
  position:relative;
  // border:1px solid #dedede;
`;

const Li = styled.li`
  :hover {
    // background-color: var(--primary);
    background-color: #eaeef2;
    // color: white;
  }
`;

const InputStyle = styled.input`
  ::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    outline: none;
    z-index: 1;
    opacity:0;
  }

  + label > span {
    position:relative;
    z-index:1;
    left:2px;
  }

  + label span + span {
    position:absolute;
    top:8px;
    left:2px;
    width:100%;
    height:1px;
    background-color:white;
    z-index:0;
  }

  :focus + label span + span {
    height:2px;
    top:7px;
  }
`;

const InputDateWithClear = ({value = '', min = 0, max = 0, label="", onHandleUpdate = (e) => {}, clearFilter = '', clearTitle = ''}) => {
  const [valueCurrent, setValueCurrent] = useState(value);
  const refInput = useRef();

  const clearInput = () => {
    if (refInput.current) {
      refInput.current.value = '';
      setValueCurrent('');
    }
  };

  const onChange = (e) => {
    setValueCurrent(e.target.value);
    onHandleUpdate(e);
  };

  String.prototype['toDateInput'] = function() {
    let dateParsed = '';

    if (this.toString().length <= 10) 
      dateParsed = this.toString().trim().replaceAll('.', '-');
    else
      dateParsed = this.toString().trim().slice(0, 10).replaceAll('.', '-');
    
    // console.log("%ctoDateInput", 'background:#222;color:#bada55', dateParsed, this.toString());
    return dateParsed;
  };

  useDidMountEffect(() => {
    console.log("%cCLEAR DATIMEONE", "color:red;font-weight:bold");
    clearFilter && clearInput();
  }, [clearFilter]);

  return (
    <div className="relative inline-flex items-center flex-grow">
      <InputStyle
        type="date"
        ref={refInput}
        onChange={onChange}
        className="flex-grow pr-8 text-sm text-black border border-gray-300 rounded-sm rounded h-9 w-34"
        max={max.toDateInput()}
        min={min.toDateInput()}
        defaultValue={valueCurrent.toDateInput()}
      />
      <label className="absolute z-0 px-1 text-xs text-gray-500 -top-2 left-2">
        <span>{label}</span>
        <span />
      </label>
      <button
        className={`${(valueCurrent.length == 0) ? 'hidden' : ''} absolute right-1 z-10`}
        type="button"
        onClick={clearInput}
      >
        <svg className="w-6 h-6" viewBox="-220 -250 800 800" fill="currentColor">
          <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25  0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344  113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812  113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
        </svg>
      </button>
      <svg className={`${(valueCurrent.length == 0) ? '' : 'hidden'} absolute w-5 h-5 right-2 text-gray-600 group-focus:text-blue-500`} viewBox="0 0 36.447 36.447" fill="currentColor">
        <g>
          <path d="M30.224,3.948h-1.098V2.75c0-1.517-1.197-2.75-2.67-2.75c-1.474,0-2.67,1.233-2.67,2.75v1.197h-2.74V2.75 c0-1.517-1.197-2.75-2.67-2.75c-1.473,0-2.67,1.233-2.67,2.75v1.197h-2.74V2.75c0-1.517-1.197-2.75-2.67-2.75 c-1.473,0-2.67,1.233-2.67,2.75v1.197H6.224c-2.343,0-4.25,1.907-4.25,4.25v24c0,2.343,1.907,4.25,4.25,4.25h24 c2.344,0,4.25-1.907,4.25-4.25v-24C34.474,5.855,32.567,3.948,30.224,3.948z M25.286,2.75c0-0.689,0.525-1.25,1.17-1.25 c0.646,0,1.17,0.561,1.17,1.25v4.896c0,0.689-0.524,1.25-1.17,1.25c-0.645,0-1.17-0.561-1.17-1.25V2.75z M17.206,2.75 c0-0.689,0.525-1.25,1.17-1.25s1.17,0.561,1.17,1.25v4.896c0,0.689-0.525,1.25-1.17,1.25s-1.17-0.561-1.17-1.25V2.75z M9.125,2.75 c0-0.689,0.525-1.25,1.17-1.25s1.17,0.561,1.17,1.25v4.896c0,0.689-0.525,1.25-1.17,1.25s-1.17-0.561-1.17-1.25V2.75z M31.974,32.198c0,0.965-0.785,1.75-1.75,1.75h-24c-0.965,0-1.75-0.785-1.75-1.75v-22h27.5V32.198z" />
          <rect x="6.724" y="14.626" width="4.595" height="4.089" />
          <rect x="12.857" y="14.626" width="4.596" height="4.089" />
          <rect x="18.995" y="14.626" width="4.595" height="4.089" />
          <rect x="25.128" y="14.626" width="4.596" height="4.089" />
          <rect x="6.724" y="20.084" width="4.595" height="4.086" />
          <rect x="12.857" y="20.084" width="4.596" height="4.086" />
          <rect x="18.995" y="20.084" width="4.595" height="4.086" />
          <rect x="25.128" y="20.084" width="4.596" height="4.086" />
          <rect x="6.724" y="25.54" width="4.595" height="4.086" />
          <rect x="12.857" y="25.54" width="4.596" height="4.086" />
          <rect x="18.995" y="25.54" width="4.595" height="4.086" />
          <rect x="25.128" y="25.54" width="4.596" height="4.086" />
        </g>
      </svg>
    </div>
  );
};

const InputDateFromTo = ({value = [null, null], min = 0, max = 0, onHandleUpdate = (e) => {}, clearFilter = '', clearTitle = ''}) => {
  const { t } = useTranslation("shop");
  
  const [minTo, setMinTo] = useState(min.indexOf('1900-01-01') != 1 ? min : '');
  const [maxFrom, setMaxFrom] = useState(max.indexOf('1900-01-01') != 1 ? max : '');
  const [clearFilterAll, setClearFilterAll] = useState('');

  const onHandleUpdateFrom = (e) => {
    setMinTo(e.target.value);
    onHandleUpdate(e);
  }

  const onHandleUpdateTo = (e) => {
    setMaxFrom(e.target.value);
    onHandleUpdate(e);
  }

  useEffect(() => {
    if (clearFilter.indexOf(clearTitle) != -1) {
      setMaxFrom(max.indexOf('1900-01-01') != 1 ? max : '');
      setMinTo(min.indexOf('1900-01-01') != 1 ? min : '');
      setClearFilterAll("" + (Math.random()*100));
    }
  }, [clearFilter]);

  return (
    <>
      <InputDateWithClear label={t('od')} value={value[0] || ''} min={minTo} max={maxFrom} onHandleUpdate={onHandleUpdateFrom} clearFilter={clearFilterAll} />
      <span className="px-1">-</span>
      <InputDateWithClear label={t('do')} value={value[1] || ''} min={minTo} max={maxFrom} onHandleUpdate={onHandleUpdateTo} clearFilter={clearFilterAll}  />
    </>
  );
};

const InputNumberWithClear = ({value = [null], min = 0, max = 0, prec = 0, label = "", onHandleUpdate = (e) => {}, clearFilter = '', clearTitle = ''}) => {
  const [valueCurrent, setValueCurrent] = useState(value[0] || '');
  const refInput = useRef();

  const clearInput = () => {
    if (refInput.current)
      setValueCurrent(refInput.current.value = '');
  };

  const onChange = (e) => {
    setValueCurrent(e.target.value);
    onHandleUpdate(e);
  };

  Number.prototype.toStepInput = () => {
    if (this <= 0) return "1";
    return "0." + "0".repeat(this- 1) + "1";
  };

  useEffect(() => {
    clearFilter.indexOf(clearTitle) != -1 && clearInput();
  }, [clearFilter]);

  return (
    <div className="relative inline-flex items-center">
      <InputStyle
        type="number"
        ref={refInput}
        onChange={onChange}
        className="flex-grow w-24 text-sm text-black border border-gray-300 rounded h-9 pr-7"
        maxLength={max}
        minLength={min}
        step={prec.toStepInput()}
        placeholder={prec.toStepInput()}
        defaultValue={valueCurrent}
      />
      <label className="absolute z-0 px-1 text-xs text-gray-500 -top-2 left-2">
        <span>{label}</span>
        <span />
      </label>
      <button
        className={`${(valueCurrent.length == 0) ? 'hidden' : ''} absolute right-1`}
        type="button"
        onClick={clearInput}
      >
        <svg className="w-6 h-6" viewBox="-220 -250 800 800" fill="currentColor">
          <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25  0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344  113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812  113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
        </svg>
      </button>
    </div>
  );
};

const InputNumberFromTo =  ({value = [null, null], min = 0, max = 0, prec = 0, onHandleUpdate = (e) => {}, clearFilter = '', clearTitle = ''}) => {
  const { t } = useTranslation("shop");

  const [valueFrom, setValueFrom] = useState(value[0] || '');
  const [valueTo, setValueTo] = useState(value[1] || '');
  const refInputFrom = useRef();
  const refInputTo = useRef();
  const [color, setColor] = useState('gray');

  const clearInputFrom = () => {
    if (refInputFrom.current) {
      setValueFrom(refInputFrom.current.value = '');
    }
  };

  const clearInputTo = () => {
    if (refInputTo.current) {
      setValueTo(refInputTo.current.value = '');
      setColor('gray');
    }
  };

  const debounceFunc = useCallback(debounce((_func, _event) => {
    _func(_event);
  }, 500), []);

  const onChangeFrom = (e) => {
    debounceFunc((_e) => {
      setValueFrom(e.target.value);
      if(SetStyleBorder(e.target.value, valueTo))
        onHandleUpdate(e);
    }, e);
  };

  const onChangeTo = (e) => {
    debounceFunc((_e) => {
      setValueTo(e.target.value);
      if(SetStyleBorder(valueFrom, e.target.value))
        onHandleUpdate(e);
    }, e);
  };

  const SetStyleBorder = (vFrom, vTo) => {
    if( parseInt(vFrom) > parseInt(vTo)) {
        setColor('red');
        return false;
    } else {
        setColor('gray');
    }
    return true;
  }

  Number.prototype.toStepInput = () => {
    if (this <= 0) return "1";
    return "0." + "0".repeat(this- 1) + "1";
  };

  useEffect(() => {
    if (clearFilter.indexOf(clearTitle) != -1) {
      clearInputFrom();
      clearInputTo();
    }
  }, [clearFilter]);

  return (
    <>
      <div className="relative inline-flex items-center">
      <InputStyle
        type="number"
        ref={refInputFrom}
        onChange={onChangeFrom}
        className="flex-grow w-24 text-sm text-black border border-gray-400 rounded h-9 pr-7"
        max={max}
        min={min}
        step={prec.toStepInput()}
        placeholder={prec.toStepInput()}
        defaultValue={valueFrom}
      />
      <label className="absolute z-0 px-1 text-xs text-gray-500 -top-2 left-2">
        <span>{t('od')}</span>
        <span />
      </label>
      <button
        className={`${(valueFrom.length == 0) ? 'hidden' : ''} absolute right-1`}
        type="button"
        onClick={clearInputFrom}
      >
        <svg className="w-6 h-6" viewBox="-220 -250 800 800" fill="currentColor">
          <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25  0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344  113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812  113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
        </svg>
      </button>
    </div>
    <span className="px-1">-</span>
    <div className="relative inline-flex items-center">
      <InputStyle
        type="number"
        ref={refInputTo}
        onChange={onChangeTo}
        className={`flex-grow w-24 text-sm text-black border border-${color}-400 rounded h-9 pr-7`}
        max={max}
        min={min}
        step={prec.toStepInput()}
        placeholder={prec.toStepInput()}
        defaultValue={valueTo}
      />
      <label className={`absolute z-0 px-1 text-xs text-${color}-500 -top-2 left-2`} >
        <span>{t('do')}</span>
        <span />
      </label>
      <button
        className={`${(valueTo.length == 0) ? 'hidden' : ''} absolute right-1`}
        type="button"
        onClick={clearInputTo}
      >
        <svg className="w-6 h-6" viewBox="-220 -250 800 800" fill="currentColor">
          <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25  0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344  113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812  113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
        </svg>
      </button>
    </div>

    </>
  )

};

const InputTextWithClear = ({value = [null], length = 0, onHandleUpdate = (e) => {}, clearFilter = '', clearTitle = ''}) => {
  const { t } = useTranslation("shop");
  const [valueCurrent, setValueCurrent] = useState(value[0] || '');
  const refInput = useRef();

  const clearInput = () => {
    if (refInput.current)
      setValueCurrent(refInput.current.value = '');
  };

  const debounceFunc = useCallback(debounce((_func, _event) => {
    _func(_event);
  }, 500), []);

  const onChange = (e) => {
    debounceFunc((_e) => {
      setValueCurrent(_e.target.value);
      onHandleUpdate(_e);
    }, e);
  };

  useEffect(() => {
    clearFilter.indexOf(clearTitle) != -1 && clearInput();
  }, [clearFilter]);

  return (
    <div className="relative inline-flex items-center">
      <input
        type="text"
        ref={refInput}
        onChange={onChange}
        className="flex-grow text-sm text-black border border-gray-300 rounded h-9 w-42 pr-7"
        maxLength={length}
        placeholder={t('maks: 10 znaków', { count: length })}
        defaultValue={valueCurrent}
      />
      <button
        className={`${(valueCurrent.length == 0) ? 'hidden' : ''} absolute right-1`}
        type="button"
        onClick={clearInput}
      >
        <svg className="w-6 h-6" viewBox="-220 -250 800 800" fill="currentColor">
          <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25  0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344  113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812  113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
        </svg>
      </button>
    </div>
  );
};

const FilterBlock = ({
  id, name = '#',
  data = [], //[{ id: 0, wartosc: '' }],
  select = [0],
  type = 'select',
  filter = ({ min: 0, max: 0, len: 0, prec: 0}),
  handleUpdate = (col, val) => {},
  expand = false,
  clearFilter, // new JSON string array
}) => {
  const [isOpen, setIsOpen] = useState(expand);
  const [itemList, setItemList] = useState([]);
  const refButton = useRef();
  const refGroup = useRef();

  useEffect(() => {
    setIsOpen(expand);
  }, [expand]);

  const onShowList = () => {
    // if (isOpen === false)
    setIsOpen(!isOpen);
  };

  const onFilterSelect = (e, val, force = false) => {
    if (e.target.tagName == "INPUT" && e.target.type != "checkbox" && force == false) return;

    const itemTmp = [...itemList];
    let value = [val.id, undefined];

    if (val.id == 0) {
      const target = refGroup.current.querySelectorAll("input:not([type='checkbox'])");
      const targetCheckbox = refGroup.current.querySelector('input[type="checkbox"]');
      value[0] = target[0]?.value;
      value[1] = target[1]?.value;

      if (
        (
          (value[0] == null || value[0].length == 0 || value[0] == undefined) &&
          (value[1] == null || value[1].length == 0 || value[1] == undefined)
        )
        && targetCheckbox.checked != true && e.target.type != "checkbox"
      ) {
        if (e.target.type == "checkbox")
          targetCheckbox.click();
        
        return;
      }

      if (e.target.type == "checkbox") {
        value[0] = '';
        value[1] = '';
      } else {
        if (target[0]?.type == "date") {
          value[0] = new Date(value[0]).toJSON();
          value[1] = new Date(value[1]).toJSON();
        }
      }
    }

    const index = itemTmp.findIndex(el => (el === value[0]) || val.id == 0);

    if (index !== -1) {
      itemTmp.splice(index, 2);
      val.id == 0 && !value.every(v => v == '' || v == undefined || v == null) && itemTmp.push(...value);
    } else {
      itemTmp.push(...value);
    }

    setItemList([...itemTmp]);
    console.log('FilterBlock', type, index, val, itemTmp, itemList);

    switch (type) {
      case 'radio':
        handleUpdate({ _type: 'bool', [id]: !!itemTmp[0] || undefined });
        break;
      case 'number':
        handleUpdate({ _type: 'number', [id]: (!itemTmp[0] && !itemTmp[1]) ? undefined : { od: itemTmp[0] || '', do: (itemTmp[1] || '') } });
        break;
      case 'date':
        handleUpdate({ _type: 'date', [id]: (!itemTmp[0] && !itemTmp[1]) ? undefined : { od: itemTmp[0] || '', do: (itemTmp[1] || '') } });
        break;
      case 'input':
        handleUpdate({ _type: 'array', [id]: itemTmp[0] || undefined });
        break;
      case 'string': default: {
        const itemFilter = itemTmp.filter(item => item !== undefined && item !== '');
        handleUpdate({ _type: 'array', [id]: itemFilter.length == 0 ? undefined : itemFilter });
      }
    }
  };

  const onSetCheckbox = useCallback( // for non multi
    debounce((e) => {
      const targetCheckbox = refGroup.current.querySelector('input[type="checkbox"]');
      const target = refGroup.current.querySelectorAll('input:not([type="checkbox"])');
      const value = [target[0]?.value, target[1]?.value];

      // console.log("DEBOUNCE", e.target);
      // console.log("SET CHECKED", targetCheckbox.checked, value);

      if (
        (value[0] == null || value[0] == '' || value[0] == undefined || value[0].length === 0) &&
        (value[1] == null || value[1] == '' || value[1] == undefined || value[1].length === 0)
      ) {
        if (targetCheckbox.checked == true) {
          //  setTimeout(() => targetCheckbox.click(), 0);
          // else
          // 
          // targetCheckbox.removeAttribute('checked');
          targetCheckbox.click();
          onFilterSelect(e, {id: 0, wartosc: ''}, true);
        }
      } else {
        if (targetCheckbox.checked == false)
          targetCheckbox.checked = true;

        onFilterSelect(e, {id: 0, wartosc: ''}, true);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (itemList.length == 0) return;
    const clear = parseJSON(clearFilter);

    console.log('FilterBlock [clear]', clear);

    if (clear === '-1' || clear === false) {
      setItemList([]);
      return;
    }

    if (clear == undefined) return;

    const values = [...clear];
    let isNew = false;

    console.log('FilterBlock [split]', values);

    values.length > 0 && values.forEach((valArr) => {
      if (valArr == id) isNew = true;
      // console.log('FilterBlock [clearfilter]', id, valArr);
    });

    if (isNew) {
      // console.log('FilterBlock [clear]');
      setItemList([]);
    }
  }, [clearFilter]);

  if (!Array.isArray(data) || data.length <= 0) {
    return "";
  }

  return (
    <div className="relative w-full">
      <button
        onClick={onShowList}
        ref={refButton}
        type="button"
        aria-haspopup="listbox"
        aria-labelledby="listbox-label"
        className="relative w-full text-left  border-b border-gray-300 border-dashed sm:text-sm min-h-10 focus:outline-none"
      >
        <div className="flex">
          <span className="inset-y-0 left-0 flex items-center pr-1 z-1">
            <svg className={`${isOpen ? 'hidden' : ''} h-5 w-5 text-gray-400`} xmlns="http://www.w3.org/2000/svg" viewBox="1 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="m6.293,7.90276a1,1 0 0 1 1.414,0l2.293,2.293l2.293,-2.293a1,1 0 0 1 1.414,1.414l-3,3a1,1 0 0 1 -1.414,0l-3,-3a1,1 0 0 1 0,-1.414z" />
            </svg>
            <svg className={`${isOpen ? '' : 'hidden'} h-5 w-5 text-gray-400`} xmlns="http://www.w3.org/2000/svg" viewBox="-19 -20 20 20" fill="currentColor" aria-hidden="true">
              <path transform="rotate(180)" d="m6.293,7.90276a1,1 0 0 1 1.414,0l2.293,2.293l2.293,-2.293a1,1 0 0 1 1.414,1.414l-3,3a1,1 0 0 1 -1.414,0l-3,-3a1,1 0 0 1 0,-1.414z" />
            </svg>
          </span>
          <span className="my-2 font-medium">{capitalizeFirstLetter(name)}</span>
        </div>
      </button>
      <Transition
        show={isOpen}
        enter=""
        enterFrom=""
        enterTo=""
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {(ref) => (
          <div
            ref={ref}
            className={`relative right-0 w-full z-30 mt-px`}
          >
            <ul
              tabIndex="-1"
              role="listbox"
              aria-labelledby="state-cart-label"
              aria-activedescendant="state-cart-0"
              className="py-1 overflow-auto text-sm rounded-md max-h-56 focus:outline-none sm:text-sm"
            >
              {data.map(val => (
                <Li
                  key={`filter_choose_${val.id}`}
                  role="option"
                  aria-selected="false"
                  className="relative text-gray-700 cursor-default select-none"
                >
                  <div
                    onClick={(e) => onFilterSelect(e, val)}
                    className="w-full px-3 py-2 outline-none focus:outline-none"
                  >
                    <div className="flex items-center content-center" ref={refGroup}>
                      <input
                        type="checkbox"
                        className="mr-2 border border-gray-400 rounded-sm rounded"
                        checked={itemList.findIndex(itemId => {
                          console.log("CHECKBOX", itemId, val.id);
                          return itemId == val.id || (val.id == 0 && itemId != null);
                        }) !== -1}
                        onChange={() => {}}
                        disabled={val.id == 0 && itemList.length == 0}
                        data-key={val.id}
                      />
                      {type === 'radio' && (
                        <span className="block -mt-px text-sm font-normal truncate" title={'tak'}>
                          Tak
                        </span>
                      )}
                      {type === 'color' && (
                        <Color
                          className="flex-shrink-0 w-4 h-4 mr-2 border border-gray-400 rounded-full"
                          style={{ background: val.color }}
                        />
                      )}
                      {type === 'date' && (
                        <InputDateFromTo value={itemList} min={filter.min} max={filter.max} onHandleUpdate={onSetCheckbox} clearFilter={clearFilter} clearTitle={'"'+filter.kolumna+'"'} />
                      )}
                      {type === 'number' && (
                        <InputNumberFromTo value={itemList} min={filter.min} max={filter.max} prec={filter.prec} onHandleUpdate={onSetCheckbox} clearFilter={clearFilter} clearTitle={'"'+filter.kolumna+'"'} />
                      )}
                      {type === 'input' && (
                        <InputTextWithClear value={itemList} length={filter.len} onHandleUpdate={onSetCheckbox} clearFilter={clearFilter} clearTitle={'"'+filter.kolumna+'"'} />
                      )}
                      {val.wartosc.length > 0 && (
                        <span className="block -mt-px text-sm font-normal truncate" title={val.wartosc}>
                          {capitalizeFirstLetter(val.wartosc.replace(/(.{26})..+/, "$1…"))}
                        </span>
                      )}
                    </div>
                  </div>
                </Li>
              ))}
            </ul>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default FilterBlock;
