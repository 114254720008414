

const SCheckBox = ({ checkAll = false }) => {
  
  const toggleCheckedAll = (e) => {
    const { checked } = e.target;
    const { tabIndex } = e.target.closest('th');
    const inputList = e.target.closest('table').querySelectorAll(`tr td[tabIndex='${tabIndex}'] input[type='checkbox']`);

    inputList.length > 0 && inputList.forEach((input) => {
      input.checked = checked;
    });
  };

  const toggleChecked = (e) => {
    if (checkAll) toggleCheckedAll(e);
  };

  return (
    <>
      <input type="checkbox" className="w-5 h-5 form-checkbox text-primary" onChange={toggleChecked} />
      {/*
      <span className="ml-2 text-gray-700">label</span>
      */}
    </>
  );
};

export default SCheckBox;
