import { useState } from 'react';
import PropSelect from './index';
import useCartAction from "../hook/cartaction";
import { SpinnerAbsolute } from '../spinner';

const StarRequired = () => (
  <sup className="relative inline-block ml-1">
    <svg className="absolute w-1 h-1 text-red-600 -top-1 -left-1" viewBox="0 0 478.53 478.53" style={{ enableBackground: 'new 0 0 478.53 478.53' }} fill="currentColor">
      <path d="M477.795,184.279c-1.765-5.431-6.458-9.389-12.108-10.209l-147.159-21.384l-65.812-133.35
        c-2.527-5.12-7.741-8.361-13.451-8.361s-10.924,3.241-13.451,8.361l-65.812,133.35L12.843,174.07
        c-5.65,0.82-10.344,4.778-12.108,10.209c-1.765,5.43-0.293,11.391,3.796,15.376l106.484,103.798L85.877,450.018
        c-0.965,5.627,1.349,11.314,5.968,14.671c4.618,3.354,10.741,3.799,15.797,1.142l131.623-69.199l131.623,69.199
        c2.195,1.153,4.592,1.723,6.979,1.723c3.11,0,6.205-0.966,8.818-2.864c4.619-3.356,6.933-9.044,5.968-14.671l-25.138-146.565
        l106.484-103.798C478.088,195.669,479.56,189.708,477.795,184.279z"
      />
    </svg>
  </sup>
);

const findPropertyList = (property = { definition: [], selection: []}) => {
  if (property.definition.length == 0) return [];

  const propertyMapped = property.definition.map(prop => {
    const find = property.selection.find(el => el.field == prop.pole);

    if (prop.typ == "L")
      return ({ field: prop.opis, value: find && find.value? "tak" : "nie", required: prop.wymagane });

    if (prop.typ == "N")
      return ({ field: prop.opis, value: isNaN(find && find.value) || (find && find.value) == undefined ? "---" :  find.value, required: prop.wymagane });

    if (prop.typ == "O" && prop.ismulti == false) {
      return ({ field: prop.opis, value: (find && prop.values.find(val => find.value == val.id)?.wartosc) || "---", required: prop.wymagane });
    }

    if (prop.typ == "O" && prop.ismulti == true) {
      return ({ field: prop.opis, value: (find && prop.values.filter(val => find.value.split(",").some(fi => fi == val.id)).map(el => el.wartosc).sort().join(",")) || "---", required: prop.wymagane });
    }

    return ({ field: prop.opis, value: find && find.value || "---", required: prop.wymagane });
  });

  return propertyMapped;
};

const PropExpand = ({ item, property = {definition: [], selection: [] }}) => {
  if (property.definition.length == 0) return '';

  const [displayEdit, setDisplayEdit] = useState(false);
  const [changedEdit, setChangedEdit] = useState(false);
  const [propSave, setPropSave] = useState({});
  const [stateCart, cartAction] = useCartAction();

  const onChanged = (prop) => {
    changedEdit == false && setChangedEdit(true);
    setPropSave({ ...propSave, ...prop });
  };

  const onSave = async (e) => {
    const isSuccess = await cartAction.setProperty(item, propSave, +item.id, true /*reload*/);
    // console.log("Prop ", propSave, property.selection, isSuccess);

    if (!isSuccess) return;

    const propList = {};
    property.selection.forEach((val) => propList[val.field] = val.value);
    property.selection = [];

    for (const [field, value] of Object.entries({ ...propList, ...propSave })) {
      property.selection.push({ field, value });
    }

    setChangedEdit(false);
    setDisplayEdit(false);
  };

  return (
    <>
      <button
        type="button"
        className="flex justify-center w-full group focus:outline-none hover:text-primary hover:cursor-pointer md:justify-start"
        onClick={() => setDisplayEdit(!displayEdit)}
      >
        <ul className="flex flex-wrap items-center gap-x-2">
          <li>
            <svg className="relative w-5 h-5 -ml-1 top-px" viewBox="0 0 24 24" fill="none" style={{ left: "-2px", marginRight: "-8px"}}>
              <g transform={`rotate(${!displayEdit ? "0" : "90,12,12"})`}>
                <path d="M17 12L9 6V18L17 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </g>
            </svg>
          </li>
          {property.definition.length > 0 && findPropertyList(property).map((el, index) => (
            <li className={`${!displayEdit ? '' : 'hidden'}`} key={`propdisplay_${el.field}_${index}`}>
                {el.required && (<StarRequired />)}
                <span className={`${el.required ? 'text-red-600' : ''} lowercase group-hover:text-primary`}>{el.field}:&nbsp;</span>
                <span className="font-semibold">{""+el.value}</span>
            </li>
          ))}
          <li className={`${displayEdit ? '' : 'hidden'}`}>
            <span className="text-gray-500 group-hover:text-primary">[ukryj edycję właściwości]</span>
          </li>
        </ul>
      </button>
      <div className={`${displayEdit ? "flex" : "hidden"} flex-row relative w-full mb-1 mt-1 focus:border-primary`}>
        {stateCart.loading && <SpinnerAbsolute />}
        <div className="flex flex-col flex-wrap flex-grow">
          <fieldset className={` relative rounded bg-white border ${stateCart.error ? "border-red-500" : "border-gray-300"} flex flex-row flex-wrap items-center ${stateCart.loading ? "rainbow-box" : ""}`}>
            <div className="p-2">
              <PropSelect item={item} property={property.definition} selected={property.selection} onChanged={onChanged} />
            </div>
          </fieldset>
        </div>
        <div className="absolute right-0 flex flex-row m-px -top-3">
          {/* <button
            type="button"
            className="p-1 mr-1 text-white bg-white border border-b-0 border-l-0 border-r-0 border-gray-300 rounded-full"
            title="zamknij edycję cech"
            onClick={() => setDisplayEdit(false)}
          >
            <svg className="w-4 h-4 bg-red-500 rounded-full" viewBox="-220 -220 800 800" fill="currentColor">
              <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0">
              </path>
            </svg>
          </button> */}
          <button
            type="button"
            className={`${changedEdit ? '' : 'hidden'} text-white p-1 bg-white rounded-full border border-b-0 border-l-0 border-r-0 border-gray-300`}
            title="zapisz zmiany cech"
            onClick={onSave}
          >
            <svg className="w-4 h-4 bg-green-500 rounded-full" viewBox="-180 -220 750 750" fill="currentColor">
              <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0"></path>
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default PropExpand;
