import { useRef, useContext, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CartContext from '../context/cart.ts';
import { debounce, toFixedCut, toValidNumber } from '../util/index';
import PopWarning from '../PopWarning';

import './style.css';

const InputCounter = ({
  value = 1,
  defValue = 0,
  max = 0,
  maxActual = 0,
  disable = false,
  unavailable = false,
  resetValue = true,
  autoConfirm = false,
  notifyConfirm = false,
  incValue = true,
  onSave = (save) => {}
}) => {
  const [quantity, setQuantity, _loading, error] = useContext(CartContext);

  const { t } = useTranslation('cart');

  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState(0);
  // const [prevValue, setPrevValue] = useState(value);
  const [refDiv, setRefDiv] = useState(null);
  const [showPopup, setShowPopup] = useState(-1);

  const refQuantity = useRef();
  const maxLimit = 1000000;

  // console.log('QUANTITYINPUT', quantity, _loading, error);

  if (typeof max === 'boolean') {
    max = max === true ? -1 : 0;
    // unavailable = max === true;
  } else if (max < 0) {
    max = max == -1 ? -1 : 0;
    // unavailable = max != -1;
  }

  if (value < 0) value = 0;

  useEffect(() => {
    if (defValue <= 0) return;
    quantity.input = defValue;
  }, []);

  useEffect(() => {
    if (refQuantity) {
      // refQuantity.current.attributes["type"].value = "text";
      // console.log("INPUT number", quantity.input, refQuantity.current.value);
      // const caretPos = refQuantity.current.selectionStart; // - refQuantity.current.selectionEnd;
      refQuantity.current.value = toFixedCut(parseFloat(quantity.input), quantity.precision);
      refQuantity.current.setSelectionRange(position, position);
      // refQuantity.current.attributes["type"].value = "number";
      showPopWarning();
    }

    if (!autoConfirm)
      setLoading(false);
  }, [quantity]);

  useEffect(() => {
    if (refQuantity.current == undefined) return;

    const maxConfirm = (autoConfirm || notifyConfirm) ? maxActual : max;

    if (maxConfirm > 0 && +quantity.input > maxConfirm) {
      quantity.input = parseFloat(maxConfirm);
      refQuantity.current.value = quantity.input;
    }

    if (+quantity.input > maxLimit) {
      quantity.input = parseFloat(maxLimit);
      refQuantity.current.value = quantity.input;
    }
  }, [maxActual]);

  useEffect(() => {
    // console.log("Loading context", _loading);

    _loading == false && setLoading(false);
  }, [_loading]);

  // only debounceConfirm
  const updateConfirm = (_valueConfirm, _force = false) => {
    if (_force && !disable) {
      // force
    } else if (!autoConfirm || disable) {
      setQuantity({ ...quantity });
      setLoading(false);
      return;
    }

    // autoConfirm
    if ((autoConfirm || notifyConfirm) && max !== -1 && (+_valueConfirm) >= maxActual) {
      quantity.confirm = parseFloat(maxActual);
      setLoading(false);
    } else {
      quantity.confirm = parseFloat(+_valueConfirm);
    }

    if (quantity.confirm > maxLimit)
      quantity.confirm = maxLimit;

    if (autoConfirm || notifyConfirm)
      quantity.input = quantity.confirm;
    else
      quantity.input = 1;

    setQuantity({ ...quantity });
    // setLoading(false);
  };

  const debounceConfirm = useCallback(debounce((_value, _force) => {
    updateConfirm(_value, !!_force);
  }, autoConfirm ? 1500 : 50), [quantity]);

  const debounceKey = useCallback(debounce((_func, _event) => {
    _func(_event);
  }, 0), []);

  const plus = () => {
    loading != true && setLoading(true);
    let maxInput = parseInt(value, 10);

    if (max !== -1 && maxActual <= +quantity.input) {
      if (max != 0)
        maxInput = parseInt(maxActual, 10);
    } else
      maxInput = parseInt(+quantity.input + value, 10);

    if (maxInput > maxLimit)
      maxInput = maxLimit;

    return maxInput;
  };

  const minus = () => {
    loading != true && setLoading(true);
    return parseInt(+quantity.input > value ? +quantity.input - value : value, 10);
  };

  const handleKeyUp = (e) => {
    // if (loading == true) return;
    // loading != true && setLoading(true);
    // e.target.value = toValidNumber(e.target.value);

    debounceKey((e) => {
      // if (max === 0) return;
      let targetValue = toValidNumber(e.target.value);
      if (targetValue === '') return;

      const keyCode = (e.keyCode ? e.keyCode : e.which);

      // console.log("KEYUP", keyCode, targetValue);

      if (e.ctrlKey && keyCode == 65) { // CTRL + A
        e.target.select();
        return;
      }

      if (keyCode == 17) { // CTRL up
        return;
      }

      if (keyCode === 13) { // enter
        return;
      }

      setPosition(e.target.selectionStart);

      if (keyCode === 37 || keyCode === 39) { // arrow left right
        return;
      }

      loading != true && setLoading(true);

      const maxConfirm = (autoConfirm || notifyConfirm) ? maxActual : max;
      if (max !== -1 && maxConfirm <= +targetValue)
        targetValue = parseFloat(maxConfirm);

      let valueMod = +value;
      if (quantity.precision > 0)
        valueMod = Number(0).toFixed(quantity.precision).replace(/.$/, "1");

      if (!(+targetValue >= valueMod))
        targetValue = valueMod;

      const countDecimals = (function (_value) {
        if (Math.floor(_value) !== _value) {
          return _value.toString().split('.')[1]?.length || 0;
        }

        return 0;
      }(targetValue));

      if (countDecimals > quantity.precision)
        quantity.input = toFixedCut(parseFloat(targetValue), quantity.precision);
      else
        quantity.input = toFixedCut(parseFloat(targetValue), countDecimals);

      if (quantity.input > maxLimit) {
        quantity.input = targetValue = maxLimit;
      }

      e.target.value = targetValue;

      // setQuantity({ ...quantity }); // !
      debounceConfirm(+quantity.input);
    }, e);
  };

  const revertValue = () => {
    if (quantity.input == quantity.confirm) {
      return;
    }

    loading != true && setLoading(true);
    quantity.input = parseFloat(+quantity.confirm);
    setQuantity({ ...quantity });
    // debounceConfirm(+quantity.input, true); // commented
  };

  const confirmValue = () => {
    if (quantity.input == quantity.confirm) {
      return;
    }

    loading != true && setLoading(true);
    quantity.confirm = parseFloat(+quantity.input);
    // setQuantity({ ...quantity }); // !
    debounceConfirm(+quantity.confirm, true);
  };

  const handleKeyDown = (e) => {
    // if (loading == true) return;
    // loading != true && setLoading(true);

    debounceKey((e) => {
      // if (max === 0) return;
      const targetValue = toValidNumber(e.target.value);
      const keyCode = (e.keyCode ? e.keyCode : e.which);

      if (keyCode != 37 && keyCode != 39 && keyCode != 8 && keyCode != 46 && // backspace, delete, left, right
        (keyCode < 96 || keyCode > 105) && // numpad 0-9
        (Number.isNaN(String.fromCharCode(e.which)) && keyCode != 110 && keyCode != 188 && keyCode != 190) // numpad decimal, comma, period (dot)
      ) {
        // console.log('PREVENT INPUT', e.key, e.code, String.fromCharCode(e.which), isNaN(String.fromCharCode(e.which)))
        e.preventDefault();
        return;
      }

      // console.log('KEYDOWN', keyCode, String.fromCharCode(keyCode), targetValue);
      // setPrevValue(targetValue);

      if (keyCode === 37 || keyCode === 39) { // <-  ->
        return;
      }

      if (keyCode === 107) { //e.key === '+') {
        e.preventDefault();
        loading != true && setLoading(true);
        quantity.input = plus();
        // setQuantity({ ...quantity }); // !
        debounceConfirm(+quantity.input);
        return;
      }

      if (keyCode === 109) { //e.key === '-') {
        e.preventDefault();
        loading != true && setLoading(true);
        quantity.input = minus();
        // setQuantity({ ...quantity }); // !
        debounceConfirm(+quantity.input);
        return;
      }

      if (e.key === 'Enter' && notifyConfirm) {
        confirmValue();
      }

      if (e.key === 'Escape' && notifyConfirm) {
        revertValue();
      }

      if (e.key === 'Enter' && !notifyConfirm) {
        let confirm = +quantity.confirm;

        if (max !== -1 && (+targetValue) >= max) // maxActual = max quantity in input for jm
          confirm = parseFloat(maxActual);
        else
          confirm = parseFloat(+quantity.input) + parseFloat(quantity.confirm);

        if (confirm > maxLimit)
          confirm = maxLimit;

        loading != true && setLoading(true);
        if (resetValue) quantity.input = value;
        // setQuantity({ ...quantity }); // !
        debounceConfirm(+confirm, true);
      }

      if (e.ctrlKey && e.key === 'Delete') {
        if (quantity.confirm === 0) return;

        loading != true && setLoading(true);
        if (resetValue) quantity.input = value;
        // setQuantity({ ...quantity }); // !
        debounceConfirm(0, true);
      }
    }, e);
  };

  const changeValue = (val) => { // plus, minus
    if (quantity.input == val) {
      setLoading(false);
      return;
    }

    loading != true && setLoading(true);
    quantity.input = parseFloat(val);
    console.log('CHANGE VALUE', quantity, val);
    // setQuantity({ ...quantity }); // !
    debounceConfirm(+quantity.input);
  };

  const handleBlur = (e) => {
    const validate = /^\d+(?:\.\d{1,2})?$/.test(e.target.value);
    if (!validate) {
      e.target.value = toFixedCut(parseFloat(quantity.input), quantity.precision);
    }

    // e.target.style.borderColor=/^\d+(?:\.\d{1,2})?$/.test(e.target.value)? 'inherit' : 'red' ;
  };

  const handleFocus = (e) => {
    // console.log("INPUT FOCUS", document.activeElement, e.target);
    e.target.select();
    // e.target.setSelectionRange(0, e.target.value.length);
  };

  const showPopWarning = () => {
    // const isShow = (max == quantity.input || max == quantity.confirm) && max > 0 && disable == false;
    const isShow = (((autoConfirm || notifyConfirm) && maxActual <= quantity.input) || 
      ((!autoConfirm || !notifyConfirm) && max <= quantity.input)) 
      && max > 0 && disable == false;

    if (isShow == true && showPopup == 0) {
      setShowPopup(Math.random() * 100);
      setTimeout(() => setShowPopup(0), 2500);
    }

    if (showPopup == -1) setShowPopup(0);
  };

  return (
    <div
      className="flex flex-wrap items-center"
      data-testid="InputCounter"
      // data-tooltip="podaj wartość w wymaganym formacie"
    >
      <div className="relative flex flex-row custom-number-input h-9 w-30" ref={setRefDiv}>
        <div className={`flex flex-row h-9 w-full rounded-lg relative border border-gray-300
          ${disable ? "bg-gray-100": "bg-white"} text-gray-300 ${error === quantity.hash ? 'border-red-500' : loading ? 'rainbow-box' :  ''}`}>
          {/*
          <div className={`${loading ? 'block' : 'hidden' } absolute -top-0 right-9 h-8 bg-white`}>
            <img className="relative w-14 h-14" src="/assets/loading.gif" style={{ top: "-11px", left: "7px" }} />
          </div>
          */}
          <div className="w-10 p-1 rounded-l-lg" data-testid="InputCounter-sub">
            <button
              onClick={() => changeValue(minus())}
              type="button"
              disabled={disable || value >= quantity.input}
              className={`bg-gray-100 h-full w-8 rounded cursor-pointer 
                          focus:outline-none ${disable || value >= quantity.input ? 'cursor-not-allowed text-gray-300' : 'hover:bg-gray-300 text-gray-500 hover:text-gray-700 '}`}
            >
              <span className="m-auto text-2xl font-light leading-3 outline-none">−</span>
            </button>
          </div>
          <input
            type="tel"
            title=""
            data-test={"" + quantity.input + " " + quantity.precision}
            ref={refQuantity}
            defaultValue={toFixedCut(parseFloat(quantity.input), quantity.precision)}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            step={`${quantity.precision > 0 ? "0." + "1".padStart(quantity.precision, "0") : "1"}`}
            pattern={`${quantity.precision > 0 ? "^\d*(\.\d{0,"+ quantity.precision + "})?$" : "^\d$"}`}
            onBlur={handleBlur}
            onFocus={handleFocus}
            validate="false"
            disabled={disable}
            className={`outline-none focus:outline-none px-0 border-0 text-center w-14 font-semibold text-sm   
                        focus:text-black md:text-basecursor-default flex items-center focus:border-0 focus:shadow-none ring-opacity-0 
                        ring-0 focus:ring-2 ${disable ? 'bg-gray-100 cursor-not-allowed text-gray-300' : 'text-gray-600 bg-white hover:text-black'}`}
          />
          <div className="w-10 p-1 rounded-r-lg" data-testid="InputCounter-add">
            <button
              onClick={() => changeValue(plus())}
              type="button"
              disabled={disable || ((!autoConfirm || !notifyConfirm) && max <= quantity.input && max != -1) || ((autoConfirm || notifyConfirm) && maxActual <= quantity.input && max != -1) || max == 0|| quantity.input >= maxLimit}
              onMouseOver={showPopWarning}
              className={`bg-gray-100 h-full w-8 rounded cursor-pointer 
                          focus:outline-none ${disable || ((!autoConfirm || !notifyConfirm) && max <= quantity.input && max != -1) || ((autoConfirm || notifyConfirm) && maxActual <= quantity.input && max != -1) || max == 0 || quantity.input >= maxLimit ? 'cursor-not-allowed text-gray-300' : 'hover:bg-gray-300 text-gray-500 hover:text-gray-700 '}`}
            >
              <span className="m-auto text-2xl font-light leading-3 outline-none">+</span>
            </button>
          </div>
        </div>
        {notifyConfirm && quantity.input != quantity.confirm && (
          <>
            <div className="absolute z-20 flex flex-row -translate-y-1/2 -left-8 top-1/2 gap-x-2"> 
              <button
                type="button"
                onClick={revertValue}
                className="p-1 text-white bg-transparent group"
                data-tooltip={t('anuluj')}
              >
                <svg className="w-6 h-6 bg-red-500 rounded-sm shadow-sm group-hover:bg-red-400" viewBox="-220 -220 800 800" fill="currentColor">
                  <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 
                    0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 
                    113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 
                    113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"
                  />
                </svg>
              </button>
            </div>
            <div className="absolute z-20 flex flex-row -translate-y-1/2 -right-8 top-1/2 gap-x-2"> 
            {/* <div className="absolute z-20 flex flex-row -translate-x-1/2 ml-1/2 -top-3 gap-x-2">  */}
            {/* <div className="flex flex-row gap-x-1">  */}
              <button
                type="button"
                onClick={confirmValue}
                className="p-1 text-white bg-transparent group"
                data-tooltip={t('zapisz')}
              >
                <svg className="w-6 h-6 bg-green-500 rounded-sm shadow-sm group-hover:bg-green-400" viewBox="-180 -220 750 750" fill="currentColor">
                  <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 
                  0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 
                  15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" 
                />
                </svg>
              </button>
            </div>
          </>
        )}
      </div>
      <PopWarning
        refElement={refDiv}
        isOpen={showPopup > 0}
        template={(`maksymalna ilość`)}
      />
    </div>
  );
};

export default InputCounter;
