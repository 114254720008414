import { useState, useRef, useEffect, forwardRef } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
// import { Transition } from '@headlessui/react';
import styled from 'styled-components';
import CSVFileValidator from 'csv-file-validator';

import useCartAction from '../hook/cartaction';
import useInterval from '../hook/useInterval';
import AlertDialog from '../modal/AlertDialog';
import NormalDialog from '../modal/normal';
import Tooltip from '../tooltip';

import api from '../service/api.ts';
import { parseJSON } from '../util';
import { useTranslation } from 'react-i18next';

const P = styled.p`
span {
  font-size:1.0em;
  font-weight:500;
}
`;

const TableWrap = styled.div`
  & table {
    border-collapse: collapse;
    width:100%;
    border:none;
    color: var(--main);
    background-color: white;
    text-align:left;
    margin-top:8px;
    border-collapse: collapse;
    table-layout: fixed;

    & tr th:last-child, tr td:last-child {
      width:180px;
    }

    & thead, tbody tr {
      display:table;
      width:100%;
      table-layout:fixed;
    }
    
    & thead.scroll {
      width: calc(100% - 0.5em);
    }

    & th {
      padding:4px 8px;
      border-bottom:1px solid #dedede;
    }

    & td {
      padding:1px 8px;
    }

    & tbody {
      display: block;
      max-height:300px;
      overflow-y:auto;
    }
  }
`;

// const TableInfo = styled.table`
//   border:1px solid #dedede;

//   > thead, tbody {
//     width:100%;
//   }
// `;

const Button = styled.button`
  &:hover div.tooltip-hover {
    display:block;
  }
`;

const getArticle = async (symbol) => {
  const resp = await api.getArticleBySymbol(symbol);
  return resp?.data;
};

const CartImport = () => {
  const { t } = useTranslation("cart");
  
  const [isSelected, setSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOption, setIsOption] = useState(0);
  const refFile = useRef(null);
  const [state, cartAction] = useCartAction();

  const blurList = () => {
    setTimeout(() => {
      isOpen != false && setIsOpen(false);
    }, 10);
  };

  const changeFile = function(event) {
    console.log('CartImport', event.target.files[0]);
    const file = event.target.files[0];

    if (file.size > 100000 || file.name.split(".").pop() != "csv") {
      event.preventDefault();
      AlertDialog({
        title: "Błąd pliku",
        message: "Nieprawidłowy format pliku",
        color: "yellow"
      });
      return;
    }

    setSelected(true);
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");

    reader.onload = function(evt) {
      // console.log(evt.target.result);
      openModal(evt.target.result);
    };

    reader.onabort = function() {
      setSelected(false);
      refFile.current.value="";
    };

    reader.onerror = function() {
      AlertDialog({
        title: "Informacja",
        message: "Nie powiodło się odczytanie pliku",
        color: 'yellow',
      });
      console.log("error reading file");
      setSelected(false);
      refFile.current.value="";
    };
  };

  const clickFile = function(e) {
    if (refFile.current != null)
      refFile.current.click();
  };

  const toggleOption = function(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const fileToCart = async (content, setCount = (x) => {} /* useState 0-100 int */) => {
    const fileLines = content.split("\n");

    if (!Array.isArray(fileLines) || fileLines.length == 0)
      return false;

    const productList = [];
    fileLines.forEach(val => {
      const productLine = val.split(';');
      if (!Array.isArray(fileLines) || productLine.length < 2) {
        return;
      }

      const symbol = productLine[0].trim();
      if (symbol.length === 0)
        return;

      const quantity = parseFloat(productLine[1]);
      if (isNaN(quantity) || quantity <= 0)
        return;

      const jm = productLine[2].trim();
      if (jm.length < 0)
        return;

      const productFound = productList.find(a => a.symbol == symbol && a.jm == jm);
      if (productFound == undefined)
        productList.push({ symbol, quantity, jm });
    });

    if (productList.length == 0)
      return false;

    const productNotImport = [];
    const countMax = productList.length;
    let countInc = 0;

    for (const product of productList) {
      setCount(Math.round((++countInc / countMax) * 100));
      const data = await getArticle(product.symbol);

      if (data?.status != 0 || String(data.dane).length <= 0) {
        productNotImport.push({ ...product, status: 1 });
        continue;
      }

      const jsonData = parseJSON(data.dane);
      const productData = Array.isArray(jsonData) ? jsonData[0] : null;

      if (productData === null || typeof productData != 'object') {
        productNotImport.push({ ...product, status: 2 });
        continue;
      }

      const jmPom = productData.jm_pom.find(a => a.symbol_jmp == product.jm); // obj {symbol_jm: "szt.", ...}

      if (jmPom == undefined) {
        productNotImport.push({ ...product, status: 3 });
        continue;
      }

      const quantityMax = Math.max(0, typeof productData.zapas.actual === 'boolean' ? +productData?.zapas_wolny_q : +productData.zapas.actual);
      if (quantityMax <= 0) {
        productNotImport.push({ ...product, status: 4 });
        continue;
      }

      const quantityObj = { input: 1, confirm: parseFloat(product.quantity) };
      await cartAction.setProduct({ item: productData, symbolJM: product.jm, quantity: quantityObj });
    }

    return productNotImport;
  };

  const openModal = (fileContent) => {
    const container = document.getElementById('show-modal');

    const closeModal = () => {
      unmountComponentAtNode(container);
    };

    const BarLoader = function() {
      const [barWidth, setBarWidth] = useState(0);

      const getStatusTranslate = (status) => {
        switch (status) {
          case 1: return 'błąd dodania pozycji';
          case 2: return 'produkt nie istnieje';
          case 3: return 'nieprawidłowa jm';
          case 4: return 'niewystarczający zapas';
          default: return '';
        }
      };

      useEffect(() => {
        // console.log("FILECONTENT", fileContent);

        fileToCart(fileContent, setBarWidth)
          .then(result => {
            // console.log("RESULT", result)

            if (result === false) {
              AlertDialog({
                title: "Ostrzeżenie",
                message: "Nie udało się zaimportować artykuły do koszyka",
                color: 'yellow',
              });
              return;
            }

            if (result.length == 0) {
              AlertDialog({
                title: "Informacja o imporcie",
                message: "Pomyślnie załadowano artykuły do koszyka",
                color: 'green',
              });
              return;
            }

            AlertDialog({
              title: "Informacja o imporcie",
              color: 'white',
              message: (
                <>
                  <P>{t('Nie zaimportowane produkty')}:</P>
                  <TableWrap>
                    <table>
                      <thead className={`${result.length > 11 ? 'scroll' : ''}`}>
                        <tr>
                          <th>Symbol</th>
                          <th>{t('Ilość / JM')}</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody className="scroll">
                        {result.map((product, index) => (
                          <tr key={`product_import_${product.symbol}_${index}`}>
                            <td>{product.symbol}</td>
                            <td>{product.quantity} {product.jm}</td>
                            <td className="text-red-400">{getStatusTranslate(product.status)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TableWrap>
                </>
              ),
            });
          })
          .finally(_ => {
            closeModal();

            state.select = +(new Date());
            setSelected(false);
            refFile.current.value = "";
          });
      }, []);

      // useInterval(() => {
      //   if (barWidth < 100)
      //     setBarWidth(barWidth+1);
      // }, 200);

      return (
        <>
          <div className="flex justify-between mb-1">
            <span className="text-base font-medium text-gray-700 dark:text-white">
              {t('Importowanie produktów z pliku csv do koszyka')}
            </span>
            <span className="text-sm font-medium text-gray-700 dark:text-white">
              {barWidth}%
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div className="bg-primary h-2.5 rounded-full" style={{ width: `${barWidth}%`}} />
          </div>
        </>
      );
    };

    render(
      <NormalDialog
        title={t("Importuj z pliku")}
        open={true}
        onClose={closeModal}
        onConfirm={()=> ''}
        // textBtn="Przerwij"
      >
        <BarLoader />
      </NormalDialog>,
      container,
    );
  };

  return (
    <Button
      type="button"
      onClick={clickFile}
      className={`relative hidden p-2 px-3 mb-2 mr-2 text-sm leading-4 border border-gray-300 rounded 
      shadow-sm group md:block border-1 hover:bg-gray-800 hover:border-gray-800 hover:text-white 
      disabled:opacity-50 hover:border-transparent`}
      disabled={isSelected}
      title=""
    >
      <div className="flex flex-row items-stretch" data-cy="import-from-file-button">
        <input
          ref={refFile}
          type="file"
          onChange={changeFile}
          className="absolute invisible w-0 h-0"
          accept=".csv"
        />
        {/* <span className="flex items-center px-3 m-px mr-0 border-r border-gray-300"> */}
        <svg className="inline w-4 h-4 mr-2" viewBox="0 0 477 477" fill="currentColor">
          <path d="M409.6,153.6h-85.333c-9.426,0-17.067,7.641-17.067,17.067s7.641,17.067,17.067,17.067H409.6
            c9.426,0,17.067,7.641,17.067,17.067v221.867c0,9.426-7.641,17.067-17.067,17.067H68.267c-9.426,0-17.067-7.641-17.067-17.067
            V204.8c0-9.426,7.641-17.067,17.067-17.067H153.6c9.426,0,17.067-7.641,17.067-17.067S163.026,153.6,153.6,153.6H68.267
            c-28.277,0-51.2,22.923-51.2,51.2v221.867c0,28.277,22.923,51.2,51.2,51.2H409.6c28.277,0,51.2-22.923,51.2-51.2V204.8
            C460.8,176.523,437.877,153.6,409.6,153.6z"
          />
          <path d="M335.947,243.934c-6.614-6.387-17.099-6.387-23.712,0L256,300.134V17.067C256,7.641,248.359,0,238.933,0
            s-17.067,7.641-17.067,17.067v283.068l-56.201-56.201c-6.78-6.548-17.584-6.361-24.132,0.419c-6.388,6.614-6.388,17.1,0,23.713
            l85.333,85.333c6.657,6.673,17.463,6.687,24.136,0.03c0.01-0.01,0.02-0.02,0.031-0.03l85.333-85.333
            C342.915,261.286,342.727,250.482,335.947,243.934z"
          />
        </svg>
        {t('Importuj z pliku')}
        <Tooltip
          className="absolute hidden -top-2 -right-2 tooltip-hover"
          content={(
            <div>
              Plik <b>CSV</b> o zawartości:<br />
              <p className="pt-1 text-sm text-gray-600">SYMBOL_ART;ILOŚĆ;JM</p>
              {/*
                <TableInfo>
                  <thead>
                    <tr><th>Symbol art.</th><th>Ilość</th><th>Jednostka miary</th></tr>
                  </thead>
                  <tbody>
                    <tr><td>2865</td><td>2</td><td>szt.</td></tr>
                    <tr><td>78</td><td>1</td><td>opak.</td></tr>
                    <tr><td>ABC</td><td>2</td><td>KPL</td></tr>
                  </tbody>
                </TableInfo>
              */}
            </div>
          )}
        >
          <div className="z-10 w-4 h-4 text-blue-500 hover:text-primary">
            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill='currentColor'>
              <circle cx="50%" cy="50%" r="50%" fill="white" />
              <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 
                90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 
                16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 
                33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 
                83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 
                46-46c0-25.365-20.635-46-46-46z"
              />
            </svg>
          </div>
        </Tooltip>
        {/*  */}
        {/* </span> */}
        {/*
        <a
          className="relative flex items-center py-2 pl-3 pr-10 ml-0 text-sm text-black bg-white rounded-r"
          href={void(0)}
          onClick={toggleOption}
          onBlur={blurList}
        >
          {isOption == 0 ? 'symbol art.' : 'nr. katalog.'}
          <span className="absolute inset-y-0 right-0 z-10 flex items-center px-2">
            <svg className="w-5 h-5 text-gray-400" viewBox="1 0 20 20" fill="currentColor">
              <path d="m6.293,7.90276a1,1 0 0 1 1.414,0l2.293,2.293l2.293,-2.293a1,1 0 0 1 1.414,1.414l-3,3a1,1 0 0 1 -1.414,0l-3,-3a1,1 0 0 1 0,-1.414z" />
            </svg>
          </span>
        </a>
        */}
      </div>
    </Button>
  );
};

export default CartImport;
