import { useState, useEffect } from 'react';

const useGroupData = (defValue) => {
  const [length, setLength] = useState(0);

  useEffect(() => {
    if (length == defValue.length) return;
    setLength(defValue.length);
    groupGrid._name[1](defValue);
  }, [defValue]);

  const groupGrid = {
    length,
    _limit: 6,
    _color: 2,
    _colorShift: 0,
    // changed: (+new Date()),
    _name: useState(defValue),
    _show: useState({}),
    getColor(name, shift = undefined) {
      const shiftColor = shift || this._colorShift;

      switch (this._name[0].indexOf(name)) {
        case (0 + shiftColor): return 'green';
        case (1 + shiftColor): return 'red';
        case (2 + shiftColor): return 'blue';
        case (3 + shiftColor): return 'yellow';
        case (4 + shiftColor): return 'purple';
        default: return 'gray';
      }
    },
    getTxtColor(name, opacity = 500) {
      return `text-${this.getColor(name)}-${opacity}`;
    },
    getBgColor(name, opacity = 50) {
      const color = this.getColor(name);
      // return `bg-${color === 'green' ? 'grey' : color}-${opacity}`;
      return `bg-${color}-${opacity}`;
    },
    getName() {
      return this._name[0];
    },
    getLength() {
      return +this._name[0].length;
    },
    setName(name) {
      if (this._name[0].indexOf(name) != -1) {
        const nameNew = this._name[0].filter(el => el != name);
        setLength(nameNew.length);
        this._name[1](nameNew);
        return;
      }

      setLength(this._name[0].length + 1);
      this._name[1]([...this._name[0], name]);
      // // this.changed = (+new Date());
    },
  };

  return { groupGrid };
};

export default useGroupData;
