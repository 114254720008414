import { useState } from 'react';

import { getDownloadFile } from "../../components/util";
import { sanitizeFilename, getCurrentDate } from '../../components/util';

import useCartAction from '../../components/hook/cartaction';
import api from '../../components/service/api';

const getCartPdf = async (id, name) => {
  const resp = await api.getCartPdf(id, name);
  return resp?.data;
};

const CartPdf = ({ cart, defaultMessage = 'Eksportuj do PDF' }) => {
  if (cart == false) return '';

  const [cartState,] = useCartAction();
  const [loading, setLoading] = useState(false);

  const onExport = (e) => {
    e.preventDefault();
    if (loading) return false;

    setLoading(true);
    const cartId = cartState.cart.selectNormal;
    const cartName = cartState.cart.getNameById(cartId);

    getCartPdf(cartId, cartName)
      .then(data => {
        if (data?.status != 0) {
          setLoading(false);
          return;
        }

        const title = data?.info;
        const url = "data:application/pdf;base64," + data.dane;

        fetch(url)
          .then(res => res.blob())
          .then(blob => {
            const fileName = `b2b__${sanitizeFilename(title)}_${getCurrentDate()}.pdf`;
            getDownloadFile(blob, fileName);
          })
          .finally(() => {
            setLoading(false);
          });
    }).catch(() => {
      setLoading(false);
    });

    return false;
  };

  return (
    <button
      type="button"
      className="relative p-2 px-3 mb-2 mr-2 text-sm leading-4 border border-gray-300 rounded shadow-sm group border-1 hover:bg-gray-800 hover:text-white hover:border-transparent"
      onClick={onExport}
      data-cy="export-to-pdf-button"
      disabled={loading}
    >
      {loading && (
        // ICON LOADING CIRCLE
        <svg className="inline mr-1.5 w-5 h-5 bg-transparent animate-spin" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
          <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
      )}
      {!loading && (
        // ICON PDF
        <svg className="inline mr-1.5 w-5 h-5 text-gray-600 group-hover:text-white" viewBox="0 0 15 15" fill="currentColor">
          <path d="M2.5 6.5V6H2V6.5H2.5ZM6.5 6.5V6H6V6.5H6.5ZM6.5 10.5H6V11H6.5V10.5ZM13.5 3.5H14V3.29289L13.8536 3.14645L13.5 3.5ZM10.5 0.5L10.8536 
            0.146447L10.7071 0H10.5V0.5ZM2.5 7H3.5V6H2.5V7ZM3 11V8.5H2V11H3ZM3 8.5V6.5H2V8.5H3ZM3.5 8H2.5V9H3.5V8ZM4 7.5C4 7.77614 3.77614 8 3.5 8V9C4.32843 
            9 5 8.32843 5 7.5H4ZM3.5 7C3.77614 7 4 7.22386 4 7.5H5C5 6.67157 4.32843 6 3.5 6V7ZM6 6.5V10.5H7V6.5H6ZM6.5 11H7.5V10H6.5V11ZM9 9.5V7.5H8V9.5H9ZM7.5 
            6H6.5V7H7.5V6ZM9 7.5C9 6.67157 8.32843 6 7.5 6V7C7.77614 7 8 7.22386 8 7.5H9ZM7.5 11C8.32843 11 9 10.3284 9 9.5H8C8 9.77614 7.77614 10 7.5 
            10V11ZM10 6V11H11V6H10ZM10.5 7H13V6H10.5V7ZM10.5 9H12V8H10.5V9ZM2 5V1.5H1V5H2ZM13 3.5V5H14V3.5H13ZM2.5 1H10.5V0H2.5V1ZM10.1464 0.853553L13.1464 
            3.85355L13.8536 3.14645L10.8536 0.146447L10.1464 0.853553ZM2 1.5C2 1.22386 2.22386 1 2.5 1V0C1.67157 0 1 0.671573 1 1.5H2ZM1 12V13.5H2V12H1ZM2.5 
            15H12.5V14H2.5V15ZM14 13.5V12H13V13.5H14ZM12.5 15C13.3284 15 14 14.3284 14 13.5H13C13 13.7761 12.7761 14 12.5 14V15ZM1 13.5C1 14.3284 1.67157 15 
            2.5 15V14C2.22386 14 2 13.7761 2 13.5H1Z" 
          />
        </svg>
      )}
      <span className="relative top-px">{defaultMessage}</span>
    </button>
  );
};

export default CartPdf;
