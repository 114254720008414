import SelectSingle from "../propselect/selectsingle";

const SSelect = ({ value = [{ key: 'SZT', value:'szt.' }], defaultValue = '', className = '', handleChange = (el) => {} }) => {
  const disabled = false; //(Math.random() * 100) < 60;

  if (value.length <= 1)
    return (
      <span>
        {value.length > 0 && value[0].value}
      </span>
    );

  return (
    <SelectSingle
      className={className}
      readOnly={disabled}
      disabled={disabled}
      list={value.map(el => ({ ...el, id: el.key }))}
      defaultValue={defaultValue}
      handleUpdate={handleChange}
    />
  );
};

export default SSelect;
