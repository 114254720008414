import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import SResizer from './sresizer';
import SFilter from './sfilter';
import SCheckbox from './scheckbox';
import SSummary from './ssummary';
import useSortData from '../hook/useSortData';
import useGroupData from '../hook/useGroupData';

// import useRefCallback from '../hook/useRefCallback';

import SDragable from './sdragable';
import { default as storage } from '../util/storage';
// import PopWarning from '../../components/PopWarning';

import { uid } from '../util';
import { IconDot, IconDot2 } from '../icon';

import './style.css';

const ButtonGroup = styled.button`
  &:hover div.tooltip-hover {
    display:block;
  }
`;

const STable = ({
  name = '',
  keyName = '',
  funcSort = null,
  funcGroup = null,
  funcFilter = null,
  data,
  children,
  summary = '',
  summaryStyle = '',
  noHeader = false,
  noDrag = false,
  defaultSort = '',
  defaultGroup = [],
  defaultFilter = {},
  transMax = 3000,
  loader = {load: -1, max: 0, info: ""},
  handleDone = () => {},
}) => {
  const { sortedItems, requestSort, directionKey } = useSortData(data, defaultSort);
  const { groupGrid } = useGroupData(defaultGroup);
  const [filterGrid, setFilterGrid] = useState(defaultFilter);
  const [saveItems, setSaveItems] = useState([]);
  const [tableIndex, setTableIndex] = useState(
    storage.getObject(`grid-column-${name}`, Array.from({ length: React.Children.count(children) || 0 }, (_, i) => i + 1)),
  );
  // const [isPending, startTrans] = useTransCustom(transMax); // TODO: check prevent the same time multi sort
  const refTable = useRef(); // useRefCallback();
  // const reactChildren = React.Children;

  const onUpdateDrag = () => {
    if (name == '') return;

    const nodeList = refTable.current.querySelectorAll('thead > tr:first-child th');

    if (nodeList.length <= 0) return;

    const tableIndexNew = Array.from(nodeList).map(val => +val.getAttribute('tabIndex'));

    storage.setObject(`grid-column-${name}`, tableIndexNew || []);
    setTableIndex(tableIndexNew);
  };

  const getCurrentChildren = () => {
    const childrenFilter = React.Children.map(children, (el) => el); //.filter(Boolean);
    if (tableIndex.length > 0 && React.Children.count(childrenFilter) == tableIndex.length) {
      return tableIndex.map(val => childrenFilter[val - 1]);
    }

    return childrenFilter;
  };

  useEffect(() => {
    if (JSON.stringify(defaultFilter) !== JSON.stringify(filterGrid)) {
      setTimeout(() => setFilterGrid(defaultFilter), 0);
    }
  }, [defaultFilter]);

  useEffect(() => {
    if (refTable.current != null) SDragable(refTable.current, onUpdateDrag);
  }, []);

  useEffect(() => {
    if (sortedItems == undefined) return;

    if (sortedItems.length === 0) {
      setSaveItems([]);
      return;
    }

    // -------------------
    // --  TABLE GROUP  --
    // -------------------
    const groupList = [];

    const TableGroup = function({ value, item, rowIndex, keyName, level = 0, showParent = true }) {
      const groupGridLength = groupGrid.length;
      const valueMap = groupGridLength > level ? value._values : [value];

      if (valueMap === undefined) return '';

      const status = valueMap["_status"] || 0;
      const currChildren = getCurrentChildren();

      const [show, setShow] = useState(false);
      const group = {
        toggleShow: () => setShow(!show),
        color: groupGrid.getTxtColor(value._group || ""),
        padding: level > 0 ? `pl-${level + level + 1}` : '',
        show,
        level,
      };

      return (
        <>
          {groupGridLength > 0 && (
            <tr
              key={`table_group_row_${level}_${String(value._name)}`}
              className={`align-middle bg-gray-100 group-row 
                ${((show && showParent) || (!show && showParent) || level == 0) && 'group-row-show'}
                ${groupGrid._color > 1 ? groupGrid.getBgColor(value._group) : ''}
              `}
              data-level={level}
            >
              {React.Children.map(currChildren, (child, index) => (
                child != null && (
                  React.cloneElement(child, {
                    data: value,
                    tabindex: tableIndex[index],
                    rowindex: rowIndex,
                    keyName,
                    groupGrid: group,
                    level,
                  })
                )
              ))}
            </tr>
          )}
          {show && groupGridLength > (level + 1) && (
            valueMap.map((elem, index) => (
              <TableGroup
                key={`table_group_next_${level}_${String(value._name)}_${index}`}
                value={elem}
                currChildren={currChildren}
                item={item}
                rowIndex={rowIndex}
                // tableIndex={tableIndex}
                keyName={keyName}
                level={level + 1}
                showParent={show}
              />
            ))
          )}
          {(show || groupGridLength == 0) && (groupGridLength == 0 || groupGridLength == (level + 1)) && (
            valueMap.map((elem) => (
              <React.Fragment key={`table_fragment_row_${level}_${String(elem[keyName] ?? rowIndex)}_${uid()}`}>
                <tr
                  key={`table_row_${level}_${String(elem[keyName] ?? rowIndex)}_${uid()}`}
                  className={`
                    ${(status === 1 || status === 3) ? 'status-quantity' : ''} 
                    ${(status === 2 || status === 3) ? 'status-price' : ''}
                    group-row ${((show && showParent) || (groupGridLength == 0)) && 'group-row-show'}
                  `}
                  data-level={level + 1}
                  data-length={valueMap.length}
                >
                  {React.Children.map(currChildren, (child, index) => (
                    child != null && (
                      React.cloneElement(child, {
                        data: elem,
                        tabindex: tableIndex[index],
                        rowindex: rowIndex,
                        keyName,
                      })
                    )
                  ))}
                </tr>
                <tr key={`expandable_${String(elem[keyName] ?? rowIndex)}_${uid()}`} className="hidden expandable">
                  <td colSpan={React.Children.count(currChildren)} className="shadow-detail" />
                </tr>
              </React.Fragment>
            ))
          )}
        </>
      );
    };

    // ------------------
    // --  TABLE INIT  --
    // ------------------
    // for (let rowIndex = 0; rowIndex < sortedItems.length; rowIndex++) {
    sortedItems.forEach((item, rowIndex) => {
      groupList.push(
        <tbody key={`table_body_${+rowIndex}`}>
          <TableGroup
            value={item}
            item={item}
            rowIndex={rowIndex}
            // tableIndex={tableIndex}
            keyName={keyName}
            level={0}
          />
        </tbody>,
      );
    });
    // }

    setSaveItems(groupList);
    handleDone();
  // }, [sortedItems, groupGrid._show[0]]);
  }, [sortedItems]);

  /* sort */
  const handleRequestSort = (e, symbol, sort, type) => !sort || (e.target.tagName !== 'BUTTON' && requestSort(sort || symbol, type, null, funcSort));

  const sortTemplate = (symbol) => (
    <span className={`sort-${directionKey(symbol)} after:blue-500 text-xl sort`}>
      <svg className="fill-current sort text-primary" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"/>
      </svg>
      <svg className="fill-current sort text-primary" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
      </svg>
    </span>
  );

  const handleRequestFilter = (filterObj) => {
    // e.stopPropagation();
    // startTrans(() => {
    const filterTmp = ({ ...filterGrid, [filterObj[0]]: filterObj[1] });
    if (filterObj[1][0] === '' && filterObj[1][1] === '') {
      delete filterTmp[filterObj[0]];
    }

    typeof funcFilter === 'function' && funcFilter(filterTmp);
    // console.log('filterFunc', filterGrid, filterTmp);
    setFilterGrid(filterTmp);
    // });
  };

  /* filter */
  const filterTemplate = (symbol) => (
    <div className="relative inline-block">
      <span className="px-2">&nbsp;</span>
      <button
        type="button"
        className={`filter-${symbol} group text-xl filter absolute -left-1 -top-2 p-2 focus:outline-none`}
        // onClick={(e) => handleRequestFilter(e, symbol)}
      >
        <svg className="w-4 h-4 group-hover:text-primary" viewBox="0 0 472.615 472.615" enableBackground="new 0 0 472.615 472.615" fill="currentColor">
          <g transform="scale(0.8,0.8)" transform-origin="center">
            <polygon points="472.615,12.908 0,12.908 180.081,202.629 180.066,459.708 292.55,401.525 292.534,202.629" />
          </g>
        </svg>
      </button>
    </div>
  );

  /* group */
  const handleRequestGroup = (e, symbol) => {
    e.stopPropagation();
    // startTrans(() => {
    // console.log("handleRequestGroup", symbol)
    groupGrid.setName(symbol);
    // });
  };

  useEffect(() => {
    typeof funcGroup == "function" && funcGroup(groupGrid.getName());
  }, [groupGrid.length]);

  const groupTemplate = (symbol) => {
    const groupExists = groupGrid.getName().indexOf(symbol) != -1;

    return (
      <div className={`is_group ${groupExists ? 'show' : ''}`} data-title="grupowanie">
        <span className="p-2">&nbsp;</span>
        <ButtonGroup
          type="button"
          className={`group-${symbol} group pr-2 absolute -top-2 -left-2 p-2 focus:outline-none`}
          onClick={(e) => groupGrid.getName().length <= groupGrid._limit && handleRequestGroup(e, symbol)}
        >
          <>
            {groupExists ? (
              <IconDot2 size={4} color={groupGrid.getColor(symbol, 0)} />
            ) : groupGrid.getName().length < groupGrid._limit ? (
              <IconDot size={4} color='gray' />
            ) : (
              <></>
            )}
            {/* <Tooltip
              className="absolute hidden top-2 tooltip-hover tooltip-show"
              content={`Grupowanie`}
            /> */}
          </>
        </ButtonGroup>
      </div>
    );
  };

  // if (saveItems.length == 0)
  //   return '';

  return (
    <div className="relative w-full">
      <table
        role="grid"
        className="min-w-full stable sgrid"
        ref={refTable}
      >
        {!noHeader && (
        <thead>
          <tr>
            {React.Children.map(getCurrentChildren(), (column, index) => {
              // const [popAlert, setPopAlert] = useState(false);
              if (column == null) return (<></>);

              return (
                <th
                  key={`${column.props.symbol}_${+index}`}
                  // onClick={(e) => !isPending && handleRequestSort(e, column.props.symbol, column.props.sort, column.props?.type)}
                  onClick={(e) => handleRequestSort(e, column.props.symbol, column.props.sort, column.props?.type)}
                  className={`relative bg-gray-50 text-xs leading-4 font-medium text-gray-500 tracking-wider
                    ${(column.props?.drag !== undefined && !noDrag) ? 'drag ' : ''}
                    ${column.props?.sort !== undefined ? 'sort ' : ''}
                    ${column.props?.group !== undefined ? '_group' : ''}
                    ${column.props?.filter !== undefined ? 'filter ' : ''}
                    ${column.props?.classHeader !== undefined ? column.props?.classHeader : 'px-5 py-3 uppercase text-left'} 
                    ${column.props.typeColumn === 'checkbox' ? 'px-3 w-8 ml-3' : ''}
                  `}
                  // style={column.props?.styleHeader !== undefined ? { ...column.props?.styleHeader } : {}}
                  tabIndex={tableIndex[index]}
                  // onMouseEnter={() => column.props?.alert && setPopAlert(true)}
                  // onMouseLeave={() => column.props?.alert && setPopAlert(false)}
                >
                  {column.props.typeColumn === 'checkbox' && (
                    <SCheckbox checkAll />
                  )}

                  {column.props?.group && groupTemplate(column.props.group)}

                  {typeof column.props?.headerTemplate !== 'function' && (
                    <span className={column.props?.style}>
                      {column.props?.nameHidden != true && column.props.name}
                      {/* {column.props?.alert && (<em className="text-red-500">*</em>)} */}
                    </span>
                  )}

                  {typeof column.props?.headerTemplate === 'function' && (column.props.headerTemplate())}
                  {/* {column.props?.filter !== undefined && filterTemplate(column.props.filter)} */}
                  {column.props?.sort !== undefined && sortTemplate(column.props.sort)}
                  {column.props.resize !== undefined ? <SResizer mode={column.props.resize} /> : ''}
                  {/* {column.props?.alert && (
                    <div className="absolute bottom-0 right-10">
                      <PopWarning
                        isOpen={popAlert}
                        template={(column.props.alert)}
                      />
                    </div>
                  )} */}
                </th>
              );
            })}
          </tr>
          <tr className="bg-gray-100 filter">
            {React.Children.map(getCurrentChildren(), (column, index) => (
              column != null && (
                column.props.filter !== undefined ? (
                  <th key={`filter_${column.props.symbol}_${index}`}>
                    <SFilter
                      symbol={column.props.symbol}
                      type={column.props?.type}
                      handleChange={handleRequestFilter}
                      filterDefault={filterGrid}
                    />
                  </th>
                ) : <th className="nofilter" label="nofilter" />
              )
            ))}
          </tr>
          {loader.load > -1 && loader.max > 0 && (loader.load != loader.max) && (
            <tr className={`bg-gray-50 loader border-none`}>
              <th className="text-sm font-medium" colSpan={getCurrentChildren().length || 0}>
                <div className="w-full h-1 bg-gray-200 rounded-md dark:bg-gray-700">
                  <div className="h-1 rounded-md bg-primary " style={{ width: parseFloat(+loader.load / (+loader.max || 1)).toFixed(2) * 100 + "%" }}></div>
                </div>
                <div className='py-1 text-primary'>
                  {loader.info} {Math.round(parseFloat(+loader.load / (+loader.max || 1)).toFixed(2) * 100)}%
                </div>
              </th>
            </tr>
          )}
        </thead>
        )}
        {saveItems}
        {(typeof summary === 'function') && (
        <tfoot className={`${summaryStyle}`}>
          <tr>
            <SSummary content={summary(data, React.Children.count(children) || 0)} />
          </tr>
        </tfoot>
        )}
      </table>
    </div>
  );
};

STable.propTypes = {
  keyName: PropTypes.string.isRequired,
  //data: PropTypes.arrayOf,
  //children: PropTypes.arrayOf(PropTypes.element),
};

STable.defaultProps = {
  //data: [],
};

export default STable;
