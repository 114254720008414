import { useEffect } from 'react';
import SelectMulti from './selectmulti';
import SelectSingle from './selectsingle';

import { toBoolean } from '../util';

const StarRequired = () => (
  <sup className="relative inline-block ml-2">
    <svg className="absolute w-2 h-2 text-red-600 -left-2 -top-1" viewBox="0 0 478.53 478.53" style={{ enableBackground: 'new 0 0 478.53 478.53' }} fill="currentColor">
      <path d="M477.795,184.279c-1.765-5.431-6.458-9.389-12.108-10.209l-147.159-21.384l-65.812-133.35
        c-2.527-5.12-7.741-8.361-13.451-8.361s-10.924,3.241-13.451,8.361l-65.812,133.35L12.843,174.07
        c-5.65,0.82-10.344,4.778-12.108,10.209c-1.765,5.43-0.293,11.391,3.796,15.376l106.484,103.798L85.877,450.018
        c-0.965,5.627,1.349,11.314,5.968,14.671c4.618,3.354,10.741,3.799,15.797,1.142l131.623-69.199l131.623,69.199
        c2.195,1.153,4.592,1.723,6.979,1.723c3.11,0,6.205-0.966,8.818-2.864c4.619-3.356,6.933-9.044,5.968-14.671l-25.138-146.565
        l106.484-103.798C478.088,195.669,479.56,189.708,477.795,184.279z"
      />
    </svg>
  </sup>
);

const InputCheck = ({ element, propertyDefault = {} /* {field: 'CHECK', value: true} */, handleUpdate = (obj) => {} }) => {
  const onChange = (e) => {
    handleUpdate({ [`${element.pole}`]: !!(e.target.checked) });
  };

  return (
    <div className="flex items-center content-center">
      <label htmlFor="property-select_inputcheck" className="relative h-5 pr-2 text-gray-800 " style={{ top: '-4px' }}>
        {element.wymagane && (<StarRequired />)}
        <span className={`leading-4 pr-2  ${element.wymagane ? 'text-red-600' : 'text-gray-800'}`}>
          {element.opis}
        </span>
        <input
          onChange={onChange}
          className={`relative form-check-input appearance-none ring-0 focus:ring-0 h-6 w-6 ml-2 border shadow-sm rounded 
            border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 
            align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer
          `}
          type="checkbox"
          id="property-select_inputcheck"
          style={{ marginTop: "-1px" }}
          defaultChecked={!!propertyDefault?.value}
        />
      </label>
    </div>
  );
};

const InputSwitch = ({ element, propertyDefault = {} /* {field: 'PODC', value: true} */, handleUpdate = (obj) => {} }) => {
  const onChange = (e) => {
    handleUpdate({ [`${element.pole}`]: !!(e.target.checked) });
  };

  return (
    <div className="relative pl-0 form-check form-switch">
      <label htmlFor="property-select_inputswitch" className="flex flex-row items-center cursor-pointer">
        {element.wymagane && (<StarRequired />)}
        <span className={`leading-4 pr-2  ${element.wymagane ? 'text-red-600' : 'text-gray-800'}`}>
          {element.opis}
        </span>
        <input
          onChange={onChange}
          type="checkbox"
          id="property-select_inputswitch"
          className="float-left w-10 h-6 align-top bg-gray-300 bg-no-repeat bg-contain border border-gray-300 rounded-full shadow-sm appearance-none cursor-pointer form-check-input focus:ring-none focus:outline-none"
          defaultChecked={!!propertyDefault?.value}
        />
      </label>
    </div>
  );
};

const InputString = ({ element, propertyDefault = {} /* {field: 'CTRL', value: "text"} */, handleUpdate = (obj) => {} }) => {
  const onChange = (e) => {
    handleUpdate({ [`${element.pole}`]: ''+(e.target.value) });
  };

  return (
    <div className="relative pl-0">
      <label htmlFor="property-select_inputstring" className="flex flex-row items-center cursor-pointer">
        {element.wymagane && (<StarRequired />)}
        <span className={`leading-4 pr-2  ${element.wymagane ? 'text-red-600' : 'text-gray-800'}`}>
          {element.opis}
        </span>
        <input
          onChange={onChange}
          type="text"
          maxLength={+element.len}
          id="property-select_inputstring"
          className={`appearance-none h-7 w-24 leading-5 block px-2 py-0 text-sm text-gray-700 bg-white bg-clip-padding bg-no-repeat 
            border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none rounded
            `}
          defaultChecked={""+propertyDefault?.value}
        />
      </label>
    </div>
  );
};

const InputNumber = ({ element, propertyDefault = {} /* {field: 'CTRL', value: "text"} */, handleUpdate = (obj) => {} }) => {
  const onChange = (e) => {
    const value = parseFloat(+(e.target.value)).toFixed(+element.prec);
    handleUpdate({ [`${element.pole}`]: value });
  };

  return (
    <div className="relative pl-0">
      <label htmlFor="property-select_inputnumber" className="flex flex-row items-center cursor-pointer">
        {element.wymagane && (<StarRequired />)}
        <span className={`leading-43 pr-2  ${element.wymagane ? 'text-red-600' : 'text-gray-800'}`}>
          {element.opis}
        </span>
        <input
          onChange={onChange}
          type="number"
          maxLength={+element.len}
          pattern={`\d{0,${element.len}}`}
          placeholder="0.00"
          id="property-select_inputnumber"
          className={`appearance-none h-7 w-24 leading-5 block px-2 py-0 text-sm text-gray-700 bg-white bg-clip-padding bg-no-repeat 
            border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none rounded
            `}
          defaultValue={propertyDefault?.value || 0}
        />
      </label>
    </div>
  );
};

const InputDate = ({ element, propertyDefault = {} /* {field: 'CTRL', value: "text"} */, handleUpdate = (obj) => {} }) => {
  const onChange = (e) => {
    handleUpdate({ [`${element.pole}`]: ''+(e.target.value) });
  };

  return (
    <div className="relative pl-0">
      <label htmlFor="property-select_inputdate" className="flex flex-row items-center cursor-pointer">
        {element.wymagane && (<StarRequired />)}
        <span className={`leading-4 pr-2  ${element.wymagane ? 'text-red-600' : 'text-gray-800'}`}>
          {element.opis}
        </span>
        <input
          onChange={onChange}
          type="date"
          id="property-select_inputdate"
          className={`appearance-none h-7 w-32 leading-5 block px-2 py-0 text-sm text-gray-700 bg-white bg-clip-padding bg-no-repeat 
            border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none rounded
            `}
          defaultValue={""+propertyDefault?.value}
        />
      </label>
    </div>
  );
};

const InputSelect = ({ element, propertyDefault = {} /* {field: "MOC", value: "1500W"} */, handleUpdate = (obj) => {} }) => {
  // const [selected, setSelected] = useState(0);

  const onChange = (key) => {
    // setSelected(+(key));
    handleUpdate({ [`${element.pole}`]: ""+(key) });
  };

  return (
    <label htmlFor="property-select_inputselect" className="flex flex-row items-center">
      {element.wymagane && (<StarRequired />)}
      <span className={`leading-4 pr-2  ${element.wymagane ? 'text-red-600' : 'text-gray-800'}`}>
        {element.opis}
      </span>
      <SelectSingle
        list={element.values.sort((a, b) => a.kolejnosc < b.kolejnosc).map(el => ({...el, value: el.wartosc })) }
        definitionKey={element?.key}
        defaultValue={propertyDefault?.value || 0}
        handleUpdate={onChange}
      />
    </label>
  );
};

const InputSelectMulti = ({ element, propertyDefault = {} /* {field: "MOC", value: "1500W,1600W"} */, handleUpdate = (obj) => {} }) => {
  // const [selected, setSelected] = useState([]);

  const onChange = (list) => {
    // console.log("InputSelectMulti", list)
    // setSelected(list);
    handleUpdate({ [`${element.pole}`]: list.sort().join(",") });
  };

  return (
    <label htmlFor="property-select_inputselect" className="flex flex-row items-center">
      {element.wymagane && (<StarRequired />)}
      <span className={`leading-4 pr-2  ${element.wymagane ? 'text-red-600' : 'text-gray-800'}`}>
        {element.opis}
      </span>
      <SelectMulti
         className={`appearance-none h-7 leading-5 block w-full pr-9 py-0 text-sm text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid 
          border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none rounded 
          `}
        defaultValue={!toBoolean(propertyDefault?.value) ? [] : (""+propertyDefault?.value).split(",") || []}
        definitionKey={element?.key}
        list={element.values.sort((a, b) => a.kolejnosc < b.kolejnosc)}
        handleUpdate={onChange}
      />

    </label>
  );
};

// const InputNormal = () => (<>[PROP]</>);

const PropSelect = ({ item, property, selected, onChanged = (prop) => {}, style = "flex-row"}) => {
  const propertyList = selected || [];

  return (
    <div className={`flex gap-x-4 gap-y-2 px-1 flex-wrap ${style}`}>
      {property.map((el, index) => {
        const propertyFound = propertyList.find(sel => sel?.field == el.pole) || {};
        // console.log("SELECTED", propertyFound);

        return (
          <div className="flex items-center" key={`property_${item.symbol_art}_${index}`}>
            {el.typ === 'L' && (<InputSwitch element={el} propertyDefault={propertyFound} handleUpdate={onChanged} />)}
            {el.typ === 'C' && (<InputString element={el} propertyDefault={propertyFound} handleUpdate={onChanged} />)}
            {el.typ === 'N' && (<InputNumber element={el} propertyDefault={propertyFound} handleUpdate={onChanged} />)}
            {el.typ === 'D' && (<InputDate element={el} propertyDefault={propertyFound} handleUpdate={onChanged} />)}
            {el.typ === 'O' && el.ismulti === false && (<InputSelect element={el} propertyDefault={propertyFound} handleUpdate={onChanged} />)}
            {el.typ === 'O' && el.ismulti === true && (<InputSelectMulti element={el} propertyDefault={propertyFound} handleUpdate={onChanged} />)}
          </div>
        );
      })}
    </div>
  )
};

export default PropSelect;
