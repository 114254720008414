import { useState, useEffect, useRef } from 'react';
import { usePopper } from 'react-popper';
import Transition from 'react-transition-group/Transition';
import styled from 'styled-components';

const PopperContainer = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  background-color: rgb(248, 113, 113);
  padding: 10px;
  text-align: center;
  z-index:2;
  color:white;
  font-size:12px;

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;

    &:after {
      content: " ";
      position: absolute;
      top: -15px;
      left: 0;
      transform: rotate(-45deg);
      width: 10px;
      height: 10px;
      background-color: rgb(248, 113, 113);
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    }
  }

  &[data-popper-placement^='top'] > .arrow {
    top: -15px;
    :after {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
`;

const PopWarning = ({ refElement, isOpen, template = (<></>)}) => {
  const nodeRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showDelay, setShowDelay] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(refElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', enabled: true, options: { offset: [0, 12] } },
    ],
  });

  const duration = 150;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
  };

  let timeout = null;

  useEffect(() => {
    if (isOpen) {
      if (timeout !== null) clearTimeout(timeout);
      setShowPopup(true);
      setTimeout(() => setShowDelay(true), 0);
    } else {
      setShowDelay(false);
      timeout = setTimeout(() => setShowPopup(false), duration);
    }

    return () => {
      if (timeout !== null) clearTimeout(timeout);
    };
  }, [isOpen]);

  return (
    <>
      {showPopup && (
        <Transition
          in={showDelay}
          duration={duration}
          timeout={0}
          nodeRef={nodeRef}
        >
          {(state) => (
            <div ref={nodeRef}>
              <PopperContainer
                ref={setPopperElement}
                style={{
                  ...styles.popper,
                  ...defaultStyle,
                  ...transitionStyles[state],
                }}
                {...attributes.popper}
                className=""
              >
                <div ref={setArrowElement} style={styles.arrow} className="arrow" />
                <div className="flex-row lowercase">
                  {template}
                </div>
              </PopperContainer>
            </div>
          )}
        </Transition>
      )}
    </>
  );
};

export default PopWarning;
