import { useContext } from 'react';

import MenuButton from '../../components/side/menubutton';
import Search from '../header/search';
import Link from '../../components/router/link';

import { GlobalDispatchContext, GlobalStateContext } from '../../components/context/global';

import '../../components/side/style.css';

const CatalogMenuSide = ({ children, showSearch = false }) => {
  const globalDispatch = useContext(GlobalDispatchContext);
  const globalState = useContext(GlobalStateContext);

  return (
    <div className={`${globalState.mobile ? 'mobile-on' : 'mobile-off'}`}>
      <div role="" className="mobile-overlay" onClick={() => globalDispatch.setMobile(false)}>
        {/* <svg className="w-5 h-5 text-gray-100" viewBox="0 0 94.926 94.926" fill="currentColor">
          <path d="M55.931,47.463L94.306,9.09c0.826-0.827,0.826-2.167,0-2.994L88.833,0.62C88.436,0.224,87.896,0,87.335,0
            c-0.562,0-1.101,0.224-1.498,0.62L47.463,38.994L9.089,0.62c-0.795-0.795-2.202-0.794-2.995,0L0.622,6.096
            c-0.827,0.827-0.827,2.167,0,2.994l38.374,38.373L0.622,85.836c-0.827,0.827-0.827,2.167,0,2.994l5.473,5.476
            c0.397,0.396,0.936,0.62,1.498,0.62s1.1-0.224,1.497-0.62l38.374-38.374l38.374,38.374c0.397,0.396,0.937,0.62,1.498,0.62
            s1.101-0.224,1.498-0.62l5.473-5.476c0.826-0.827,0.826-2.167,0-2.994L55.931,47.463z"/>
        </svg> */}
      </div>
      <div className="self-start min-h-full border-r mobile-side catalog">
        <aside className="sticky flex flex-col px-0 py-4 pb-0 top-24">
          <div className="flex px-4 pb-4 border-b-2 border-dashed justify-center lg:hidden gap-4">
            <Link to="/catalog" className="flex-grow">
              <img className="h-8" src="/plugins/logo.png" alt="Humansoft logo" />
            </Link>
            <button onClick={() => globalDispatch.setMobile(false)}>
              <svg className="w-4 h-4 text-gray-700" viewBox="0 0 94.926 94.926" fill="currentColor">
                <path d="M55.931,47.463L94.306,9.09c0.826-0.827,0.826-2.167,0-2.994L88.833,0.62C88.436,0.224,87.896,0,87.335,0
                  c-0.562,0-1.101,0.224-1.498,0.62L47.463,38.994L9.089,0.62c-0.795-0.795-2.202-0.794-2.995,0L0.622,6.096
                  c-0.827,0.827-0.827,2.167,0,2.994l38.374,38.373L0.622,85.836c-0.827,0.827-0.827,2.167,0,2.994l5.473,5.476
                  c0.397,0.396,0.936,0.62,1.498,0.62s1.1-0.224,1.497-0.62l38.374-38.374l38.374,38.374c0.397,0.396,0.937,0.62,1.498,0.62
                  s1.101-0.224,1.498-0.62l5.473-5.476c0.826-0.827,0.826-2.167,0-2.994L55.931,47.463z"/>
              </svg>
            </button>
          </div>
          {/* {showSearch && globalState.mobile && (
            <div className="block px-4 pb-4 pt-4">
              <Search mode="small" />
            </div>
          )} */}
          <div className="block md:hidden">
            <MenuButton catalog={true} />
          </div>
          {children}
        </aside>
      </div>
    </div>
  );
};

export default CatalogMenuSide;