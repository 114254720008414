import styled from 'styled-components';

import ExitIcon from './exiticon';
import IconButton from './iconbutton';

// interface Props {
//   children: React.ReactNode;
//   open: boolean;
//   onClose: Function;
// }

const DivModal = styled.div`
  z-index:1850; /* prevent jqwidgets overlay*/
`;

export default function Dialog(props) {
  const { dialogKey, open, onClose, maxWidth } = props;
  const bgColor = props?.bgColor || "bg-white";
  const iconColor = props?.iconColor || "text-gray";

  if (!open) {
    return <></>;
  }

  return (
    <DivModal className="fixed inset-0 z-50 flex overflow-auto transition bg-gray-800 bg-opacity-50 modal-bg" data-key={dialogKey}>
      <div className={`relative w-full m-auto flex-col flex rounded-lg shadow transition ${bgColor} ${maxWidth || "max-w-md"}`}>
        <div>{props.children}</div>
        <span className="absolute top-0 right-0 p-4">
          <IconButton onClick={() => onClose()}>
            <ExitIcon color={iconColor} />
          </IconButton>
        </span>
      </div>
    </DivModal>
  );
}
