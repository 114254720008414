import { useState, useRef } from 'react';
import styled from 'styled-components';
import { numberFormatCurrency } from './util/index';

const InputStyle = styled.input`
  &.input-alert:focus {
    border-color: rgba(220, 38, 38);
    --tw-ring-color: rgba(220, 38, 38);
  }
`;

const InputPrice = ({ className = '', defaultValue = 0, onChange = () => {}, tabIndex = 0 }) => {
  const [alert, setAlert] = useState(false);
  const refInput = useRef();

  const getPrice = () => {
    if (!refInput.current) {
      return 0;
    }

    let priceP = parseFloat(String(refInput.current.value).replaceAll(" ", "").replace(",", ".").replace(/[^\d.]/g, "").trim() || 0);

    if (Number.isNaN(priceP) || priceP == "NaN") priceP = defaultValue;

    const price = priceP;

    const selectionStart = refInput.current.selectionStart;
    refInput.current.value = numberFormatCurrency(Math.abs(price)).replace("zł", "").trim();
    refInput.current.setSelectionRange(selectionStart, selectionStart);

    return price;
  };

  const onChangePrice = (e) => {
    const price = getPrice();

    setAlert(false);
    if (price <=0) {
      setAlert(true);
    }

    typeof onChange == "function" && onChange(price);
  }

  return (
    <div className="flex flex-row gap-2 items-center">
      <div className="relative flex flex-row">
        <InputStyle
          ref={refInput}
          className={`w-40 text-sm text-right text-gray-600 bg-white border border-gray-300 rounded outline-none pr-11 
          focus:outline-none focus:shadow-none ring-opacity-0 ring-0 focus:ring-1 
            ${className} ${alert ? "input-alert border-red-600" : "" }`}
          onChange={onChangePrice}
          type="text"
          defaultValue={numberFormatCurrency(defaultValue * (/* quantity?.convert || */ 1)).replace("zł", "").trim()}
          tabIndex={tabIndex}
          // autoFocus={true}
          // ref={input => input && setTimeout(() => input.focus(), 10)}
        />
        <div className='absolute right-0 px-3 mr-px mt-px text-sm leading-9 text-center align-middle bg-gray-100 rounded rounded-l-none
          ring-opacity-0 ring-0 focus:ring-1 h-9'
        >
          {/* {dataPrice?.currency.translateCurrency()} */}zł
        </div>
      </div>
    </div>
  );
};

export default InputPrice;
