import { useEffect, useState, useRef } from 'react';

const LazyElement = ({ 
  handlerLoad = (isVisible) => { 
    console.log('LazyElement: handlerload func', isVisible);
  }
}) => {
  const refElem = useRef();
  const [isVisible, setIsVisible] = useState(false);
  // const refVisible = useRef();
  // refVisible.current = isVisible;
  let timeout = null;

  useEffect(() => {
    // console.log('infinityScroll: visible1', isVisible);
    if (isVisible) {
      clearTimeout(timeout);
      timeout = setTimeout(() => handlerLoad(isVisible), 10);
    }
    return () => {
      if (timeout != null) clearTimeout(timeout);
      // refVisible.current = false;
    };
  }, [isVisible]);

  useEffect(() => {
    let observer;
    let didCancel = false;

    // console.log('Intersection refElem', refElem.current);

    if (refElem.current === undefined) return;

    if (IntersectionObserver) {
      console.log('Intersection on');
      observer = new IntersectionObserver(
        ([entry]) => {
          // console.log('Intersection visible', didCancel, entry.intersectionRatio, entry.isIntersecting);
          if (didCancel) return;
          if (entry.intersectionRatio > 0 || entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        },
        {
          threshold: 0.01,
          rootMargin: '20%',
        },
      );

      if (refElem.current) observer.observe(refElem.current);
    } else {
      handlerLoad(isVisible);
    }

    return (() => {
      console.log('infinityScroll: cancel');
      didCancel = true;

      if (observer && observer.unobserve && refElem.current) {
        observer.unobserve(refElem.current);
        observer = null;
        refElem.current = null;
      }
    });
  }, [refElem]);

  return (
    <div ref={refElem} />
  );
};

export default LazyElement;
