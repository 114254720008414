import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { GlobalStateContext } from '../context/global';

const MenuButton = ({ catalog = false }) => {
  const globalState = useContext(GlobalStateContext);
  const [expand, setExpand] = useState(true);
  const [url, setUrl] = useState('');

  const handleExpand = () => {
    setExpand(!expand);
  };

  useEffect(() => {
    // console.log("SET URL", window.location.pathname);

    setUrl(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <>
      {!catalog && typeof globalState.user?.modules == 'object' && (globalState.user?.modules[0] == 1 || globalState.user?.modules[1] == 1) && (
        <>
          <button
            type="button"
            className="w-full flex justify-between items-center py-3 px-4 pr-6 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-gray-700 focus:outline-none"
            onClick={handleExpand}
          >
            <span className="flex items-center">
              <svg className="w-4 h-4" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="m484.86 0h-213.86v48.71c0 8.28-6.72 15-15 15s-15-6.72-15-15v-48.71h-213.86c-8.28 0-15 6.72-15 15v482c0 8.28 6.72 15 15 15h213.86v-48.71c0-8.28 6.72-15 15-15s15 6.72 15 15v48.71h213.86c8.28 0 15-6.72 15-15v-482c0-8.28-6.72-15-15-15zm-323.41 385.43c8.28 0 15 6.72 15 15s-6.72 15-15 15h-69.76c-8.28 0-15-6.72-15-15s6.72-15 15-15h19.88v-82.51c-30.969-6.455-52.201-35.7-49.05-67.49l12.37-125.33c.76-7.68 7.21-13.53 14.93-13.53h73.5c7.72 0 14.17 5.85 14.93 13.53l12.37 125.33c3.26 32.895-19.177 61.264-49.05 67.49v82.51zm109.55 17.86c0 8.29-6.72 15-15 15s-15-6.71-15-15v-28.57c0-8.28 6.72-15 15-15s15 6.72 15 15zm0-88.87c0 8.28-6.72 15-15 15s-15-6.72-15-15v-28.57c0-8.29 6.72-15 15-15s15 6.71 15 15zm0-88.57c0 8.28-6.72 15-15 15s-15-6.72-15-15v-28.57c0-8.29 6.72-15 15-15s15 6.71 15 15zm0-88.57c0 8.28-6.72 15-15 15s-15-6.72-15-15v-28.57c0-8.29 6.72-15 15-15s15 6.71 15 15zm168.86 278.15h-108.86c-8.28 0-15-6.72-15-15s6.72-15 15-15h108.86c8.28 0 15 6.72 15 15s-6.72 15-15 15zm0-60h-108.86c-8.28 0-15-6.72-15-15s6.72-15 15-15h108.86c8.28 0 15 6.72 15 15s-6.72 15-15 15zm0-60h-108.86c-8.28 0-15-6.72-15-15s6.72-15 15-15h108.86c8.28 0 15 6.72 15 15s-6.72 15-15 15zm15-75c0 8.28-6.72 15-15 15h-108.86c-8.28 0-15-6.72-15-15v-108.86c0-8.28 6.72-15 15-15h108.86c8.28 0 15 6.72 15 15z" />
                <path d="m346 126.57h78.86v78.86h-78.86z" />
                <path d="m160.77 238.38-11.04-111.81h-46.32l-11.04 111.81c-1.739 17.526 10.642 35.94 34.2 35.94 23.493 0 35.944-18.362 34.2-35.94z" />
              </svg>
              <span className="mx-4 font-medium">Menu</span>
            </span>
            <span className="mr-1">
              <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className={`${!expand ? '' : 'hidden'}`} d="M9 5L16 12L9 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path className={`${expand ? '' : 'hidden'}`} d="M19 9L12 16L5 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </span>
          </button>
          <nav className={`flex flex-col ${expand ? '' : 'hidden'} px-4 pb-2 text-sm`}>
            <ul className="text-gray-700">
              {globalState.user?.modules[0] == 1 && (
                <li className={`${url.includes('/article') ? 'text-primary' : ''}`}>
                  <span className="text-lg pl-1">-</span>
                  <Link to="/article" className="pl-5 py-1 text-base font-medium hover:text-primary">Zakupy</Link>
                </li>
              )}
              {globalState.user?.modules[1] == 1 && (
                <li className={`${url.includes('/client') ? 'text-primary' : ''}`}>
                  <span className="text-lg pl-1">-</span>
                  <Link to="/client" className="pl-5 py-1 text-base font-medium hover:text-primary">Strefa klienta</Link>
                </li>
              )}
            </ul>
          </nav>
        </>
      )}
    </>
  );
};

export default MenuButton;
