const SDragable = (table, onUpdate = () => {}) => {  
  // console.log("SDragable", "START");

  let draggingElement;
  let draggingColumnIndex;
  let isDraggingStarted = false;

  let x = 0;
  let y = 0;
  let width = 0;
  let height = 0;
  let posElemDrag = 0;
  let posElemDrop = 0;

  const cloneHeader = function () {
    const cellsTh = table.querySelectorAll('thead > tr th.drag');
    if (cellsTh.length == 0) return false;

    const dragElement = [...cellsTh].find(el => +el.getAttribute("tabindex") == draggingColumnIndex);
    if (typeof dragElement == "undefined") return false;

    const list = document.createElement('div');

    list.style.position = 'absolute';
    list.style.left = `0`;
    list.style.top = `0`;
    list.style.width = `${width}px`;
    list.style.height = `${height}px`;
    list.innerText = dragElement.innerText;
    list.setAttribute('class', dragElement.getAttribute('class'));
    list.classList.add('clone-list');

    table.parentNode.insertBefore(list, table);

    return list;
  };

  const mouseDownHandler = function (e) {
    const columnIndex = [].slice.call(table.querySelectorAll('thead > tr:first-child th')).indexOf(e.currentTarget);

    // console.log("COLUMNINDEX", columnIndex);
    if (columnIndex == undefined) return;


    const findElement = table.querySelectorAll('thead > tr:first-child th')[columnIndex];
    draggingColumnIndex = parseInt(findElement.getAttribute("tabindex"), 10);
    posElemDrag = findElement.getBoundingClientRect().left;

    // console.log("TABINDEX", draggingColumnIndex)

    x = e.clientX - e.currentTarget.offsetLeft;
    y = e.clientY - e.currentTarget.offsetTop;
    width = e.currentTarget.clientWidth;
    height = e.currentTarget.clientHeight;

    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const mouseMoveHandler = function (e) {
    if (!isDraggingStarted) {
      isDraggingStarted = true;

      draggingElement = cloneHeader();
      if (draggingElement === false) return;

      draggingElement.classList.add('dragging');
    }

    if (draggingElement === false) return;

    draggingElement.style.top = `${draggingElement.offsetTop + e.clientY - y}px`;
    draggingElement.style.left = `${draggingElement.offsetLeft + e.clientX - x}px`;

    x = e.clientX;
    y = e.clientY;
  };

  const tableHeaderListener = function() {
    table.querySelectorAll('thead > tr:first-child th.drag').forEach(function (headerCell) {
      // headerCell.classList.add('draggable');
      headerCell.removeEventListener('mousedown', mouseDownHandler);
      headerCell.addEventListener('mousedown', mouseDownHandler);
    });
  };

  const mouseUpHandler = function (e) {
    if (isDraggingStarted == false) {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
      return;
    }

    if (draggingElement === false) return;

    draggingElement.remove();
    isDraggingStarted = false;

    let endColumnIndex = -1;

    table.querySelectorAll('thead > tr:first-child th').forEach(function (row) {
      const clientRect = row.getBoundingClientRect();
      const xStart = parseInt(clientRect.left);
      const xEnd = parseInt(clientRect.left + clientRect.width);

      // console.log("xPosition", xStart, xEnd, e.clientX);

      if (e.clientX > xStart && e.clientX < xEnd) {
        if (row.classList.contains("drag")) {
          endColumnIndex = parseInt(row.getAttribute("tabindex"), 10);
          posElemDrop = row.getBoundingClientRect().left;
        }
      }
    });

    if (endColumnIndex == -1 || endColumnIndex == draggingColumnIndex) {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
      return;
    }

    table.querySelectorAll('tr:not(.expandable)').forEach(function (row, index) {
      const cellsTh = [].slice.call(row.querySelectorAll('th, td'));

      const dragElement = cellsTh.find(el => +el.getAttribute("tabindex") == draggingColumnIndex);
      const dropElement = cellsTh.find(el => +el.getAttribute("tabindex") == endColumnIndex);

      if (typeof dragElement == "undefined") return;
      if (typeof dropElement == "undefined") return;

      const parentNodeTh = dragElement?.parentNode;
    
      if (!parentNodeTh) return;
      
      if (posElemDrop > posElemDrag) {
        const nextSiblingTh = dropElement?.nextSibling;
        if (!nextSiblingTh) return;
        
        parentNodeTh.insertBefore(dragElement, nextSiblingTh);
      } else {
        parentNodeTh.insertBefore(dragElement, dropElement);
      }
    });

    // save column index
    typeof onUpdate == "function" && onUpdate();

    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    tableHeaderListener();
  };

  tableHeaderListener();
};

export default SDragable;
