import { useState, useRef, useEffect } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";

import ForwardDialog from '../../components/modal/forward';
import Portal from '../../components/context/portal';
import api from '../../components/service/api';
import AlertDialog from '../../components/modal/AlertDialog';
import { numberFormatCurrency, numberFormatQuantity } from '../../components/util';
// import { numberFormatCurrency, parseJSON } from '../../components/util/index';

import styled from 'styled-components';
import { IconAlert } from '../../components/icon';
import { debounce } from '../../components/util/index';
import { useTranslation } from 'react-i18next';

import SelectCountry from '../../components/country/SelectCountry';

const Form = styled.form`
  display:flex;
  width:100%;
  flex-direction: column;
  gap:8px;

  & > input[type="submit"] {
    // visibility:hidden;
    height:0;
  }

  & > div.err {
    margin-top:-8px;
  }

  & > input + div {
    margin-top:-8px;
  }

  & > div {
    display:flex;
    width:100%;
    gap:8px;
    row-gap:0;
    align-items:center;
    flex-wrap:wrap;
  }
  
  & > div label {
    font-size:0.90rem;
    text-align:right;
    width:170px;
  }

  & > div label sup:before {
    content:"*";
    color:red;
    margin-right:2px;
  }

  @media screen and (max-width:480px) {
    & > div label {
      width:100%;
      text-align:left;
    }

    & > div.err label {
      display:none;
    }
  }

  & > div input {
    min-width:100%;
  }

  & > div > label + div {
    display:flex;
    flex-wrap: wrap;
    flex-grow:2;
    gap:8px;
    align-items:center;
    // width:auto;
  }

  & > div.err > label + div {
    flex-direction: column;
    align-items:flex-start;
  }

  & > div.nr input {
    min-width:auto;
    max-width:80px;
  }

  & > div.nr input ~ input {
    max-width:60px;
  }

  & > div.kod input {
    min-width:auto;
    max-width:90px;
  }

  & > div.kod input ~ div input {
    min-width:auto;
    max-width:154px;
  }

  & > div input ~ span {
    min-width:8px;
    text-align:center;
  }
`;

const P = styled.p`
  display:flex;
  align-items:center;
  flex-direction:row;
  gap:4px;
  font-size:0.8rem;
  padding-top:1px;

  & > span {
    margin-top:-1px;
  }
`;

type IDeliveryList = {
  name: string, 
  email: string, 
  phone: string, 
  postalcode: string,
  city: string, 
  address: string 
  homeno: string, 
  localno: string, 
  country: string,
};

const getCityName = async (postalcode) => {
  const resp = await api.getCityName(postalcode);
  return resp?.data;
};

const ValidDelivery = (
    { title = 'Edycja adresu dostawy', list = [], onClose = function(e: Event){ }, onConfirm = function(data: IDeliveryList){ } } 
    : 
    { title:string, list : Array<IDeliveryList>, onClose : Function, onConfirm : Function } 
) => {
  const { t } = useTranslation("dialog");
  const { register,  formState: { errors }, handleSubmit, setValue } = useForm<IDeliveryList>();
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingCity, setLoadingCity] = useState(false);

  const refForm = useRef();

  const onSubmit: SubmitHandler<IDeliveryList> = (data) => {
    console.log("FORM", data);

    if (loadingConfirm == true) return false;

    // setLoadingConfirm(true);
    // setLoadingConfirm(false);

    onConfirm(data);
    return false;
  }

  const confirmModal = (e) => {
    if (!refForm?.current) return;
    
    // refForm.current.dispatchEvent(new Event('submit', { cancelable: true }))
    refForm.current.elements[0].click();
  };

  const cancelModal = (e) => {
    onClose(e);
  };

  const debouncePC = debounce((postalcode) => {
    getCityName(postalcode)
      .then(data => {
        if (data.status != 0) {
          return false;
        }

        const city = data.dane;
        
        if (refForm.current) {
          setValue('city', city, { shouldValidate: true })
        }
      }).finally(() => {
        setLoadingCity(false);
      });
  }, 500);

  const changePostalCode = (e) => {
    let postalcode = `${e.target.value}`;

    if (postalcode.match(/^[0-9]{2}-[0-9]{3}$/g) !== null) {
      setLoadingCity(true);
      debouncePC(postalcode);
    }

    return false;
  };

  return (
    <ForwardDialog
      title={t(title)}
      open
      onClose={cancelModal}
      onGoTo={confirmModal}
      textGoTo={(
        <div className="flex flex-row">
          <svg className={`${loadingConfirm ? '' : 'hidden'} animate-spin mr-3 h-5 w-5 text-white`} fill="none" viewBox="0 0 24 24">
            <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
          <span>{t('Potwierdź')}</span>
        </div>
      )}
      textCancel={(
        <div className="flex flex-row">
          <svg className={`${loadingCancel ? '' : 'hidden'} animate-spin mr-3 h-5 w-5 text-white`} fill="none" viewBox="0 0 24 24">
            <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
          <span>{t('Anuluj')}</span>
        </div>
      )}
    >
      <>
        <Form onSubmit={handleSubmit(onSubmit)} method="POST" ref={refForm}>
          <input type="submit" />
          <div>
            <label><sup />{t('Nazwa')}:</label>
            <div>
              <input
                type="text"
                maxLength={50}
                className={`w-full text-sm text-gray-600 bg-white border border-gray-300 rounded outline-none md:w-auto focus:outline-none 
                  focus:shadow-none ring-opacity-0 ring-0 focus:ring-1`} 
                {...register("name", { required: true, maxLength: 50, pattern: /^[\wzżźćńółęąśŻŹĆĄŚĘŁÓŃ -]+$/i  })} 
              />
            </div>
          </div>
          {errors.name?.type === 'required' && (
            <div className="err">
              <label>&nbsp;</label>
              <div>
                <P role="alert">
                  <IconAlert color="red" />
                  <span className="text-red-500">{t('Nazwa adresu jest wymagana')}</span>
                </P>
              </div>
            </div>
          )}
          {errors.name?.type === 'pattern' && (
            <div className="err">
              <label>&nbsp;</label>
              <div>
                <P role="alert">
                  <IconAlert color="red" />
                  <span className="text-red-500">{t('Nieprawidłowa nazwa adresu (niedozwolone znaki)')}</span>
                </P>
              </div>
            </div>
          )}
          {errors.name?.type === 'maxLength' && (
            <div className="err">
              <label>&nbsp;</label>
              <div>
                <P role="alert">
                  <IconAlert color="red" />
                  <span className="text-red-500">{t('Zbyt długa nazwa (max. 50 znaków)')}</span>
                </P>
              </div>
            </div>
          )}
          <div>
            <label><sup />{t('Kraj')}:</label> 
            <div>
              <SelectCountry 
                defaultValue="pl"
                className="max-w-xl" 
                onChange={(country) => setValue('country', country, { shouldValidate: true })}
                register={register("country", { required: true })}
              />
            </div>
          </div>
          {errors.country?.type === 'required' && (
            <div className="err">
              <label>&nbsp;</label>
              <div>
                <P role="alert">
                  <IconAlert color="red" />
                  <span className="text-red-500">{t('Kraj jest wymagany')}</span>
                </P>
              </div>
            </div>
          )}
          <div>
            <label><sup />{t('Ulica')}:</label> 
            <div>
              <input
                type="text"
                maxLength={80}
                className={`w-full text-sm text-gray-600 bg-white border border-gray-300 rounded outline-none md:w-auto focus:outline-none 
                  focus:shadow-none ring-opacity-0 ring-0 focus:ring-1`} 
                {...register("address", { required: true, maxLength:80, pattern: /^[A-Z0-9a-zżźćńółęąśŻŹĆĄŚĘŁÓŃ_ -\.]+$/i })}
              />
            </div>
          </div>
          {errors.address?.type === 'required' && (
            <div className="err">
              <label>&nbsp;</label>
              <div>
                <P role="alert">
                  <IconAlert color="red" />
                  <span className="text-red-500">{t('Ulica jest wymagana')}</span>
                </P>
              </div>
            </div>
          )}
          {errors.address?.type === 'pattern' && (
            <div className="err">
              <label>&nbsp;</label>
              <div>
                <P role="alert">
                  <IconAlert color="red" />
                  <span className="text-red-500">{t('Nieprawidłowa nazwa ulicy')}</span>
                </P>
              </div>
            </div>
          )}
          {errors.address?.type === 'maxLength' && (
            <div className="err">
              <label>&nbsp;</label>
              <div>
                <P role="alert">
                  <IconAlert color="red" />
                  <span className="text-red-500">{t('Zbyt długa nazwa ulicy')}</span>
                </P>
              </div>
            </div>
          )}
          <div className='nr'>
            <label>{t('Nr. domu / lokalu')}:</label>
            <div>
              <input
                type="text"
                maxLength={20}
                className={`w-full text-sm text-gray-600 bg-white border border-gray-300 rounded outline-none md:w-auto focus:outline-none 
                  focus:shadow-none ring-opacity-0 ring-0 focus:ring-1`} 
                  {...register("homeno", { maxLength: 20, pattern: /^[A-Za-z0-9]+$/i })} 
              />
              <span>/</span>
              <input
                type="text"
                maxLength={10}
                className={`w-full text-sm text-gray-600 bg-white border border-gray-300 rounded outline-none md:w-auto focus:outline-none 
                  focus:shadow-none ring-opacity-0 ring-0 focus:ring-1`} 
                {...register("localno", { maxLength: 10, pattern: /^[A-Za-z0-9]+$/i })} 
              />
            </div>
          </div>
          <div className='kod'>
            <label><sup />{t('Kod pocztowy')}:</label> 
            <div>
              <input
                type="text"
                className={`w-full text-sm text-gray-600 bg-white border border-gray-300 rounded outline-none md:w-auto focus:outline-none 
                  focus:shadow-none ring-opacity-0 ring-0 focus:ring-1`} 
                {...register("postalcode", { required:true, pattern: /^[-\d]{4,8}$/, maxLength:8 })}
                onChange={changePostalCode}
                maxLength={8}
                placeholder=""
              />
            </div>
          </div>
          {(errors.postalcode?.type === 'required' || errors.postalcode?.type === 'pattern') && (
            <div className='err'>
              <label>&nbsp;</label> 
              <div>
              {errors.postalcode?.type === 'pattern' && (
                  <P role="alert">
                    <IconAlert color="red" />
                    <span className="text-red-500">{t('Kod pocztowy jest nieprawidłowy')}</span>
                  </P>
                )}
                {errors.postalcode?.type === 'required' && (
                  <P role="alert">
                    <IconAlert color="red" />
                    <span className="text-red-500">{t('Kod pocztowy jest wymagany')}</span>
                  </P>
                )}
              </div>
            </div>
          )}
          <div>
            <label><sup />{t('Miasto')}:</label> 
            <div className='relative'>
              <input
                type="text"
                maxLength={35}
                className={`w-full text-sm text-gray-600 bg-white border border-gray-300 rounded outline-none md:w-auto focus:outline-none 
                  focus:shadow-none ring-opacity-0 ring-0 focus:ring-1`} 
                {...register("city", { required: true, maxLength: 35 })}
              />
              <button 
                className={`${loadingCity ? '' : 'hidden'} text-gray-500 bg-white absolute mt-2 mr-2 hover:cursor-pointer`}
                style={{ top: "1px", right: "1px"}}
              >
                <svg className='w-10 h-5' viewBox="0 0 20 20" preserveAspectRatio='xMinYMid'>
                  <g transform="translate(10 10)">
                    <circle cx="0" cy="0" r="4" fill="var(--primary)">
                      <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                    </circle>
                  </g>
                  <g transform="translate(22 10)">
                    <circle cx="0" cy="0" r="4" fill="var(--primary)">
                      <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                    </circle>
                  </g>
                    <g transform="translate(34 10)">
                    <circle cx="0" cy="0" r="4" fill="var(--primary)">
                      <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                    </circle>
                  </g>
                </svg>
              </button>
            </div>
          </div>
          {(errors.city?.type === 'required') && (
            <div className='err'>
              <label>&nbsp;</label> 
              <div>
                <P role="alert">
                  <IconAlert color="red" />
                  <span className="text-red-500">{t('Miasto jest wymagane')}</span>
                </P>
              </div>
            </div>
          )}
          {(errors.city?.type === 'maxLength') && (
            <div className='err'>
              <label>&nbsp;</label> 
              <div>
                <P role="alert">
                  <IconAlert color="red" />
                  <span className="text-red-500">{t('Zbyt długa nazwa miasta')}</span>
                </P>
              </div>
            </div>
          )}
        </Form>
      </>
    </ForwardDialog>
  );
};

export default ValidDelivery;
