import { useState, useRef, useEffect } from 'react';

const SelectMulti = ({ 
  defaultValue = [], 
  definitionKey = "id",
  list = ({ wartosc: "", id: 0 /* */ }), 
  handleUpdate = (data) => {} 
}) => {
  const [selected, setSelected] = useState(defaultValue || []);
  const [isOpen, setIsOpen] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const refList = useRef();

  const onShowList = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsOpen(!isOpen);
    showInput === true && setShowInput(false);

    setTimeout(() => {
      refList.current.focus();
    }, 100);
  };

  const onHideList = () => {
    setIsOpen(false);
    showInput === true && setShowInput(false);
  };

  const onSelect = (e, el) => {
    e.preventDefault();
    e.stopPropagation();
    
    const key = el[definitionKey || "id"];
    const checkbox = e.currentTarget.querySelector("input[type='checkbox']");
    const checked = !selected.some(el => el == key);
    checkbox.checked = checked;

    const selectedTmp = checked ? [...new Set([...selected, key])] : selected.filter(el => el != key && el != "");

    setSelected(selectedTmp);
    handleUpdate(selectedTmp); // sort for compare objects

    return false;
  };

  const onClear = (e) => {
    e.preventDefault();
    e.stopPropagation();
   

    const checkboxList = refList.current.querySelectorAll("input[type='checkbox']");
    checkboxList.length > 0 && checkboxList.forEach(el => el.checked = false);

    setSelected([]);
    handleUpdate([]);

    return true;
  }

  useEffect(() => {
    if (selected.length > 0) {
      const checkboxList = refList.current.querySelectorAll("input[type='checkbox']");
      checkboxList.length > 0 && checkboxList.forEach(el => el.checked = selected.some(sel => sel == +el.dataset.id));
    }
  }, []);

  return (
    <>
      <div className={`relative max-w-sm`}>
        <button
          type="button"
          // aria-haspopup="listbox"
          // aria-labelledby="listbox-label"
          className={`relative flex flex-row items-center w-full pr-9 text-left bg-white border border-gray-300 rounded shadow-sm cursor-default  
            sm:text-sm ${isOpen && 'border-primary ring-1 ring-primary overflow'}`}
          onMouseDown={onShowList}
        >
          <span className="relative z-0 flex items-center w-full pl-3" style={{ minHeight: '1.75rem' }}>
            {selected.length == 0 && (list[0].id == 0 ? list[0].wartosc : '------')}
            {selected.length > 0 && list.filter(el => selected.some(sel => sel == el.id)).map(el => el.wartosc).join(", ")}
          </span>
          <a
            className={`relative left-1 -mr-1 ${selected.length > 0 ? "" : "hidden"} text-gray-500 hover:text-primary`}
            title="wyczyść"
            href={void(0)}
            onMouseDown={onClear}
          >
            <svg className="w-6 h-6" viewBox="-330 -350 1020 1020" fill="currentColor">
              <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 
                0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 
                113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 
                113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
            </svg>
          </a>
          <p className={`absolute inset-y-0 right-0 flex items-center pl-2 pr-3 z-10`}>
            {/* <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" clipRule="evenodd" d="M10 3a1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" />
            </svg> */}
            <svg className="w-3 h-3 text-gray-500" viewBox="-5 -6 38 38" fill="currentColor">
              <path d="M29.994,10.183L15.363,24.812L0.733,10.184c-0.977-0.978-0.977-2.561,0-3.536c0.977-0.977,2.559-0.976,3.536,0 l11.095,11.093L26.461,6.647c0.977-0.976,2.559-0.976,3.535,0C30.971,7.624,30.971,9.206,29.994,10.183z"/>
            </svg>
          </p>
        </button>
        <div
          tabIndex={-1}
          ref={refList}
          onBlur={onHideList}
          className={`absolute z-20 transition-opacity mt-1 right-0 rounded bg-white shadow-lg outline-none ${isOpen ? 'opacity-100 visible' : 'invisible opacity-0'}`}
        >
          <ul
            role="listbox"
            // aria-labelledby="state-cart-label"
            // aria-activedescendant="state-cart-0"
            className="py-1 overflow-auto text-sm rounded-md max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {list.filter(el => el.id > 0).map(el => (
              <li
                key={`prop_selectmulti_choose_${el.id}`}
                role="option"
                className="relative text-gray-700 cursor-default select-none hover:bg-primary hover:text-white"
              >
                <button
                  type="button"
                  onMouseDownCapture={(e) => onSelect(e, el)}
                  className="w-full px-3 py-2 outline-none"
                >
                  <div className="flex items-center content-center">
                    {/* <img className="flex-shrink-0 w-5 h-5 rounded-full" src="" alt="" /> */}
                    <div className="relative">
                      <input
                        type="checkbox"
                        className='mr-2 border border-gray-400 rounded'
                        data-id={el.id}
                        defaultChecked={!!selected[el.id]}
                        readOnly={true}
                      />
                      <div className='absolute top-0 left-0 w-full h-full bg-transparent'></div>
                    </div>
                    <span className="block -mt-px text-sm font-normal truncate">
                      {el.wartosc} 
                    </span>
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SelectMulti;
