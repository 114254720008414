import { useState, useRef } from 'react';
import useTransCustom from '../hook/transition';

const PageNum = ({ pageMax, handleChange }) => {
  const [,startTrans] = useTransCustom(3000);
  const [isOpen, setIsOpen] = useState(false);
  const refButton = useRef();

  const changeMaxPage = (e, caption = "12") => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(false);

    refButton.current.children[0].innerHTML = caption;
    typeof handleChange === 'function' && startTrans(handleChange(parseInt(caption)));
  };

  const blurList = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  return (
    <>
      <div className="flex-start md:flex-end">
        <div className="relative hidden text-left xm:inline-block">
          <div>
            <span className="rounded shadow-sm">
              <button
                ref={refButton}
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                onBlur={blurList}
                className="inline-flex justify-center w-full px-3 py-2 pr-8 text-sm font-normal leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary active:bg-gray-50 active:text-gray-800"
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <p>{pageMax}</p>
                <span className="absolute inset-y-0 flex items-center pr-2 ml-3 right-1 z-1">
                  {/* <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="1 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="m6.293,7.90276a1,1 0 0 1 1.414,0l2.293,2.293l2.293,-2.293a1,1 0 0 1 1.414,1.414l-3,3a1,1 0 0 1 -1.414,0l-3,-3a1,1 0 0 1 0,-1.414z" />
                  </svg> */}
                  <svg className="w-3 h-3 text-gray-500" viewBox="-5 -6 38 38" fill="currentColor">
                    <path d="M29.994,10.183L15.363,24.812L0.733,10.184c-0.977-0.978-0.977-2.561,0-3.536c0.977-0.977,2.559-0.976,3.536,0 l11.095,
                      11.093L26.461,6.647c0.977-0.976,2.559-0.976,3.535,0C30.971,7.624,30.971,9.206,29.994,10.183z"
                    />
                  </svg>
                </span>
              </button>
            </span>
          </div>
          <div
            tabIndex={-1}
            // ref={refList}
            // onBlur={blurList}
            className={`absolute z-30 bottom-10 transition-opacity w-auto mb-1 right-0 origin-bottom-right rounded-md bg-white shadow-lg outline-none ${isOpen ? 'opacity-100 visible' : 'invisible opacity-0'}`}
          >
            <div className="py-1 overflow-auto text-sm bg-white rounded-md shadow-xs ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <button
                  type="button"
                  onMouseDown={(e) => changeMaxPage(e, "12")}
                  className="block py-2 pl-4 pr-8 text-sm leading-5 text-gray-700 hover:bg-primary hover:text-white focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                >12</button>
                <button
                  type="button"
                  onMouseDown={(e) => changeMaxPage(e, "20")}
                  className="block py-2 pl-4 pr-8 text-sm leading-5 text-gray-700 hover:bg-primary hover:text-white focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                >20</button>
                <button
                  type="button"
                  onMouseDown={(e) => changeMaxPage(e, "50")}
                  className="block py-2 pl-4 pr-8 text-sm leading-5 text-gray-700 hover:bg-primary hover:text-white focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                >50</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNum;
