import { useEffect, useState } from 'react';
import styled from 'styled-components';


const Ul = styled.ul`
  display:table;
  border-collapse: separate;
  border-spacing: 4px;
`;

const Li = styled.li`
  display:table-row;

  & span {
    display:table-cell;
    background-color: #F2F2F2;
    font-size:0.9em;
  }
  & span:first-letter {
    text-transform: uppercase;
  }
  &:nth-child(2n+1) span,
  &:nth-child(2n+1) div {
    background-color: #F8F8F8;
  }
`;


const FeatureList = ({ list, handleCount = (count) => {} }) => {
  if (list == undefined || list.length == 0) return "";

  const [featureList , setFeatureList] = useState([]);

  useEffect(() => {
    const featureTmp = [];
    
    list.forEach(el => {
      featureTmp.push({key: el.id_dkol, name: el.opis_c, values: Array.isArray(el.value)? [...el.value] : [el.value]});
    });
      
    setFeatureList([...featureTmp]);
    typeof handleCount == "function" && handleCount(featureTmp.length);
  }, []);

  return (
    <Ul className="list-disc marker:text-primary">
      {featureList.map(feature => 
        <Li key={`feature_${feature.key}`}>
          <span className='text-right px-2 py-1'>
            {feature.name}
          </span>
          <span className="py-1 px-2">
            {feature.values && (feature.values.join(", "))}
          </span>
        </Li>
      )}
    </Ul>
  )
};

export default FeatureList;