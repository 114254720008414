import { useState } from "react";
import storageSession from '../util/storage';

const STORAGE_PREFIX = 'session_storage_';

const useSessionState = (key: string, initValue) => {
  const [session, setSession] = useState(() => {
    try {
      const value = storageSession.get(STORAGE_PREFIX + key);
      return value ? JSON.parse(value) : initValue;
    } catch(error) {
      console.log('useSessionState', error)
      return initValue;
    }
  });

  const setItem = (value) => {
    try {
      const storeValue = value instanceof Function ? value(session) : value;
      setSession(storeValue);
      storageSession.set(STORAGE_PREFIX + key, JSON.stringify(storeValue));
    } catch(error) {
      console.log("useSessionState", error);
    }
  }

  return [ session, setItem ];
};

export default useSessionState;