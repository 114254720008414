import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getDownloadFile } from "../../components/util";
import { sanitizeFilename, getCurrentDate } from '../../components/util';
import storage from '../../components/util/storage';
import api from '../../components/service/api';
import Tooltip from '../../components/tooltip';

const Button = styled.button`
  &:hover div.tooltip-hover {
    display:block;
  }
`;

const getArticlePdf = async (data) => {
  // console.log("ArticlePdf data", data);
  const resp = await api.getArticlePdf(data);
  return resp?.data;
};

const ArticlePdf = ({ category = [''], search = '', filter = {}, sort = '', available = false }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('cart');

  const onExport = (e) => {
    e.preventDefault();
    if (loading) return false;

    setLoading(true);

    const dataCategory = (category.length > 0 || category[0] != '') ? category : [''];
    const dataLang = storage.get('global-lang', 'pl');
    const dataFilter = filter != null && Object.keys(filter).length <= 0 ? null : filter;

    getArticlePdf({
      nodekeys: dataCategory,
      orderby: sort,
      filtr: search,
      filtr_cechy: dataFilter,
      available,
      lang: dataLang,
    })
      .then((data) => {
        // console.log("ArticlePdf", data);
        if (data?.status != 0) {
          setLoading(false);
          return;
        }

        const title = data?.info;
        const url = "data:application/pdf;base64," + data.dane;

        fetch(url)
          .then(res => res.blob())
          .then(blob => {
            const fileName = `b2b__${sanitizeFilename(title)}_${getCurrentDate()}.pdf`;
            getDownloadFile(blob, fileName);
          })
          .finally(() => {
            setLoading(false);
          });
      }).catch(() => {
        setLoading(false);
      });

    return false;
  };

  return (
    <Button
      type="button"
      className="relative flex p-2 px-3 text-sm border border-gray-300 rounded shadow-sm gap-x-2 leading-2 group border-1 hover:bg-gray-800 hover:text-white hover:border-transparent"
      onClick={onExport}
      data-cy="export-to-pdf-button"
      disabled={loading}
    >
      {loading && (
        // ICON LOADING CIRCLE
        <svg className="w-4 h-4 bg-transparent animate-spin" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
          <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
      )}
      {!loading && (
        // ICON PDF
        <svg className="w-4 h-4 text-gray-600 group-hover:text-white" viewBox="0 0 24 24" fill="currentColor" >
          <path d="M12.5535 16.5061C12.4114 16.6615 12.2106 16.75 12 16.75C11.7894 16.75 11.5886 16.6615 11.4465 16.5061L7.44648 12.1311C7.16698 11.8254 
            7.18822 11.351 7.49392 11.0715C7.79963 10.792 8.27402 10.8132 8.55352 11.1189L11.25 14.0682V3C11.25 2.58579 11.5858 2.25 12 2.25C12.4142 
            2.25 12.75 2.58579 12.75 3V14.0682L15.4465 11.1189C15.726 10.8132 16.2004 10.792 16.5061 11.0715C16.8118 11.351 16.833 11.8254 16.5535 
            12.1311L12.5535 16.5061Z"
          />
          <path d="M3.75 15C3.75 14.5858 3.41422 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15V15.0549C2.24998 16.4225 2.24996 17.5248 2.36652 
            18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6516C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.47522 21.75 7.57754 21.75 8.94513 
            21.75H15.0549C16.4225 21.75 17.5248 21.75 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6516C21.2536 20.0497 21.5125 19.2919 
            21.6335 18.3918C21.75 17.5248 21.75 16.4225 21.75 15.0549V15C21.75 14.5858 21.4142 14.25 21 14.25C20.5858 14.25 20.25 14.5858 20.25 15C20.25 
            16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 
            20.2484 16.4354 20.25 15 20.25H9C7.56459 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 
            3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15Z"
          />
        </svg>
      )}
      <span className="relative top-0 text-sm leading-4">{t('Eksportuj do PDF')}</span>
      <Tooltip className="absolute hidden -top-2 -right-2 tooltip-hover" content={t('Ograniczenie do\u00A0200 produktów')}>
        <div className="z-10 w-4 h-4 text-blue-500 hover:text-primary">
          <svg viewBox="0 0 512 512" fill='currentColor'>
            <circle cx="50%" cy="50%" r="50%" fill="white" />
            <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 
              90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 
              16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 
              33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 
              83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 
              46-46c0-25.365-20.635-46-46-46z"
            />
          </svg>
        </div>
      </Tooltip>
    </Button>
  );
};

export default ArticlePdf;
