import { useEffect, useRef, useCallback, useState, useContext, lazy } from 'react';
import { createPortal } from 'react-dom';
import ReactHtmlParser from 'react-html-parser';
import parser from 'bbcode-to-react';
import { Link } from 'react-router-dom';
// import Link from '../../components/link.js';

import api from '../../components/service/api.ts';
import { parseJSON } from '../../components/util/index';

import STable from '../../components/grid/stable';
import SColumn from '../../components/grid/scolumn';
// import SCheckbox from '../../components/grid/scheckbox';
import SRowDetails from '../../components/grid/srowdetails';
import SSelect from '../../components/grid/sselect';
import SPlaceholder from '../../components/grid/splaceholder';

import LazyImage from '../../components/hook/lazyimage';
import InputCounter from '../../components/hook/inputcounter';
import CartButton from '../../components/hook/cartbutton';
import FaviButton from '../../components/hook/favibutton';
import useCartAction from '../../components/hook/cartaction';

import Battery from '../../components/battery';
// import LabelTag from '../../components/labeltag';
import IconDiscount from '../../components/icon/IconDiscount';

import { numberFormatCurrency, numberFormatQuantity, roundTo } from '../../components/util/index';

import CartContext from '../../components/context/cart.ts';
import CartSelect from '../../components/cart/cartselect';
import PopTemplate from '../../components/poptemplate';

import useFileCache from '../../components/hook/useFileCache';

import ArticleGrid from './grid';
import DocumentList from '../../components/list/DocumentList';
import FeatureList from '../../components/list/FeatureList';

import { default as storage } from '../../components/util/storage'
import { GlobalStateContext } from '../../components/context/global';
import { useTranslation } from 'react-i18next';

const ArticleList = ({ loading = false, data = [], handleSort = strokeLinecapnull, handleUpdate = null, menuTreePath = null, noDetail = false, noHeader = false, noVariant = false, noDialog = false, noDrag = false, headerCartSelect = true, catalog = false
}) => {
  const globalState = useContext(GlobalStateContext);
  const [loadArticleImage] = useFileCache();
  const { t } = useTranslation("shop");
  const [showArticle, setShowArticle] = useState(false);

  const loadArticleShow = (symbol) => {
    setShowArticle(symbol);
  };

  const ArticleShowPopup = ({ symbol }) => {
    const container = document.getElementById("show-article");
    const Dialog = lazy(() => import('../../components/modal/dialog.js'));
    const ArticleShow = lazy(() => import('./show2'));

    const onCloseDialog = () => {
      // ReactDOM.unmountComponentAtNode(container);
      setShowArticle(false)
    };

    return createPortal(
      <Dialog
        open
        onClose={onCloseDialog}
        dialogKey={`show_article_${symbol}`}
        maxWidth="max-w-3xl"
      >
        <ArticleShow symbol={symbol} isPopup />
      </Dialog>, 
      container
    );
  };

  const labelHeaderTemplate = () => {
    if (data.length == 0 || data[0]?.label_name == undefined)
      return (<span>{t('Etykieta')}</span>);

    return (<span>{t(data[0].label_name)}</span>);
  };

  const articleTemplate = useCallback((item) => {
    const [showPopup, setShowPopup] = useState(false);
    const [refImage, setRefImage] = useState(null);

    return (
      <div className="flex flex-col items-center justify-center md:justify-start md:flex-row">
        <div
          ref={setRefImage}
          className="flex-shrink-0 w-10 h-10"
          onMouseEnter={() => setShowPopup(true)}
          onMouseLeave={() => setShowPopup(false)}
        >
          <Link to={`${catalog ? '/catalog/' : '/article/'}show/${encodeURIComponent(item.symbol_art)}`}>
            <LazyImage
              className="w-10 h-10 min-w-10"
              src=""
              symbol={item.symbol_art}
              width="40"
              height="40"
              alt=""
              handlerLoad={(symbol) => loadArticleImage(symbol)}
            />
          </Link>
        </div>
        <PopTemplate
          refElement={refImage}
          isOpen={showPopup}
          template={(
            <LazyImage
              className="w-40 h-40"
              src=""
              symbol={item.symbol_art}
              width="240"
              height="240"
              alt=""
              handlerLoad={(symbol) => loadArticleImage(symbol, 240)}
            />
          )}
        />
        <div className="text-center md:ml-4 md:text-left">
          <div className="text-sm font-medium leading-5 text-gray-900">
            {/* <LabelTag data={item.wyroznienia.find(Boolean)} /> */}
            {!globalState.user?.article_popup && (
              <Link to={`${catalog ? '/catalog/' : '/article/'}show/${encodeURIComponent(item.symbol_art)}`}>
                {parser.toReact(item.nazwa_art)}
              </Link>
            )}
            {globalState.user?.article_popup && (
              <a
                className='hover:cursor-pointer'
                href={void(0)}
                onClick={() => loadArticleShow(item.symbol_art)}
              >
                {parser.toReact(item.nazwa_art)}
              </a>
            )}
          </div>
          {/* <div className="text-sm leading-5 text-gray-500">
            {item.label}
          </div> */}
        </div>
      </div>
    );
  }, []);

  const headerCartTemplate = () => (
    <div className="inline-block">
      {!catalog ? <CartSelect styleSize="tiny" /> : ''}
    </div>
  );

  const headerExtraFieldTemplate = () => {
    if (data.length == 0 || data[0]?.extra_field_label == undefined) 
      return (<span>{t('Etykieta')}</span>);

    return (<span>{t(String(data[0].extra_field_label))}</span>);
  };

  const cartTemplate = useCallback((item) => {
    const defaultJM = item.jm_pom.sort((a, _) => a.podstawowa == 1 && a.visible == 1 ? -1 : 1).find(el => el.visible == 1);
    if (defaultJM === undefined) return ''; // prevent reload

    const [firstRender, setFirstRender] = useState(false);
    const basicJM = item.jm_pom.find(el => el.podstawowa === 1);

    const [state, cartAction] = useCartAction();
    const [quantity, setQuantity] = useState({ ...cartAction.getProduct({ item }) });
    const [count, setCount] = useState(cartAction.getProductConfirm(item));

    const globalStateQ = useContext(GlobalStateContext);
    const [quantityMax, setQuantityMax] = useState({ current: 0, actual: 0 });

    const changeFavorite = (isOff) => {
      cartAction.setFavorite(item, isOff);
    };

    useEffect(() => {
      // if (firstRender === false) {
      //   return false;
      // }
      // console.log('product Effect', quantity, item.symbol_art);
      cartAction.setProduct({ item, quantity }).then(() => {
        setQuantityMax(cartAction.getQuantityMax({
          item,
          maxActual: item.zapas.actual,
          jmCurrent: defaultJM.symbol_jmp,
          priorityMeasure: true,
          stock_unlimit: globalStateQ.user?.stock_unlimit,
        }));
        setCount(cartAction.getProductConfirm(item));
      });
    }, [quantity]);

    useEffect(() => {
      if (firstRender === false) {
        return false;
      }
      setCount(cartAction.getProductConfirm(item));
    }, [state.select]);

    useEffect(() => {
      if (firstRender === false) {
        setFirstRender(true);
        return false;
      }

      setQuantity({
        ...quantity,
        confirm: parseFloat(cartAction.getProduct({ item }).confirm),
        convert: parseFloat(cartAction.getProduct({ item }).convert),
        precision: parseFloat(cartAction.getProduct({ item }).precision),
      });
    }, [state.measure[item.symbol_art], state.select]);

    return (
      <div className="flex flex-row justify-center gap-1 md:justify-end">
        <CartContext.Provider value={[quantity, setQuantity, state.loading, state.error, state.done]}>
          <InputCounter
            value={1}
            defValue={1}
            max={quantityMax.current}
            maxActual={quantityMax.actual}
            disable={item.isabstractmodel || item.cena == 0}
          />
          <div className="flex flex-row items-center">
            <div className="flex flex-col">
              <CartButton
                value={1}
                max={quantityMax.current}
                maxActual={quantityMax.actual}
                defaultClassName={`${count > 0 ? 'h-7' : 'h-9'}`}
                disable={item.isabstractmodel || item.cena == 0}
                noDialog={noDialog}
              />
              <span className={`${count > 0 ? '' : 'hidden'} flex-row block text-xs text-gray-400 leading-3 text-center`}>
                {count}&nbsp;{basicJM && basicJM?.symbol_jmp} 
              </span>
            </div>
            {globalStateQ.user?.favorite_display && (
              <FaviButton
                value={state.favorite[item.symbol_art]}
                onChange={changeFavorite}
                small
                defaultClassName={``} 
              />
            )}
          </div>
        </CartContext.Provider>
      </div>
    );
  }, []);

  const measureTemplate = (item) => {
    const defaultJM = item.jm_pom.sort((a, _) => a.podstawowa == 1 && a.visible == 1 ? -1 : 1).find(el => el.visible == 1);
    if (defaultJM === undefined) return ''; // prevent reload

    const [state, cartAction] = useCartAction();

    const jmList = item.jm_pom
      .filter(el => el.visible == 1)
      .map(el => ({ key: el.symbol_jmp, value: el.nazwa_jm, sort: el.podstawowa }))
      .sort((a, b) => a.sort + b.sort);

    const defaultJMP = item.jm_pom.find(el => el.symbol_jmp === state.measure[item.symbol_art]);

    const [selectedJM, setSelectedJM] = useState(defaultJMP || defaultJM);
    const changeMeasure = (key) => {
      cartAction.setMeasure(item, key);
      setSelectedJM(item.jm_pom.find(el => el.symbol_jmp === key));
    };

    return (
      <div>
        {item.jm_pom.length === 1 && (
          <span className="block">{selectedJM.symbol_jmp}</span>
        )}
        {item.jm_pom.length > 1 && (
          <SSelect
            className='py-1 pl-2 pr-8 text-sm leading-5 border rounded-md h-9'
            value={jmList}
            defaultValue={selectedJM?.symbol_jmp || defaultJMP || defaultJM}
            handleChange={changeMeasure}
          />
        )}
        {selectedJM?.podstawowa != undefined && selectedJM.podstawowa !== 1 && (
          <span className="block text-xs text-gray-400">
            {`1${selectedJM.symbol_jmp}`}
            &nbsp;=&nbsp;
            {selectedJM.przelicz}
            {item.symbol_jm}
          </span>
        )}
      </div>
    );
  };

  const priceTemplate = (item) => {
    const globalStateQ = useContext(GlobalStateContext);
    const [state, cartAction] = useCartAction();

    const defaultJM = item.jm_pom.find(el => el.podstawowa === 1);
    const defaultJMD = item.jm_pom.find(el => el.podstawowa === 0);
    const defaultJMP = item.jm_pom.find(el => el.symbol_jmp === state.measure[item.symbol_art]);

    const [selectedJM, setSelectedJM] = useState(defaultJMP || defaultJM || defaultJMD);
    const [quantity, setQuantity] = useState({ ...cartAction.getProduct({ item }) });

    const jmList = item.jm_pom.filter(el => el.visible == 1);

    useEffect(() => {
      // console.log("currency", String(+item.cena * parseFloat(selectedJM.przelicz)), item.cena, parseFloat(selectedJM.przelicz));
      const jm = item.jm_pom.find(el => el.symbol_jmp === state.measure[item.symbol_art]);
      if (jm !== undefined) setSelectedJM(jm);
      setQuantity({ ...cartAction.getProduct({ item }) });
    }, [state.measure[item.symbol_art], state.select]);

    return (
      <div
        translate="no"
        // data-title={`base: ${roundTo(+item.base_price, 2)}, norm: ${roundTo(+item.cena, 2)}, net: ${+item.cena_n}, dis: ${+quantity.price_discount}, convert: ${parseFloat(selectedJM.przelicz)}`}
      >
        {globalStateQ.user?.price_catalog === true && +item?.base_price > 0 && roundTo(+item.base_price * parseFloat(selectedJM.przelicz), 2) > roundTo((+item.cena || (!!globalStateQ.user?.price_brutto ? +item.cena_b : +item.cena_n)) * parseFloat(selectedJM.przelicz), 2) && (
          <>
            {globalStateQ.user?.rabat_procent === true && (
              <span className='block text-sm leading-3 text-red-400'>
                {/* {roundTo(((+item.base_price) - (+item.cena || +item.cena_n)) / (+item.cena || item.cena_n) * 100 , 2)}% */}
                {roundTo(100 * ((+item.base_price - (+item.cena || (!!globalStateQ.user?.price_brutto ? +item.cena_b : +item.cena_n))).toFixed(2) / +item.base_price).toFixed(2), 2)}%
              </span>
            )}
            {globalStateQ.user?.rabat_procent !== true && (
              <span className='block text-sm leading-3 text-red-400 line-through'>
                {numberFormatCurrency(+item.base_price * parseFloat(selectedJM.przelicz), item.currency)}
              </span>
            )}
          </>
        )}
        <span className="block md:whitespace-nowrap">
          <span className="flex items-center justify-end gap-1">
            {quantity.price_discount > 0 && roundTo(+quantity.price_discount, 2) !== roundTo(item.cena, 2) && (
              <span>
                <IconDiscount />
              </span>
            )}
            <span>
              {+item.cena ?
                +item.cena !== 0 ? numberFormatCurrency((+quantity.price_discount || +item.cena) * parseFloat(selectedJM.przelicz), item.currency) : '-,--'
                : numberFormatCurrency((+quantity.price_discount || (!!globalStateQ.user?.price_brutto ? +item.cena_b : +item.cena_n)) * parseFloat(selectedJM.przelicz), item.currency)
              }
              {((item.jm_pom.length > 1 && (defaultJM && defaultJM?.symbol_jmp) != selectedJM?.symbol_jmp) || (item.jm_pom.length > 1 && jmList.length <= 1)) && (
                <>&nbsp;/&nbsp;<span className="inline-block">{selectedJM.symbol_jmp}</span></>
              )}
            </span>
          </span>
        </span>
        <span className={`${selectedJM.podstawowa !== 1 ? '' : 'hidden'} text-xs text-gray-400 leading-3 block md:whitespace-nowrap`}>
          {+item.cena_n !== 0 ? numberFormatCurrency((!!globalStateQ.user?.price_brutto ? +item.cena_b : +item.cena_n), item.currency) : '-,--'}&nbsp;/&nbsp;<span className="inline-block">{defaultJM && defaultJM?.symbol_jmp}</span>
        </span>
      </div>
    );
  };

  const quantityTemplate = (item) => {
    // TODO: quantity show "brak" for stock_show == 0
    // if (item.zapas.stock_show == 0)
    //   return;

    const showQuantityInfo = item?.outOfStock != undefined && Array.isArray(item.outOfStock) && item.outOfStock[0]?.value != undefined && item.outOfStock[0].value.trim() != "";
    const tplQuantityInfo = (
      <span className='block text-sm leading-3 text-red-400'>
        {item?.outOfStock != undefined &&  Array.isArray(item.outOfStock) && (item.outOfStock[0].desc.trim() || item.outOfStock[0].value.trim())}
      </span>
    );

    if (item.zapas.stock_show == 2) { // dostępny/niedostępny
      return (
        <span className="block text-sm">
          {item.zapas.actual == 1 ? t('dostępny') : showQuantityInfo ? tplQuantityInfo : t('niedostępny')}
        </span>
      );
    }

    if (item.zapas.stock_show == 3) { // pasek graficzny
      return (
        <>
          {(item.zapas.actual <= 0 && showQuantityInfo) && tplQuantityInfo}
          {!(item.zapas.actual <= 0 && showQuantityInfo) && (
            <Battery value={item.zapas.actual} min={item.zapas.min} max={item.zapas.max} />
          )}
        </>
      );
    }

    const [state] = useCartAction();
    const defaultJM = item.jm_pom.find(el => el.podstawowa === 1);
    const defaultJMD = item.jm_pom.sort((a, _) => a.podstawowa == 1 && a.visible == 1 ? -1 : 1).find(el => el.visible == 1);
    const defaultJMP = item.jm_pom.find(el => el.symbol_jmp === state.measure[item.symbol_art]);

    const [selectedJM, setSelectedJM] = useState(defaultJMP || defaultJMD);

    useEffect(() => {
      const jm = item.jm_pom.find(el => el.symbol_jmp === state.measure[item.symbol_art]);
      if (jm !== undefined) setSelectedJM(jm);
    }, [state.measure[item.symbol_art], state.select]);

    return (
      <>
        {(item.zapas.actual <= 0 && showQuantityInfo) && tplQuantityInfo}
        {!(item.zapas.actual <= 0 && showQuantityInfo) && (
          <>
            {numberFormatQuantity(item.zapas.actual / (selectedJM?.przelicz || 1), +selectedJM?.precision)}
            {selectedJM?.podstawowa !== 1 && (
              <span className="block text-xs leading-3 text-gray-400">{numberFormatQuantity(item.zapas.actual, +selectedJM?.precision)}&nbsp;{defaultJM && defaultJM?.symbol_jmp}</span>
            )}
          </>
        )}
      </>
    );
  };

  const labelTemplate = (item) => (
    <span translate="no">{item.label != null && item.label?.length > 0 ? `${item.label}` : `${item.symbol_art != null && item.symbol_art.length > 0 ? item.symbol_art : ''}`}</span>
  );

  const extraFieldTemplate = (item) => (
    <span translate="no">{item.extra_field != undefined && ('' + item.extra_field)}</span>
  );

  const batteryTemplate = useCallback((item) => (
    <Battery value={item.zapas.actual} min={item.zapas.min} max={item.zapas.max} />
  ), []);

  const detailTemplate = useCallback((item) => {
    const [articleVariantList, setArticleVariantList] = useState([]);
    const [articleCrossList, setArticleCrossList] = useState([]);
    const [articleCrossMultiList, setArticleCrossMultiList] = useState([]);
    const [articleCrossText, setArticleCrossText] = useState({ single:"", multi:"" });
    const [featureList, setFeatureList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [childPortal, setChildPortal] = useState(undefined);
    const refTab = useRef();

    const getArticleVariant = async (symbol) => {
      const resp = await (!catalog ? api.getArticleVariants(symbol) : api.getCatalogArticleVariantsBySymbol(symbol));
      return resp?.data;
    };

    const getArticleCrossSell = async (symbol) => {
      const resp = await (!catalog ? api.getArticleCrossSelling(symbol) : api.getCatalogArticleCrossSelling(symbol));
      return resp?.data;
    };

    const changeToggle = (child) => {
      setChildPortal(child);
      if (noVariant == true) return;

      (function() {
        if (articleVariantList.length > 0) return;

        getArticleVariant(item.symbol_art).then(results => {
          if (results?.status != 0) return;

          const list = parseJSON(results?.dane);
          Array.isArray(list) && setArticleVariantList(list);
        });
      })();

      (function() {
        if (articleCrossList.length > 0) return;

        getArticleCrossSell(item.symbol_art).then(results => {
          if (results?.status != 0) return;

          const list = [false, false];
          const crossList = parseJSON(results?.dane);
          list[0] = crossList.filter(el => el.typ !== "W");
          list[1] = crossList.filter(el => el.typ === "W");

          if (list[0]?.length > 0 && list[0][0].tytul != undefined)
            articleCrossText.single = list[0][0].tytul;

          if (list[1]?.length > 0 && list[1][0].tytul != undefined)
            articleCrossText.multi = list[1][0].tytul;
            
          Array.isArray(list[0]) && setArticleCrossList(list[0]);
          Array.isArray(list[1]) && setArticleCrossMultiList(list[1]);
        });
      })();
    };

    const showTab = async (e, id) => {
      setCurrentTab(id);

      if (refTab.current) {
        const { children } = refTab.current;

        Array.from(children).forEach((el, index) => {
          if (id === index)
            el.classList.remove('hidden');
          else 
            el.classList.add('hidden');
        });
      }
    };

    return (
      <>
        <SRowDetails index={item.symbol_art} handleToggle={changeToggle} />
        {childPortal && createPortal(
          <div className="flex flex-row">
            <nav className="relative flex mt-1 bg-gray-50 detail-nav">
              <ul className="relative text-md">
                <li>
                  <button
                    onClick={(e) => showTab(e, 0)}
                    type="button"
                    className={`${currentTab == 0 ? 'text-primary bg-white' : ''} group py-2 pl-2 pr-2 w-full flex items-center focus:outline-none focus-visible:underline hover:text-primary`}
                  >
                    <svg className="w-6 h-6" viewBox="-72 0 480 480" fill="currentColor" stroke="currentColor">
                      <path d="m312 0h-288c-13.601562 0-24 10.398438-24 24v432c0 13.601562 10.398438 24 24 24h219.199219l92.800781-92.800781v-363.199219c0-13.601562-10.398438-24-24-24zm8 380.800781-72 72v-52.800781c0-4.800781 3.199219-8 8-8h40v-16h-40c-13.601562 0-24 10.398438-24 24v64h-208c-4.800781 0-8-3.199219-8-8v-432c0-4.800781 3.199219-8 8-8h288c4.800781 0 8 3.199219 8 8zm0 0"/>
                      <path d="m120 48h96v16h-96zm0 0"/>
                      <path d="m64 264h208v16h-208zm0 0"/>
                      <path d="m64 304h208v16h-208zm0 0"/>
                      <path d="m64 344h208v16h-208zm0 0"/>
                      <path d="m64 384h136v16h-136zm0 0"/>
                      <path d="m240 212.800781v-97.601562l-72-36-72 36v97.601562l72 36zm-72-62.402343-20-12 48-27.199219 20 9.601562zm10.398438-48-45.597657 26.402343-12.800781-8 48-24zm-66.398438 32 48 29.601562v46.398438h16v-46.398438l48-29.601562v68.800781l-56 28-56-28zm0 0"/>
                    </svg>
                    <span className="hidden ml-2 text-sm font-medium text-gray-700 transition-all ease-out group-hover:flex transition-medium">
                      {t('Opis')}
                    </span>
                  </button>
                </li>
                {featureList.length > 0 && (
                  <li>
                    <button
                      onClick={(e) => showTab(e, 1)}
                      type="button"
                      className={`${currentTab == 1 ? 'text-primary bg-white' : ''} group py-2 pl-2 pr-1 w-full flex items-center focus:outline-none focus-visible:underline hover:text-primary`}
                    >
                      <svg className="w-6 h-6" viewBox="0 0 512 512" fill="currentColor" stroke="currentColor">
                        <g>
                          <path d="m415.949 363.039v-59.61c0-5.523-4.478-10-10-10h-59.575c-1.521-20.558-18.729-36.818-39.662-36.818s-38.14 16.26-39.661 36.818h-48.447v-56.223c0-5.523-4.478-10-10-10h-7.029c-10.901 0-19.771-8.876-19.771-19.786s8.869-19.786 19.771-19.786h7.029c5.522 0 10-4.477 10-10v-69.5c0-5.523-4.486-10-10.009-10h-198.475c-5.522 0-10 4.477-10 10v393.866c0 5.523 4.478 10 10 10h395.829c5.522 0 10-4.477 10-10v-59.61c20.549-1.524 36.801-18.737 36.801-39.676s-16.252-38.151-36.801-39.675zm-217.345-244.904v49.61c-20.549 1.524-36.8 18.737-36.8 39.676s16.251 38.152 36.8 39.676v46.333h-59.475c-5.522 0-10 4.477-10 10v7.033c0 10.91-8.869 19.786-19.771 19.786s-19.771-8.876-19.771-19.786v-7.033c0-5.523-4.478-10-10-10h-59.467v-175.295zm-178.484 195.294h49.575c1.521 20.558 18.729 36.818 39.662 36.818s38.141-16.26 39.662-36.818h49.575v59.5c0 5.523 4.478 10 10 10h7.029c10.901 0 19.771 8.876 19.771 19.786s-8.869 19.786-19.771 19.786h-7.029c-5.522 0-10 4.477-10 10v59.499h-178.474zm392.859 109.071h-7.029c-5.522 0-10 4.477-10 10v59.5h-177.355v-49.61c20.549-1.524 36.8-18.737 36.8-39.676s-16.251-38.152-36.8-39.676v-49.61h58.347c5.522 0 10-4.477 10-10v-7.033c0-10.91 8.869-19.786 19.771-19.786s19.771 8.876 19.771 19.786v7.033c0 5.523 4.478 10 10 10h59.466v59.5c0 5.523 4.478 10 10 10h7.029c10.902 0 19.771 8.876 19.771 19.786s-8.869 19.786-19.771 19.786z"/>
                          <path d="m501.88 0h-198.475c-5.522 0-10 4.477-10 10v59.61c-20.549 1.524-36.8 18.737-36.8 39.676s16.251 38.152 36.8 39.676v59.619c0 5.523 4.478 10 10 10h69.466c5.522 0 10-4.477 10-10v-7.033c0-10.91 8.869-19.786 19.771-19.786s19.771 8.876 19.771 19.786v7.033c0 5.523 4.478 10 10 10h69.466c5.522 0 10-4.486 10-10.009v-198.572c.001-5.523-4.477-10-9.999-10zm-10 198.581h-49.575c-1.521-20.558-18.729-36.818-39.662-36.818s-38.141 16.26-39.662 36.818h-49.575v-59.509c0-5.523-4.478-10-10-10h-7.029c-10.901 0-19.771-8.876-19.771-19.786s8.869-19.786 19.77-19.786h7.029c5.522 0 10-4.477 10-10v-59.5h178.475z"/>
                        </g>
                      </svg>
                      <span className="hidden ml-2 text-sm font-medium text-gray-700 transition-all ease-out group-hover:flex transition-medium">
                        {t('Cechy')}
                      </span>
                    </button>
                  </li>
                )}
                {documentList.length > 0 && (
                  <li>
                    <button
                      onClick={(e) => showTab(e, 2)}
                      type="button"
                      className={`${currentTab == 2 ? 'text-primary bg-white' : ''} group py-2 pl-2 pr-1 w-full flex items-center focus:outline-none focus-visible:underline hover:text-primary`}
                    >
                      <svg className="w-6 h-6" viewBox="0 -16 512.00046 512" fill="currentColor" stroke="currentColor">
                        <path d="m413.492188 128.910156c-17.292969-86.765625-101.648438-143.082031-188.414063-125.789062-63.460937 12.648437-113.082031 62.238281-125.769531 125.691406-61.519532 7.089844-105.648438 62.707031-98.5625 124.230469 6.523437 56.621093 54.480468 99.339843 111.476562 99.300781h80.09375c8.847656 0 16.019532-7.171875 16.019532-16.019531 0-8.847657-7.171876-16.019531-16.019532-16.019531h-80.09375c-44.238281-.261719-79.882812-36.332032-79.625-80.566407.261719-44.238281 36.332032-79.886719 80.570313-79.625 8.164062 0 15.023437-6.140625 15.921875-14.257812 8.132812-70.304688 71.722656-120.707031 142.03125-112.574219 59.109375 6.835938 105.738281 53.464844 112.574218 112.574219 1.34375 8.261719 8.5 14.3125 16.867188 14.257812 44.238281 0 80.097656 35.859375 80.097656 80.097657 0 44.234374-35.859375 80.09375-80.097656 80.09375h-80.09375c-8.847656 0-16.019531 7.171874-16.019531 16.019531 0 8.847656 7.171875 16.019531 16.019531 16.019531h80.097656c61.925782-.386719 111.816406-50.902344 111.433594-112.828125-.351562-56.394531-42.53125-103.753906-98.507812-110.605469zm0 0"/>
                        <path d="m313.019531 385.183594-40.609375 40.621094v-201.613282c0-8.847656-7.171875-16.019531-16.015625-16.019531-8.847656 0-16.019531 7.171875-16.019531 16.019531v201.613282l-40.609375-40.621094c-6.144531-6.367188-16.289063-6.542969-22.652344-.394532-6.363281 6.144532-6.539062 16.285157-.394531 22.648438.132812.136719.261719.265625.394531.394531l67.9375 67.953125c1.484375 1.480469 3.242188 2.65625 5.175781 3.460938 3.941407 1.667968 8.390626 1.667968 12.335938 0 1.933594-.804688 3.691406-1.980469 5.171875-3.460938l67.9375-67.953125c6.363281-6.144531 6.539063-16.285156.394531-22.648437-6.148437-6.363282-16.289062-6.539063-22.652344-.394532-.132812.128907-.265624.257813-.394531.394532zm0 0"/>
                      </svg>
                      <span className="hidden ml-2 text-sm font-medium text-gray-700 transition-all ease-out group-hover:flex transition-medium">
                        {t('Do\u00A0pobrania')}
                      </span>
                    </button>
                  </li>
                )}
                {articleVariantList.length > 0 && (
                  <li>
                    <button
                      onClick={(e) => showTab(e, 3)}
                      type="button"
                      className={`${currentTab == 3 ? 'text-primary bg-white' : ''} group py-2 pl-2 pr-1 w-full flex items-center focus:outline-none focus-visible:underline hover:text-primary`}
                    >
                      <svg className="w-6 h-6" viewBox="0 0 320.531 320.531" fill="currentColor" stroke="currentColor">
                        <g>
                          <path d="M149.435,119.164V63.57c13.007-3.342,22.648-15.168,22.648-29.204c0-16.624-13.524-30.148-30.148-30.148
                            c-16.623,0-30.147,13.524-30.147,30.148c0,14.036,9.641,25.861,22.647,29.203v55.594c-15.775,2.374-29.169,12.115-36.556,25.597
                            l-32.99-25.646c0.807-2.271,1.25-4.714,1.25-7.258c0-11.99-9.755-21.745-21.745-21.745s-21.745,9.755-21.745,21.745
                            s9.755,21.745,21.745,21.745c3.972,0,7.695-1.076,10.903-2.943l37.29,28.989c-0.55,2.971-0.852,6.027-0.852,9.155
                            c0,11.14,3.652,21.44,9.815,29.777l-42.163,42.163c-2.929,2.929-2.929,7.678,0,10.606c1.465,1.464,3.385,2.197,5.304,2.197
                            s3.839-0.732,5.304-2.197l42.163-42.163c6.429,4.753,14.026,8.012,22.277,9.254v55.718c-8.306,3.062-14.245,11.056-14.245,20.411
                            c0,11.99,9.755,21.745,21.745,21.745s21.745-9.755,21.745-21.745c0-9.356-5.939-17.35-14.245-20.411v-55.717
                            c24.136-3.631,42.7-24.506,42.7-49.637C192.135,143.67,173.57,122.795,149.435,119.164z M44.394,118.602
                            c-3.72,0-6.745-3.026-6.745-6.745s3.025-6.745,6.745-6.745s6.745,3.026,6.745,6.745S48.113,118.602,44.394,118.602z
                            M126.787,34.366c0-8.353,6.795-15.148,15.147-15.148s15.148,6.795,15.148,15.148c0,8.353-6.796,15.148-15.148,15.148
                            S126.787,42.719,126.787,34.366z M141.935,301.313c-3.72,0-6.745-3.026-6.745-6.745c0-3.255,2.317-5.979,5.389-6.608
                            c0.44,0.081,0.892,0.129,1.356,0.129s0.916-0.048,1.356-0.129c3.072,0.629,5.389,3.353,5.389,6.608
                            C148.68,298.287,145.654,301.313,141.935,301.313z M141.935,204.001c-19.409,0-35.199-15.791-35.199-35.2
                            c0-3.425,0.501-6.734,1.417-9.867c0.049-0.155,0.095-0.309,0.133-0.466c4.425-14.382,17.834-24.867,33.649-24.867
                            c19.409,0,35.2,15.791,35.2,35.2C177.135,188.211,161.344,204.001,141.935,204.001z"/>
                          <path d="M290.384,95.953c-16.624,0-30.148,13.524-30.148,30.148c0,0.775,0.039,1.54,0.097,2.3l-54.238,18.493
                            c-3.921,1.336-6.016,5.599-4.679,9.519c1.063,3.119,3.977,5.082,7.098,5.082c0.803,0,1.619-0.13,2.421-0.403l54.236-18.492
                            c5.392,8.212,14.677,13.65,25.214,13.65c16.623,0,30.147-13.524,30.147-30.148C320.531,109.478,307.007,95.953,290.384,95.953z
                            M290.384,141.25c-8.353,0-15.148-6.795-15.148-15.148c0-8.353,6.796-15.148,15.148-15.148s15.147,6.795,15.147,15.148
                            C305.531,134.454,298.736,141.25,290.384,141.25z"/>
                          <path d="M30.148,250.44C13.524,250.44,0,263.965,0,280.588c0,16.624,13.524,30.148,30.148,30.148
                            c16.623,0,30.147-13.524,30.147-30.148C60.296,263.965,46.771,250.44,30.148,250.44z M30.148,295.737
                            c-8.353,0-15.148-6.795-15.148-15.148c0-8.353,6.796-15.148,15.148-15.148s15.147,6.795,15.147,15.148
                            C45.296,288.941,38.501,295.737,30.148,295.737z"/>
                        </g>
                      </svg>
                      <span className="hidden ml-2 text-sm font-medium text-gray-700 transition-all ease-out group-hover:flex transition-medium">
                        {t('Warianty')}
                      </span>
                    </button>
                  </li>
                )}
                {articleCrossList.length > 0 && (
                  <li>
                    <button
                      onClick={(e) => showTab(e, 4)}
                      type="button"
                      className={`${currentTab == 4 ? 'text-primary bg-white' : ''} group py-2 pl-2 pr-1 w-full flex items-center focus:outline-none focus-visible:underline hover:text-primary`}
                    >
                     <svg className="w-6 h-8" fill="currentColor" viewBox="0 0 490 490">
                        <path d="M114.021,162.231c-45.706,0-82.761,37.055-82.761,82.762s37.055,82.761,82.761,82.761c21.33,0,40.562-8.284,55.23-21.529
                          l127.335,78.872c-1.975,7.089-3.384,14.424-3.384,22.141c0,45.706,37.055,82.762,82.762,82.762s82.761-37.055,82.761-82.762
                          s-37.055-82.761-82.761-82.761c-21.314,0-40.562,8.284-55.23,21.529l-127.335-78.872c1.975-7.09,3.384-14.424,3.384-22.141
                          c0-7.733-1.393-15.052-3.384-22.141l127.35-78.857c14.669,13.245,33.916,21.529,55.23,21.529c45.706,0,82.762-37.055,82.762-82.761
                          C458.725,37.055,421.685,0,375.979,0c-45.706,0-82.762,37.055-82.762,82.761c0,7.717,1.393,15.052,3.384,22.141l-127.335,78.872
                          C154.583,170.53,135.351,162.231,114.021,162.231z M165.653,249.954c-1.592,16.506-10.719,30.731-24.086,39.122
                          c-8.008,5.022-17.41,8.054-27.546,8.054c-28.741,0-52.137-23.381-52.137-52.137c0-28.756,23.381-52.137,52.137-52.137
                          c10.137,0,19.538,3.032,27.546,8.054c13.367,8.391,22.493,22.616,24.086,39.107c0.153,1.654,0.505,3.262,0.505,4.961
                          C166.158,246.692,165.822,248.3,165.653,249.954z M324.347,402.277c1.592-16.506,10.719-30.731,24.086-39.122
                          c8.008-5.022,17.41-8.054,27.546-8.054c28.741,0,52.137,23.382,52.137,52.137c0,28.756-23.397,52.122-52.137,52.122
                          c-28.741,0-52.137-23.381-52.137-52.137C323.842,405.524,324.178,403.931,324.347,402.277z M375.979,30.624
                          c28.741,0,52.137,23.381,52.137,52.137s-23.381,52.138-52.137,52.138c-10.137,0-19.523-3.032-27.546-8.054
                          c-13.367-8.376-22.493-22.616-24.086-39.122c-0.153-1.654-0.505-3.262-0.505-4.961C323.842,54.006,347.223,30.624,375.979,30.624z"
                          />
                      </svg>
                      <span className="hidden ml-2 text-sm font-medium text-gray-700 transition-all ease-out group-hover:flex transition-medium">
                        {t(articleCrossText.single)}
                      </span>
                    </button>
                  </li>
                )}
                {articleCrossMultiList.length > 0 && (
                  <li>
                    <button
                      onClick={(e) => showTab(e, 5)}
                      type="button"
                      className={`${currentTab == 5 ? 'text-primary bg-white' : ''} group py-2 pl-2 pr-1 w-full flex items-center focus:outline-none focus-visible:underline hover:text-primary`}
                    >
                      {/*
                      <svg className="w-6 h-6" viewBox="0 0 165.832 165.832" fill="currentColor">
                        <path d="M132.282,9.147c-15.921,0-29.278,11.151-32.695,26.05H66.245
                          C62.828,20.298,49.471,9.147,33.55,9.147C15.051,9.147,0,24.198,0,42.697s15.051,33.55,33.55,33.55
                          c3.744,0,7.343-0.626,10.708-1.763L59.43,99.206c-6.206,6.092-10.065,14.567-10.065,23.929c0,18.5,15.051,33.55,33.551,33.55
                          s33.551-15.051,33.551-33.55c0-9.362-3.859-17.837-10.065-23.929l15.171-24.721c3.366,1.137,6.965,1.763,10.709,1.763
                          c18.499,0,33.55-15.051,33.55-33.55S150.781,9.147,132.282,9.147z M15,42.697c0-10.229,8.321-18.55,18.55-18.55
                          c10.225,0,18.545,8.317,18.55,18.541c0,0.003,0,0.006,0,0.01s0,0.006,0,0.01c-0.005,10.224-8.325,18.541-18.55,18.541
                          C23.321,61.248,15,52.926,15,42.697z M82.916,141.685c-10.229,0-18.551-8.322-18.551-18.55s8.322-18.55,18.551-18.55
                          s18.551,8.322,18.551,18.55S93.145,141.685,82.916,141.685z M93.625,91.347c-3.365-1.137-6.964-1.763-10.708-1.763
                          c-3.744,0-7.343,0.626-10.708,1.763L57.036,66.626c4.497-4.414,7.753-10.081,9.209-16.429h33.342
                          c1.456,6.348,4.713,12.015,9.209,16.429L93.625,91.347z M132.282,61.248c-10.225,0-18.545-8.317-18.55-18.541
                          c0-0.003,0-0.006,0-0.01s0-0.006,0-0.01c0.005-10.224,8.325-18.541,18.55-18.541c10.229,0,18.55,8.322,18.55,18.55
                          S142.511,61.248,132.282,61.248z"
                        />
                      </svg>
                      */}
                      {/* <svg className='w-6 h-6' viewBox="0 0 24 24" fill="currentColor">
                        <path d="M9 6C9 6.39397 9.0776 6.78407 9.22836 7.14805C9.37913 7.51203 9.6001 7.84274 9.87868 8.12132C10.1573 8.3999 10.488 8.62087 10.852 8.77164C11.2159 8.9224 11.606 9 12 9C12.394 9 12.7841 8.9224 13.1481 8.77164C13.512 8.62087 13.8427 8.3999 14.1213 8.12132C14.3999 7.84274 14.6209 7.51203 14.7716 7.14805C14.9224 6.78407 15 6.39396 15 6C15 5.60603 14.9224 5.21593 14.7716 4.85195C14.6209 4.48797 14.3999 4.15725 14.1213 3.87868C13.8427 3.6001 13.512 3.37912 13.148 3.22836C12.7841 3.0776 12.394 3 12 3C11.606 3 11.2159 3.0776 10.8519 3.22836C10.488 3.37913 10.1573 3.6001 9.87868 3.87868C9.6001 4.15726 9.37912 4.48797 9.22836 4.85195C9.0776 5.21593 9 5.60604 9 6L9 6Z" />
                        <path d="M4.43781 13.9015C4.09663 14.0985 3.79758 14.3607 3.55775 14.6733C3.31792 14.9858 3.142 15.3426 3.04004 15.7231C2.93807 16.1037 2.91206 16.5006 2.96348 16.8912C3.0149 17.2818 3.14275 17.6584 3.33974 17.9996C3.53672 18.3408 3.79897 18.6398 4.11153 18.8796C4.42408 19.1195 4.78081 19.2954 5.16136 19.3974C5.5419 19.4993 5.9388 19.5253 6.32939 19.4739C6.71999 19.4225 7.09663 19.2946 7.43781 19.0977C7.779 18.9007 8.07804 18.6384 8.31787 18.3259C8.5577 18.0133 8.73362 17.6566 8.83559 17.276C8.93756 16.8955 8.96357 16.4986 8.91215 16.108C8.86072 15.7174 8.73287 15.3408 8.53589 14.9996C8.33891 14.6584 8.07665 14.3594 7.7641 14.1195C7.45154 13.8797 7.09481 13.7038 6.71427 13.6018C6.33373 13.4998 5.93683 13.4738 5.54623 13.5252C5.15564 13.5767 4.779 13.7045 4.43781 13.9015L4.43781 13.9015Z" />
                        <path d="M19.5622 13.9015C19.9034 14.0985 20.2024 14.3607 20.4422 14.6733C20.6821 14.9859 20.858 15.3426 20.96 15.7231C21.0619 16.1037 21.0879 16.5006 21.0365 16.8912C20.9851 17.2818 20.8572 17.6584 20.6603 17.9996C20.4633 18.3408 20.201 18.6398 19.8885 18.8796C19.5759 19.1195 19.2192 19.2954 18.8386 19.3974C18.4581 19.4993 18.0612 19.5253 17.6706 19.4739C17.28 19.4225 16.9034 19.2946 16.5622 19.0977C16.221 18.9007 15.922 18.6384 15.6821 18.3259C15.4423 18.0133 15.2664 17.6566 15.1644 17.276C15.0624 16.8955 15.0364 16.4986 15.0879 16.108C15.1393 15.7174 15.2671 15.3408 15.4641 14.9996C15.6611 14.6584 15.9234 14.3594 16.2359 14.1195C16.5485 13.8797 16.9052 13.7038 17.2857 13.6018C17.6663 13.4998 18.0632 13.4738 18.4538 13.5252C18.8444 13.5767 19.221 13.7045 19.5622 13.9015L19.5622 13.9015Z" />
                        <path d="M9.00254 6.12533C9.0008 6.08372 8.99993 6.04203 8.99993 6.0003C8.99993 5.65294 9.06026 5.30857 9.17787 4.98247C9.03301 5.03346 8.8894 5.08843 8.74719 5.14733C7.71592 5.5745 6.77889 6.2006 5.98959 6.9899C5.20029 7.7792 4.57419 8.71623 4.14702 9.7475C3.71986 10.7788 3.5 11.8841 3.5 13.0003C3.5 13.5265 3.54885 14.0502 3.64523 14.5648C3.86884 14.2999 4.13691 14.0755 4.43774 13.9018C4.47388 13.8809 4.51042 13.8609 4.54733 13.8415C4.51586 13.5628 4.5 13.2819 4.5 13.0003C4.5 12.0154 4.69399 11.0401 5.0709 10.1302C5.44781 9.22024 6.00026 8.39345 6.6967 7.69701C7.36051 7.03319 8.14275 6.5002 9.00254 6.12533ZM14.9973 6.12528C15.8572 6.50014 16.6395 7.03315 17.3033 7.697C17.9997 8.39344 18.5522 9.22023 18.9291 10.1302C19.306 11.0401 19.5 12.0154 19.5 13.0003C19.5 13.282 19.4841 13.5628 19.4527 13.8416C19.4895 13.8609 19.526 13.881 19.5621 13.9018C19.863 14.0755 20.1311 14.3 20.3547 14.565C20.4511 14.0503 20.5 13.5265 20.5 13.0003C20.5 11.8841 20.2801 10.7788 19.853 9.74749C19.4258 8.71622 18.7997 7.77919 18.0104 6.98989C17.2211 6.2006 16.2841 5.57449 15.2528 5.14733C15.1105 5.0884 14.9669 5.03342 14.822 4.98242C14.9396 5.30854 14.9999 5.65292 14.9999 6.0003C14.9999 6.04201 14.9991 6.08368 14.9973 6.12528ZM17.533 19.4529C17.1916 19.3917 16.8631 19.2717 16.5621 19.098C16.5261 19.0772 16.4906 19.0556 16.4555 19.0334C15.9665 19.3945 15.4343 19.6957 14.8701 19.9294C13.9602 20.3063 12.9849 20.5003 12 20.5003C11.0151 20.5003 10.0398 20.3063 9.12988 19.9294C8.56568 19.6957 8.03345 19.3945 7.54443 19.0334C7.50935 19.0556 7.47378 19.0772 7.43775 19.098C7.13681 19.2717 6.80829 19.3917 6.46694 19.4528C7.14728 20.0362 7.91668 20.5093 8.74719 20.8533C9.77846 21.2804 10.8838 21.5003 12 21.5003C13.1162 21.5003 14.2215 21.2804 15.2528 20.8533C16.0833 20.5093 16.8527 20.0362 17.533 19.4529Z" />
                      </svg> */}
                      <svg className='w-6 h-6' viewBox="1 1 22 22" fill="none">
                        <path d="M16.96 6.17004C18.96 7.56004 20.34 9.77004 20.62 12.32" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3.48999 12.37C3.74999 9.82997 5.10999 7.61997 7.08999 6.21997" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8.19 20.9399C9.35 21.5299 10.67 21.8599 12.06 21.8599C13.4 21.8599 14.66 21.5599 15.79 21.0099" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.06 7.70001C13.5954 7.70001 14.84 6.45537 14.84 4.92001C14.84 3.38466 13.5954 2.14001 12.06 2.14001C10.5247 2.14001 9.28003 3.38466 9.28003 4.92001C9.28003 6.45537 10.5247 7.70001 12.06 7.70001Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.82999 19.92C6.36534 19.92 7.60999 18.6753 7.60999 17.14C7.60999 15.6046 6.36534 14.36 4.82999 14.36C3.29464 14.36 2.04999 15.6046 2.04999 17.14C2.04999 18.6753 3.29464 19.92 4.82999 19.92Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M19.17 19.92C20.7054 19.92 21.95 18.6753 21.95 17.14C21.95 15.6046 20.7054 14.36 19.17 14.36C17.6347 14.36 16.39 15.6046 16.39 17.14C16.39 18.6753 17.6347 19.92 19.17 19.92Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      <span className="hidden ml-2 text-sm font-medium text-gray-700 transition-all ease-out group-hover:flex transition-medium">
                        {t(articleCrossText.multi)}
                      </span>
                    </button>
                  </li>
                )}
              </ul>
            </nav>
            <div ref={refTab} className="flex flex-row flex-grow tab-list">
              <div className="flex flex-row px-4 py-4 tab-item">
                {/* <p>{item.nazwa_art}</p> */}
                <div className="text-sm text-gray-500 normalized">
                  {ReactHtmlParser(item.opis_html)}
                  {/* {ReactHtmlParser(item.opis_html || String(item.long_desc).replaceAll("\n", "<br>").replaceAll("\t", "&nbsp;&nbsp;&nbsp;&nbsp;"))} */}
                </div>
              </div>
              <div className="flex-row hidden px-4 py-4 tab-item">
                <FeatureList list={item?.cechy} handleCount={(count) => count > 0 && setFeatureList([count])} />
              </div>
              <div className="flex-row hidden px-4 py-4 tab-item">
                <DocumentList symbol={item.symbol_art} handleCount={(count) => count > 0 && setDocumentList([count])} />
              </div>
              <div className="flex-row hidden w-full py-1 tab-item">
                {articleVariantList.length > 0 && (
                  <ArticleGrid
                    data={articleVariantList}
                    handleSort={(key, direction) => false}
                    handleUpdate={() => false}
                    headerCartSelect={false}
                    noDetail={true}
                    noHeader={true}
                    catalog={catalog}
                  />
                )}
              </div>
              <div className="flex-row hidden w-full py-1 tab-item">
                {articleCrossList.length > 0 && (
                  <ArticleGrid
                    data={articleCrossList}
                    handleSort={(key, direction) => false}
                    handleUpdate={() => false}
                    headerCartSelect={false}
                    noDetail={true}
                    noHeader={true}
                    catalog={catalog}
                  />
                )}
              </div>
              <div className="flex-row hidden w-full py-1 tab-item">
                {articleCrossMultiList.length > 0 && (
                  <ArticleGrid
                    data={articleCrossMultiList}
                    handleSort={(key, direction) => false}
                    handleUpdate={() => false}
                    headerCartSelect={false}
                    noDetail={true}
                    noHeader={true}
                    catalog={catalog}
                  />
                )}
              </div>
            </div>
          </div>, childPortal,
        )}
      </>
    );
  }, []);

  const getSort = () => {
    let sortObj = {};
    const sessionSort = storage.get('local-sort-mode');

    if (sessionSort) {
      sortObj = JSON.parse(sessionSort);
      return ({ func: 1, key: sortObj.symbol, direction: sortObj.sort, type: undefined });
    }
    return null;
  };

  return (
    <>
      {globalState.user?.article_popup && showArticle != "" && <ArticleShowPopup symbol={showArticle} maxContent="" />}
      {/* {loading && (<SPlaceholder max={12} />)} */}
      <STable
        name="product_list"
        keyName="symbol_art"
        data={data}
        funcSort={handleSort}
        noDrag={noDrag}
        noHeader={noHeader}
        defaultSort={getSort()}
        handleDone={() => console.log("done")}
      >
        {!noDetail && (
          <SColumn
            className="hidden w-3 px-3 py-3 whitespace-no-wrap md:table-cell no-drag"
            template={detailTemplate}
          />
        )}
        {/* {noDetail && (
          <SColumn
            template={() => <div style={{ width : '16px' }} />}
            className="hidden w-3 px-3 py-3 whitespace-no-wrap md:table-cell no-drag"
          />
        )} */}
        <SColumn
          symbol="nazwa_art"
          name={t("Nazwa")}
          template={articleTemplate}
          className="px-5 py-3 text-center whitespace-no-wrap md:text-left name-hidden"
          sort="nazwa_art"
          drag
        />
        <SColumn
          symbol={!catalog ? 'label' : 'symbol_art'}
          name={t("Symbol")}
          template={labelTemplate}
          headerTemplate={labelHeaderTemplate}
          className="px-5 py-3 text-center whitespace-no-wrap md:text-left"
          sort={!catalog ? 'label' : 'symbol_art'}
          drag
        />
        {data.length > 0 && data[0]?.extra_field != undefined && (
          <SColumn
            symbol="extra_field"
            name={t('Etykieta')}
            template={extraFieldTemplate}
            headerTemplate={headerExtraFieldTemplate}
            className="px-5 py-3 text-center whitespace-no-wrap md:text-left"
            drag
            // sort="extra_field"
          />
        )}
        {data.length > 0 && data[0]?.cena_n !== undefined && (
          <SColumn
            symbol={!catalog ? 'cena' : (!!globalState.user?.price_brutto ? "cena_b" : "cena_n")}
            name={!!globalState.user?.price_brutto ? t("Cena brutto") : t("Cena netto")}
            typeColumn="currency"
            defaultValue="-,--"
            classHeader="px-5 py-3 uppercase text-right"
            className="px-5 py-3 text-center whitespace-no-wrap md:text-right"
            template={priceTemplate}
            sort={!!globalState.user?.price_brutto ? "cena_b" : "cena_n"}
            drag
            // alert="sortowane po cenie standardowej"
          />
        )}
        <SColumn
          symbol="symbol_jm"
          name={t("JM")}
          defaultValue=""
          // width="105px"
          template={measureTemplate}
          className="px-5 py-3 text-center whitespace-no-wrap md:text-left"
          drag
        />
        {!catalog && (
          <SColumn
            symbol="zapas"
            name={t("Zapas")}
            typeColumn="number"
            defaultValue="0"
            classHeader="px-5 py-3 text-right uppercase"
            className="px-5 py-3 text-center whitespace-no-wrap md:text-right"
            template={quantityTemplate}
            // width="90px"
            // sort="zapas_q"
            drag
          />
        )}
        {!catalog && (
          <SColumn
            // name="Koszyk nr. 1"
            width="120px"
            className="px-5 py-3 whitespace-no-wrap name-hidden no-drag"
            classHeader={`${headerCartSelect ? 'is-mobile ' : ''}text-right px-0 py-1 md:text-left md:px-5`}
            headerTemplate={headerCartSelect && headerCartTemplate}
            template={cartTemplate}
          />
        )}
      </STable>
    </>
  );
};

export default ArticleList;
